import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { id, it } from "date-fns/locale";
import { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
// import { Type } from "typescript";

interface Data {
  tableRows: [
    {
      cusid: string,
      sname: string,
      name: string,


    }
  ],
  id: number;
  code: string;
  typeid: string;
  sname: string;
  name: string;
  cityid: string;
  cperson: string;
  mobile: string;
  email: string;
  gstno: string;
  pan: string;
  tinno: number;
  isactive: string;
  cby: string;
  con: Date;
  mon: Date;
  mby: string;
  isexe: number;
  cusid: number;
  cusref: string;
  cuspocname: string;
  cuspocno: number;
  cusdesign: string;
  ismajor: number;
  add1: string;
  add2: string;
  add3: string;
  add4: string;
  add5: string;
  cadd1: string;
  cadd2: string;
  cadd3: string;
  cadd4: string;
  cadd5: string;
  ceo: string;
  add6: string;
  add7: string;
  cadd6: string;
  cadd7: string;
}
const status = [
  {
    value:1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];

const existing = [
  {
    value: 1,
    label: 'Yes'
  },
  {
    value: 0,
    label: 'No'
  }
];

const major = [
  {
    value: 1,
    label: 'Yes'
  },
  {
    value: 0,
    label: 'No'
  }
];

interface Type {
  id: number;
  name: string;
}
interface City {
  id: number;
  name: string;
}



function Customer() {
  const [data, setData] = useState<Data[]>([]);
  const [type, setType] = useState<Type[]>([]);
  const [city, setCity] = useState<City[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [formData, setFormData] = useState<any>(
    {
      
      id: '',
      code: '',
      typeid: '',
      sname: '',
      name: '',
      cityid: '',
      cperson: '',
      mobile: '',
      email: '',
      gstno: '',
      pan: '',
      tinno: '',
      isactive: '',
      cby: '',
      con: '',
      mon: '',
      mby: '',
      isexe: '',
      cusid: '',
      cusref: '',
      cuspocname: '',
      cuspocno: '',
      cusdesign: '',
      ismajor: '',
      add1: '',
      add2: '',
      add3: '',
      add4: '',
      add5: '',
      cadd1: '',
      cadd2: '',
      cadd3: '',
      cadd4: '',
      cadd5: '',
      ceo: '',
      add6: '',
      add7: '',
      cadd6: '',
      cadd7: ''
    }
  );
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [row, setRow] = useState(true);
  const [tableRows, setTableRows] = useState([{
    cusid: '',
    name: '',
  }]);
  const [customerId,setCustomerId]= useState <number>(null)




  useEffect(() => {
    apiService.getCustomer(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
      setData(response.data);
      console.log("apiService.getCustomer",response.data?.tableRows1)
      
    }).catch((err) => {
      console.log("err", err)
    });

    

    
    

    // apiService.getCustomer(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
    //   setData(response.data);
    //   console.log("apiService.getCustomer",response.data?.tableRows1)
      
    // }).catch((err) => {
    //   console.log("err", err)
    // });

    apiService.getDropdown("crm_mas_type").then(response => {
      setType(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("mas_city").then(response => {
      setCity(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    //   apiService.getDropdown("mas_dept").then(response => {
    //     setDept(response.data);
    //   }).catch((err) => {
    //     console.log("err", err)
    //   });

    //   apiService.getDropdown("mas_design").then(response => {
    //     setDesign(response.data);
    //   }).catch((err) => {
    //     console.log("err", err)
    //   });

    //   apiService.getDropdown("mas_plant").then(response => {
    //     setPlant(response.data);
    //   }).catch((err) => {
    //     console.log("err", err)
    //   });
  }, [rowsPerPage, page, formData]);



  const getCustomerId=(id:number)=>{
    apiService.getCustomer_id(id).then(response => {
      // setData(response.data);
      console.log("apiService.customerId",response?.data?.tableRows1)
      if(response?.data?.tableRows1?.length>0){
        setTableRows(response?.data?.tableRows1)
      }else{
        setTableRows([{
          cusid: '',
          name: '',
        }])
      }
      
    }).catch((err) => {
      console.log("err", err)
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const validateMobile = (mobile: string) => {
    // Mobile number validation logic (example: 10 digits only) 
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);

  };
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[a-z]+\.(com|in|org|info|tech|gov|edu|mil|co|io)$/;
    return emailRegex.test(email);
  };



  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name === 'mobile') {
      setIsMobileValid(validateMobile(value));
    }

    if (name == 'email') {
      setIsEmailValid(validateEmail(value));
    }



    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: '',
      code: '',
      typeid: '',
      sname: '',
      name: '',
      cityid: '',
      cperson: '',
      mobile: '',
      email: '',
      gstno: '',
      pan: '',
      tinno: '',
      isactive: '',
      cby: '',
      con: '',
      mon: '',
      mby: '',
      isexe: '',
      cusid: '',
      cusref: '',
      cuspocname: '',
      cuspocno: '',
      cusdesign: '',
      ismajor: '',
      add1: '',
      add2: '',
      add3: '',
      add4: '',
      add5: '',
      cadd1: '',
      cadd2: '',
      cadd3: '',
      cadd4: '',
      cadd5: '',
      ceo: '',
      add6: '',
      add7: '',
      cadd6: '',
      cadd7: ''
    });
    setSelectedItem(null);
    setIsMobileValid(true);
    setIsEmailValid(true);

  };

  const handleAddRow = () => {
    setTableRows((prevState: any) => [
      ...prevState,
      { cusid: '', name: '' },
    ]);
  };


  // Insert and update
  let submit = (event: any) => {
    event.preventDefault();
    console.log("inside")
    // Insert
    if (selectedItem == null) {
      // let data = {
      //   tableRows1: formData?.tableRows,
      //   code: formData?.code,
      //   typeid: formData?.typeid,
      //   sname: formData?.sname,
      //   name: formData?.name,
      //   cityid: formData?.cityid,
      //   cperson: formData?.cperson,
      //   mobile: formData?.mobile,
      //   email: formData?.email,
      //   gstno: formData?.gstno,
      //   pan: formData?.pan,
      //   tinno: formData?.tinno,
      //   isactive: formData?.isactive,
      //   cby: "1",
      //   isexe: formData?.isexe,
      //   cusid: formData?.cusid,
      //   cusref: formData?.cusref,
      //   cuspocname: formData?.cuspocname,
      //   cuspocno: formData?.cuspocno,
      //   cusdesign: formData?.cusdesign,
      //   ismajor: formData?.ismajor,
      //   add1: formData?.add1,
      //   add2: formData?.add2,
      //   add3: formData?.add3,
      //   add4: formData?.add4,
      //   add5: formData?.add5,
      //   cadd1: formData?.cadd1,
      //   cadd2: formData?.cadd2,
      //   cadd3: formData?.cadd3,
      //   cadd4: formData?.cadd4,
      //   cadd5: formData?.cadd5,
      //   ceo: formData?.ceo,
      //   add6: formData?.add6,
      //   add7: formData?.add7,
      //   cadd6: formData?.cadd6,
      //   cadd7: formData?.cadd7
      // };


      let data = {
        "tableRows1": tableRows,
        "code": formData?.code,
        "typeid": formData?.typeid.toString(),
        "sname": formData?.sname,
        "name": formData?.name,
        "cityid": formData?.cityid, //  formData?.name, id miss
        "cperson": formData?.cperson,
        "mobile": formData?.mobile,
        "email": formData?.email,
        "gstno": formData?.gstno,
        "pan": formData?.pan,
        "tinno": formData?.tinno,
        "isactive": formData?.isactive,
        "cby": "1",
        "con": "2024-08-02T07:35:44.421Z",
        "mon": "2024-08-02T07:35:44.421Z",
        "mby": "string",
        "isexe": formData?.isexe,
         "cusref": formData?.cusref,
        "cuspocname": formData?.cuspocname,
        "cuspocno": formData?.cuspocno, //formData?.cuspocno, missing
        "cusdesign": formData?.cusdesign,
        "ismajor": formData?.ismajor,  //formData?.ismajor, 
        "add1": formData?.add1,
        "add2": formData?.add2,
        "add3": formData?.add3,
        "add4": formData?.add4,
        "add5": formData?.add5,
        "cadd1": formData?.cadd1,
        "cadd2": formData?.cadd2,
        "cadd3": formData?.cadd3,
        "cadd4": formData?.cadd4,
        "cadd5": formData?.cadd5,
        "ceo": formData?.ceo,
        "add6": formData?.add6,
        "add7": formData?.add7,
        "cadd6": formData?.cadd6,
        "cadd7": formData?.cadd7
      }
      console.log("inside", data)

      apiService.insertCustomer(data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            code: '',
            typeid: '',
            sname: '',
            name: '',
            cityid: '',
            cperson: '',
            mobile: '',
            email: '',
            gstno: '',
            pan: '',
            tinno: '',
            isactive: '',
            cby: '',
            con: '',
            mon: '',
            mby: '',
            isexe: '',
            cusref: '',
            cuspocname: '',
            cuspocno: '',
            cusdesign: '',
            ismajor: '',
            add1: '',
            add2: '',
            add3: '',
            add4: '',
            add5: '',
            cadd1: '',
            cadd2: '',
            cadd3: '',
            cadd4: '',
            cadd5: '',
            ceo: '',
            add6: '',
            add7: '',
            cadd6: '',
            cadd7: ''
          });
          handleClose();

        }
      }).catch((err) => {
        console.log("err", err);
      });

    }

    // Update
    else {
      let data = {
        tableRows1: tableRows,
        code: formData?.code,
        typeid: formData?.typeid.toString(),
        sname: formData?.sname,
        name: formData?.name,
        cityid: formData?.cityid,
        cperson: formData?.cperson,
        mobile: formData?.mobile,
        email: formData?.email,
        gstno: formData?.gstno,
        pan: formData?.pan,
        tinno: formData?.tinno,
        isactive: formData?.isactive,
        cby: "1",
        isexe: formData?.isexe,
        
        cusref: formData?.cusref,
        cuspocname: formData?.cuspocname,
        cuspocno: formData?.cuspocno,
        cusdesign: formData?.cusdesign,
        ismajor: formData?.ismajor,
        add1: formData?.add1,
        add2: formData?.add2,
        add3: formData?.add3,
        add4: formData?.add4,
        add5: formData?.add5,
        cadd1: formData?.cadd1,
        cadd2: formData?.cadd2,
        cadd3: formData?.cadd3,
        cadd4: formData?.cadd4,
        cadd5: formData?.cadd5,
        ceo: formData?.ceo,
        add6: formData?.add6,
        add7: formData?.add7,
        cadd6: formData?.cadd6,
        cadd7: formData?.cadd7
      };
      apiService.updateCustomer(Number(formData.id), data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            code: '',
            typeid: '',
            sname: '',
            name: '',
            cityid: '',
            cperson: '',
            mobile: '',
            email: '',
            gstno: '',
            pan: '',
            tinno: '',
            isactive: '',
            cby: '',
            con: '',
            mon: '',
            mby: '',
            isexe: '',
            cusref: '',
            cuspocname: '',
            cuspocno: '',
            cusdesign: '',
            ismajor: '',
            add1: '',
            add2: '',
            add3: '',
            add4: '',
            add5: '',
            cadd1: '',
            cadd2: '',
            cadd3: '',
            cadd4: '',
            cadd5: '',
            ceo: '',
            add6: '',
            add7: '',
            cadd6: '',
            cadd7: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err);
      });
    }
  };
  const handleEditClick = (item: Data) => {
    // let isa = item?.isactive == "true"? 1 : 0;
    console.log(item.isactive);
    setFormData({
      id: item?.id.toString(),
      code: item?.code,
      typeid: item?.typeid,
      sname: item?.sname,
      name: item?.name,
      cityid: item?.cityid,
      cperson: item?.cperson,
      mobile: item?.mobile,
      email: item?.email,
      gstno: item?.gstno,
      pan: item?.pan,
      tinno: item?.tinno,
      isactive: item?.isactive,
      cby: item?.cby,
      con: item?.con,
      mon: item?.mon,
      mby: item?.mby,
      isexe: item?.isexe,
      cusid: item?.cusid,
      cusref: item?.cusref,
      cuspocname: item?.cuspocname,
      cuspocno: item?.cuspocno,
      cusdesign: item?.cusdesign,
      ismajor: item?.ismajor,
      add1: item?.add1,
      add2: item?.add2,
      add3: item?.add3,
      add4: item?.add4,
      add5: item?.add5,
      cadd1: item?.cadd1,
      cadd2: item?.cadd2,
      cadd3: item?.cadd3,
      cadd4: item?.cadd4,
      cadd5: item?.cadd5,
      ceo: item?.ceo,
      add6: item?.add6,
      add7: item?.add7,
      cadd6: item?.cadd6,
      cadd7: item?.cadd7
    });
    setTableRows(() => [
      { cusid: '', name: '' },
    ]);
    getCustomerId(item?.id)

    setSelectedItem(item);
    setOpen(true);
  };

  const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const newTableRows = [...tableRows];
    newTableRows[index][name] = value;
    console.log("newTableRows", newTableRows)

    setTableRows(newTableRows);
  };

  console.log("tableRows", tableRows);
  console.log("formData", formData);


  return (
    <>
      <Helmet>
        <title>Customer - CRM</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle
          heading="Customer"
          subHeading=""
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add New
            </Button>
          </Grid>
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {/* Employee Info Section */}
                <Grid item xs={12} >
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Customer Info</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="code"
                    label="Customer Number"
                    name='code'
                    value={formData?.code}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    margin="dense"
                    label="Type"
                    name='typeid'
                    fullWidth
                    value={formData?.typeid}
                    onChange={handleChange}
                  >
                    {type.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="sname"
                    label="ShortName"
                    name='sname'
                    value={formData?.sname}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="name"
                    label="Name"
                    name='name'
                    value={formData?.name}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    label="City"
                    name='cityid'
                    value={formData?.cityid}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  >
                  {city.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                
                
                
                



                {/* Contact Info Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Contact Info</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cperson"
                    label="Contact Person"
                    name='cperson'
                    value={formData?.cperson}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="mobile"
                    label="Mobile No"
                    name='mobile'
                    value={formData?.mobile}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!isMobileValid}
                    helperText={!isMobileValid ? 'Invalid mobile number' : ''}

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="email"
                    label="Email Id"
                    name='email'
                    value={formData?.email}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!isEmailValid}
                    helperText={!isEmailValid ? 'Invalid email id' : ''}
                  />
                </Grid>
                

                {/* Customer Details */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Customer Details</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="gstno"
                    label="GSTNO"
                    name='gstno'
                    value={formData?.gstno}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="pan"
                    label="PAN NO"
                    name='pan'
                    value={formData?.pan}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="tinno"
                    label="TIN NO"
                    name='tinno'
                    value={formData?.tinno}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    label="IS Existing"
                    name='isexe'
                    fullWidth
                    value={formData?.isexe === '' ? '' : Number(formData?.isexe)}
                    onChange={handleChange}


                  >
                    {existing.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>


                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cusref"
                    label="Reference"
                    name='cusref'
                    value={formData?.cusref}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cuspocname"
                    label="POC Name"
                    name='cuspocname'
                    value={formData?.cuspocname}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cuspocno"
                    label="POC No"
                    name='cuspocno'
                    value={formData?.cuspocno}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cusdesign"
                    label="Designation"
                    name='cusdesign'
                    value={formData?.cusdesign}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    label="IS Major"
                    name='ismajor'
                    fullWidth
                    value={formData?.ismajor === '' ? '' : Number(formData?.ismajor)}
                    onChange={handleChange}


                  >
                    {major.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  </Grid>



                {/* Permanent Address Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Permanent Address</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add1"
                    label="House No"
                    name='add1'
                    value={formData?.add1}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add2"
                    label="Street Address"
                    name='add2'
                    value={formData?.add2}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add3"
                    label="Landmark"
                    name='add3'
                    value={formData?.add3}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add4"
                    label="City"
                    name='add4'
                    value={formData?.add4}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add5"
                    label="State"
                    name='add5'
                    value={formData?.add5}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>

                {/* Current Address Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Current Address</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd1"
                    label="House No"
                    name='cadd1'
                    value={formData?.cadd1}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd2"
                    label="Street Address"
                    name='cadd2'
                    value={formData?.cadd2}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd3"
                    label="Landmark"
                    name='cadd3'
                    value={formData?.cadd3}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd4"
                    label="Region/City"
                    name='cadd4'
                    value={formData?.cadd4}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd5"
                    label="State"
                    name='cadd5'
                    value={formData?.cadd5}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    margin="dense"
                    label="Status"
                    name='isactive'
                    fullWidth
                    value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                    onChange={handleChange}
                  >
                    {status.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                </Grid>

                <Grid item xs={12}>
                  <form action="#">
                    <Card>
                      <CardHeader title="Division" />
                      <Paper>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>S.No</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Name</TableCell>



                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tableRows?.map((row: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>

                                  <TableCell>
                                    <textarea
                                      id='cusid'
                                      value={row.cusid}
                                      name="cusid"
                                      onChange={(e) => handleTableRowsChange(index, e)}
                                      rows={1}

                                      style={{
                                        height: 50,
                                        width: '40',
                                        padding: '10px',
                                        boxSizing: 'border-box',
                                        border: '1px solid #ccc',
                                        borderRadius: '10px',
                                        fontSize: '16px',
                                        // borderColor:'blue'
                                      }}
                                    />
                                  </TableCell>

                                  <TableCell>
                                    <TextField
                                      required
                                      id='name'
                                      type="string"
                                      name="name"
                                      value={row.name}
                                      onChange={(e) => handleTableRowsChange(index, e)}
                                      sx={{ m: 1, width: '28ch' }}
                                    />
                                  </TableCell>

                                </TableRow>
                              ))}
                            </TableBody>

                          </Table>

                        </TableContainer>

                      </Paper>
                      {/* <Button   variant="contained" color="primary">Add Row</Button> */}

                    </Card>

                  </form></Grid>
                <Button sx={{ margin: 1 }} variant='contained' color="primary" onClick={handleAddRow}>
                  <FontAwesomeIcon icon={faPlus} />  Add More
                </Button>







              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={submit} variant='contained' color="primary" disabled={!isMobileValid || !isEmailValid}>
                {selectedItem ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </Dialog>
          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>CUSTOMER No</TableCell>
                      <TableCell>ShortName</TableCell>
                      <TableCell>Name</TableCell>
                      {/* <TableCell>Company</TableCell>
                            <TableCell>Department</TableCell> */}
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                      {/* Add more headers as necessary */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice().map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.code}</TableCell>
                        <TableCell>{row.sname}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        {/* <TableCell>{row.compname}</TableCell>
                              <TableCell>{row.deptname}</TableCell> */}
                        <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                          {row.isactive == "1" ? "Active" : "In-Active"}
                        </TableCell>
                        <TableCell>
                        <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                        </TableCell>
                        {/* Add more cells as necessary */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={data.length === 0 ? 0 : data[0]["count"]}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Footer />
    </>
  );
}

export default Customer;
function setType(data: any) {
  throw new Error("Function not implemented.");
}

