import React, { useEffect, useRef, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, Button, MenuItem, Grid, useMediaQuery, useTheme } from '@mui/material';
import apiService from 'src/services/api-service';
import { useLocation, useNavigate } from 'react-router-dom';
import { successToast } from 'src/layouts/utile/toast';
import { useReactToPrint } from 'react-to-print';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';

const formatDateToYYYYMMDD = (date) => {
  if (!date) return '';
  return format(new Date(date), 'yyyy-MM-dd');
};

interface FollowDataItem {

  id: number;
  fdate: Date; // Change from string to Date
  ftypeid: number;
  priority: string;
  fby: number;
  desp: string;
  nfdate: Date; // Change from string to Date
  statusid: number;
  ftypename: string;
  fbyname: string;
  statusname: string;
  fdate_s: string;
  nfdate_s: string;


}
type FollowData = FollowDataItem[];

interface EnqQuote {
  id: number;
  qdate: Date;          // Date object for date values
  qdate_s: string;      // String representation of date, if needed
  qno: string;          // Quote number
  rno: number;          // Reference number
  qval: number;         // Quote value
  cusref: string;       // Customer reference
  cuspocname: string;  // Customer Point of Contact name
  cuspocno: string;    // Customer Point of Contact number
  cusdesign: string;   // Customer design
  subject: string;     // Subject of the quote
}

interface EssentialTableRow {
  enqdetid: number;
  essdataid: number;
  compliance: string;
  attach: string;
}

interface TableRowData {
  id: number;
  enqdetid: number | null;  // Null or number depending on the data
  desp: string;
  mpnno: string;     // Null or number depending on the data
  qty: number;
  essentialTableRows: EssentialTableRow[];
  unitPrice?: number; // Add this if it is part of your data
  taxableAmount?: number; // Add this if it is part of your data
  igst?: number;
}

interface FormData {
  tableRows: TableRowData[];
  enqfollow: FollowDataItem[];
  enqquote: EnqQuote[];
  enqNo: string,
  enqdate: string,
  updcheck: boolean;
  finYearId: number;
  lenessrow: number | null;
  empId: number;
  enqID: number | null;
  typeID: number;
  newEx: string;
  modeId: number;
  divId: number;
  rfxEnqNo: number | null;
  dueOn: Date;
  statusId: number;
  desp: string;
  basicValue: number;
  ccperson: string;
  cdesign: string;
  cmobile: string;
  cmail: string;
  tcperson: string;
  tdesign: string;
  tmobile: string;
  tmail: string;
  bankName: string;
  branchname: string;
  accNo: string;
  ifsc: string;
  isExcus: boolean;
  excusid: number | null;
  sname: string;
  code: string;
  name: string;
  city: string;
  ceo: string;
  md: string;
  mp: string;
  caddr1: string;
  caddr2: string;
  caddr3: string;
  caddr4: string;
  caddr5: string;
  caddr6: string;
  caddr7: string;
  faddr1: string;
  faddr2: string;
  faddr3: string;
  faddr4: string;
  faddr5: string;
  faddr6: string;
  faddr7: string;
  panno: string;
  tinno: string;
  email: string;
  omobile: string;
  pmobile: string;
  gstno: string;
  nbus: string;
  ncomp: string;
  datestart: Date | null;
  cpname: string;
  cpdesign: string;
  cpmobile: string;
  cpmail: string;
  annualturnover: number;
  buildOR: string;
  followtype: string;
  followdesc: string;
  isqul: boolean | null;
  nextfollow: string;
  priority: string;
  nfteamid: number | null;
  nfempid: number | null;
  attch: string;
}
interface GST {
  id: number;
  name: string;
}

interface LocationState1 {
  id: string;
}

const Quotation: React.FC = () => {

  const location = useLocation();
  const state = location.state as LocationState1;

  const [companyData, setCompanyData] = useState<any[]>([]); // Assuming companyData will be an array of objects
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [term, setTerm] = useState([])
  const defaultTextValues = [
    "Many thanks for enquiry. We are pleased to offer our best Quotation for the supply of Transit Cases.",
    "Looking forward to your valuable purchase order to serve you on time."
  ];

  const [formData, setFormData] = useState<FormData>({
    tableRows: [
      {
        id: 0,
        enqdetid: null,
        desp: "",
        mpnno: '',
        qty: 0,
        unitPrice: 0,
        taxableAmount: 0,
        essentialTableRows: [
          {
            enqdetid: 0,
            essdataid: 0,
            compliance: "",
            attach: ""
          }
        ]
      },
    ],
    enqfollow: [
      {
        id: 0,
        fdate: new Date(),
        ftypeid: 0,
        priority: "",
        fby: 0,
        desp: "",
        nfdate: new Date(),
        statusid: 0,
        ftypename: "",
        fbyname: "",
        statusname: "",
        fdate_s: "",
        nfdate_s: ""
      }
    ],
    enqquote: [
      {
        id: 0,
        qdate: new Date(),
        qdate_s: "",
        qno: "",
        rno: 0,
        qval: 0,
        cusref: "",
        cuspocname: "",
        cuspocno: "",
        cusdesign: "",
        subject: ""
      }
    ],
    updcheck: false,
    finYearId: 0,
    lenessrow: null,
    enqNo: '',
    enqdate: '',
    empId: 0,
    enqID: 0,
    typeID: 0,
    newEx: "",
    modeId: 0,
    divId: 0,
    rfxEnqNo: null,
    dueOn: new Date(),
    statusId: 0,
    desp: "",
    basicValue: 0,
    ccperson: "",
    cdesign: "",
    cmobile: "",
    cmail: "",
    tcperson: "",
    tdesign: "",
    tmobile: "",
    tmail: "",
    bankName: "",
    branchname: "",
    accNo: "",
    ifsc: "",
    isExcus: false,
    excusid: null,
    sname: "",
    code: "",
    name: "",
    city: "",
    ceo: "",
    md: "",
    mp: "",
    caddr1: "",
    caddr2: "",
    caddr3: "",
    caddr4: "",
    caddr5: "",
    caddr6: "",
    caddr7: "",
    faddr1: "",
    faddr2: "",
    faddr3: "",
    faddr4: "",
    faddr5: "",
    faddr6: "",
    faddr7: "",
    panno: "",
    tinno: "",
    email: "",
    omobile: "",
    pmobile: "",
    gstno: "",
    nbus: "",
    ncomp: "",
    datestart: null,
    cpname: "",
    cpdesign: "",
    cpmobile: "",
    cpmail: "",
    annualturnover: 0,
    buildOR: "",
    followtype: "",
    followdesc: "",
    isqul: null,
    nextfollow: "",
    priority: "",
    nfteamid: null,
    nfempid: null,
    attch: ""
  });


  const [quoteData, setQuoteData] = useState({
    enqquotedet: formData.tableRows.map(row => ({
      enqdetid: row.id,
      desp: row.desp,
      mpnno: row.mpnno,
      qty: Number(row.qty), // Ensure qty is a number
      unitprice: row.unitPrice,
      taxableamt: row.taxableAmount,
      taxamt: 0,
      total: 0
    })),
    query: '',
    qdate: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].qdate : new Date(), // Provide a default value
    enqid: state.id,

    cusref: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cusref : '',
    cuspocname: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cuspocname : '',
    cuspocno: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cuspocno : '',
    cusdesign: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cusdesign : '',
    qno: 0,
    status: 'Approval pending',
    qval: 0,
    lastqid: 0,
    subject: '',
    cby: "1",
    taxid: 1,
    taxamt: 0,
    total: 0,
  });

  // Ensure you have a way to set the item field
  const handleItemChange = (event) => {
    setQuoteData(prevData => ({
      ...prevData,
      item: event.target.value
    }));
  };

  

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [currentDate] = useState(getCurrentDate());
  const [gst, setGst] = useState<GST[]>([]);


  useEffect(() => {
    apiService.getDropdown("mas_company").then(response => {
      setCompanyData(response.data?.map((data: any) => ({
        id: data?.id,
        name: data?.name,
        sname: data?.sname,
        mobile: data?.mobile,
        phone: data?.phone,
        email: data?.email,
        pan: data?.pan,
        gstno: data?.gstno,
        tinno: data?.tinno,
        hsncode: data?.hsncode,
        gemsellerid: data?.gemsellerid,
        add1: data?.add1,
        add2: data?.add2,
        add3: data?.add3,
        add4: data?.add4,
        add5: data?.add5,
        mon: data?.mon
      })));
    }).catch(err => {
      console.log("err", err);
    });
  }, []);

  useEffect(() => {
    apiService.getDropdown("saarc_smt.crm_mas_quoteterms")
      .then(response => {
        setTerm(response.data);
        console.log("setTerm", response.data);
      })
      .catch(err => {
        console.log("err", err);
      });
    apiService.getDropdown("mas_tax").then(response => {
      setGst(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
  }, []);



  useEffect(() => {
    console.log(state.id, "ttttt");
    if (state.id) {
      // Fetch data if id is provided (for updating an enquiry)
      apiService.getId(Number(state.id))
        .then(response => {
          const data = response.data;
          console.log("response.Quotation", data);

          // Update the formData state with the fetched data
          setFormData(data as FormData);
          console.log("formData", formData);
        })
        .catch(error => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [state.id]);

  useEffect(() => {
    if (companyData.length > 0) {
      setSelectedCompany(companyData[0]);
    }
  }, [companyData]);

  const [igst, setIgst] = useState<number>(0);


  useEffect(() => {
    const totalAmount = formData.tableRows.reduce((sum, row) => sum + (row.taxableAmount || 0), 0);
    const finalTotal = totalAmount + igst;

    setQuoteData(prevQuoteData => ({
      ...prevQuoteData,
      enqquotedet: formData.tableRows.map(row => ({
        enqdetid: row.id,
        desp: row.desp,
        mpnno: row.mpnno,
        qty: row.qty,
        unitprice: row.unitPrice,
        taxableamt: row.taxableAmount,
        taxamt: 0, // Assuming IGST is 18%
        total: 0
      })),
      qval: totalAmount,
      taxamt: igst,
      total: finalTotal
    }));
  }, [formData, igst]);

  const handleInputChange = (index, field, value) => {
    const newRows = [...formData.tableRows];

    newRows[index] = {
      ...newRows[index],
      [field]: field === 'qty' || field === 'unitPrice'
        ? parseFloat(value) || 0
        : value, // Keep as string for other fields
    };

    if (field === 'qty' || field === 'unitPrice') {
      newRows[index].taxableAmount = (newRows[index].qty || 0) * (newRows[index].unitPrice || 0);
    }

    setFormData(prevFormData => ({
      ...prevFormData,
      tableRows: newRows
    }));
  };


  const calculateTotal = () => {
    return formData.tableRows.reduce((sum, row) => sum + (row.taxableAmount || 0), 0);
  };

  const calculateUnitprice = () => {
    return formData.tableRows.reduce((sum, row) => sum + (row.unitPrice || 0), 0);
  };

  const unittotal = calculateUnitprice()
  const totalAmount = calculateTotal();
  // const igstAmount = calculateIGST(totalAmount+unittotal);
  const finalTotal = totalAmount + igst + unittotal;


  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const dataToUpdate = quoteData;
      console.log("Quotation", dataToUpdate);

      const response = await apiService.getQuote(dataToUpdate);

      console.log('Form insert:', response.data);
      navigate('/crm/updateenquiry', { state: { message: 'Quotation Created successfully!' } });
      console.log('Quote saved successfully:', response.data);
      // Handle success (e.g., show a success message, redirect, etc.)
    } catch (error) {
      console.error('Error saving quote:', error);
      // Handle error (e.g., show an error message)
    }

  };

  const handleQuoteInputChange = (field, value) => {
    setQuoteData(prevQuoteData => ({
      ...prevQuoteData,
      [field]: value,
    }));
  };


  const [isPrintMode, setIsPrintMode] = useState<boolean>(false);

  const contentToPrint = useRef(null);
  const handlePrint1 = useReactToPrint({
    onBeforePrint: () => setIsPrintMode(true),
    onAfterPrint: () => setIsPrintMode(false),
    removeAfterPrint: true,


  });
  console.log('IsPrintMode', isPrintMode);


  // const [igst, setIgst] = useState<number>(0);

  useEffect(() => {
    calculateGST();
  }, [quoteData.qval, quoteData.taxid]);

  const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = event.target.value as number;
    quoteData.taxid = value;
    calculateGST();
  };

  const calculateGST = () => {
    const gstRate = quoteData.taxid === 1 ? 0.18 : 0.12;
    const gstAmount = quoteData.qval * gstRate;
    setIgst(gstAmount);
  };

  const getTaxNameById = (id: number): string => {
    const taxOption = gst.find(option => option.id === id);
    return taxOption ? taxOption.name : '';
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <>   <Helmet>
      <title>Quotation</title>
    </Helmet>

      <Box ref={contentToPrint}>
        <Box className="print-container">
          <Box p={4} sx={{ fontFamily: 'Arial, sans-serif', bgcolor: '#fff' }}>
            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={12} mb={-2}>
                <Grid container alignItems="center" spacing={1} direction="row">
                  <Grid item>
                    <img
                      src={require('src/assets/logosaarc.png')}
                      alt="Company Logo"
                      style={{ width: 40, height: 40 }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        color: 'red',
                        fontSize: 23,
                        fontFamily: 'Arial, sans-serif',
                        display: 'inline',
                      }}
                    >
                      {selectedCompany?.name?.split(' ')[0]}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                        color: '#000',
                        fontSize: 22,
                        fontFamily: 'Arial, sans-serif',
                        display: 'inline',
                      }}
                    >
                      &nbsp;{selectedCompany?.name?.split(' ').slice(1).join(' ')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={7} sm={7}>

                <Box sx={{ bgcolor: '#e0e0e0', p: 2, borderRadius: '5px' }}>
                  <Typography variant="subtitle2" sx={{ fontFamily: 'Arial, sans-serif', color: 'red', mb: 1, fontSize: 18 }}>
                    PE Molded Advanced CEMILAC certified Mil Std. Containers
                  </Typography>
                  <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                    {selectedCompany?.add1}, {selectedCompany?.add2}
                  </Typography>
                  <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                    {selectedCompany?.add3} - {selectedCompany?.add5}, {selectedCompany?.add4}, India
                  </Typography>
                  <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                    Phone: {selectedCompany?.phone} Fax: {selectedCompany?.mobile}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1, fontFamily: 'Arial, sans-serif', fontSize: 18 }}>
                    <strong>GST No:</strong> {selectedCompany?.gstno} &nbsp;&nbsp; <strong>HSN Code:</strong> {selectedCompany?.hsncode}
                  </Typography>
                  <Typography variant="body2" fontSize="18" fontFamily='Arial, sans-serif'>
                    <strong>GEM SELLER ID:</strong> {selectedCompany?.gemsellerid}
                  </Typography>
                </Box>
                <Box>
                  {/* <br /> */}
                  <Grid item xs={12} sm={7} md={7} >
                    <Box sx={{ p: 1, borderRadius: '5px' }}>
                      <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 17, fontFamily: 'Arial, sans-serif' }} variant="body2" >
                        To
                      </Typography>
                      <Typography sx={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', fontSize: 14 }} variant="body2" paragraph>
                        {formData?.faddr1},{formData?.faddr2},<br />
                        {formData?.faddr3},{formData?.faddr5},<br />
                        {formData?.faddr4}, {formData?.faddr6} ,{formData?.faddr7}

                      </Typography>
                    </Box>
                  </Grid>
                </Box>

              </Grid>

              <Grid item xs={5}>

                <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'Black', fontSize: 20 }}>
                  BUDGETARY QUOTATION
                </Typography>
                <Grid container direction="row" spacing={1} mt={1}>

                  <Grid item xs={12} sm={6} md={6} >
                    <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>SAARC Enquiry No:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-6px' }}>

                    {!isPrintMode ? (
                      <TextField
                        onChange={(e) => handleQuoteInputChange('enqNo', e.target.value)}
                        variant="outlined"
                        value={formData?.enqNo}
                        // fullWidth
                        sx={{
                          height: '30px',
                          '& .MuiInputBase-root': {
                            height: '100%',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: '100%',
                          },
                        }}

                      />
                    ) : (
                      <Typography variant="body2">{formData?.enqNo}</Typography>
                    )}
                  </Grid><br />
                  <Grid item xs={12} sm={6} md={6} >
                    <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Quotation No:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-4px' }}>
                    {!isPrintMode ? (
                      <TextField
                        value={quoteData.qno}
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        sx={{
                          height: '30px',
                          '& .MuiInputBase-root': {
                            height: '100%',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: '100%',
                          },
                        }}

                      // fullWidth
                      />
                    ) : (
                      <Typography variant="body2">{quoteData.qno}</Typography>
                    )}
                  </Grid><br />
                  <Grid item xs={12} sm={6} md={6} >
                    <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Quotation Date:</Typography></Grid>
                  <Grid item xs={12} sm={6} md={6} >
                    {!isPrintMode ? (
                      <TextField
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        name="qdate"
                        value={formatDateToYYYYMMDD(formData?.enqquote?.[0]?.qdate)}
                        onChange={(e) => handleQuoteInputChange('qdate', e.target.value)}
                        variant="outlined"
                        sx={{
                          width: '91%',
                          height: '30px',
                          '& .MuiInputBase-root': {
                            height: '100%',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: '100%',
                          },
                        }}
                      />
                    ) : (
                      <Typography variant="body2">
                        {new Date(quoteData.qdate).toLocaleDateString('en-GB')} {/* Adjust locale as needed */}
                      </Typography>
                    )}

                  </Grid><br />
                  <Grid item xs={12} sm={6} md={6} >
                    <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer Ref:</Typography></Grid>
                  <Grid item xs={12} sm={6} md={6} >
                    {!isPrintMode ? (
                      <TextField
                        onChange={(e) => handleQuoteInputChange('cusref', e.target.value)}
                        variant="outlined"
                        value={formData?.enqquote?.[0]?.cusref}

                        // fullWidth
                        sx={{
                          height: '30px',
                          '& .MuiInputBase-root': {
                            height: '100%',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: '100%',
                          },
                        }}

                      />
                    ) : (
                      <Typography variant="body2">{quoteData.cusref}</Typography>
                    )}
                  </Grid><br />
                  <Grid item xs={12} sm={6} md={6} >
                    <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer POC Name:</Typography></Grid>
                  <Grid item xs={12} sm={6} md={6} >
                    {!isPrintMode ? (
                      <TextField
                        onChange={(e) => handleQuoteInputChange('cuspocname', e.target.value)}
                        variant="outlined"
                        value={formData?.enqquote?.[0]?.cuspocname}
                        // fullWidth
                        sx={{
                          height: '30px',
                          '& .MuiInputBase-root': {
                            height: '100%',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: '100%',
                          },
                        }}

                      />
                    ) : (
                      <Typography variant="body2">{quoteData.cuspocname}</Typography>
                    )}
                  </Grid><br />
                  <Grid item xs={12} sm={6} md={6} >
                    <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer POC No:</Typography></Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    {!isPrintMode ? (
                      <TextField
                        onChange={(e) => handleQuoteInputChange('cuspocno', e.target.value)}
                        variant="outlined"
                        value={formData?.enqquote?.[0]?.cuspocno}
                        // fullWidth
                        sx={{
                          width: '140%',
                          height: '30px',
                          '& .MuiInputBase-root': {
                            height: '100%',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: '100%',
                          },
                        }}

                      />
                    ) : (
                      <Typography variant="body2">{quoteData.cuspocno}</Typography>
                    )}
                  </Grid><br />
                  <Grid item xs={12} sm={6} md={6} >
                    <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Designation:</Typography></Grid>
                  <Grid item xs={12} sm={6} md={6} >
                    {!isPrintMode ? (
                      <TextField
                        onChange={(e) => handleQuoteInputChange('cusdesign', e.target.value)}
                        variant="outlined"
                        value={formData?.enqquote?.[0]?.cusdesign}
                        // fullWidth
                        sx={{
                          height: '30px',
                          '& .MuiInputBase-root': {
                            height: '100%',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: '100%',
                          },
                        }}

                      />
                    ) : (
                      <Typography variant="body2">{quoteData.cusdesign}</Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}  >
                <Box
                  sx={{
                    position: 'relative',
                    marginTop: '-5%',
                    width: '100%',
                    padding: isSmallScreen ? '0px' : '5px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Typography
                    variant="body2"
                    paragraph
                    fontFamily='Arial, sans-serif'
                    color='black'
                    sx={{
                      width: '100%',
                      padding: '10px',
                      boxSizing: 'border-box',
                    }}
                  >
                    Dear Sir/Madam,
                    <br />
                    {!isPrintMode ? (
                      <TextField
                        variant="outlined"
                        defaultValue={defaultTextValues}
                        multiline
                        rows={isSmallScreen ? 4 : 2}
                        sx={{
                          width: '100%',
                          maxWidth: '1130px',
                          '& .MuiInputBase-root': {
                            height: 'auto',
                            padding: '10px',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: 'auto',
                          },
                        }}
                      />
                    ) : (
                      <div className="print-value" style={{
                        width: '100%',
                        maxWidth: '1130px',
                        padding: '10px',
                        boxSizing: 'border-box',
                      }}>
                        {defaultTextValues}
                      </div>
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <TableContainer
              // component={Paper}
              className="print-table"
              sx={{
                width: '100%',
                padding: isSmallScreen ? '0px' : '5px',
                boxSizing: 'border-box',
                overflowX: isPrintMode ? 'hidden' : 'auto',
                overflowY: isPrintMode ? 'hidden' : 'auto',
                marginTop: '-2%'
              }}
            >
              <Table sx={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '5%' }}>Sl. No.</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '26%' }}>Description</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>SAARC MPN No</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '14%' }}>Quantity</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Unit Price</TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Taxable Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.tableRows.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ height: '30px' }}
                    >
                      <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>{index + 1}</TableCell>
                      <TableCell align="left" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                        {isPrintMode ? (
                          <div className="print-desp">{item.desp}</div>
                        ) : (
                          <TextField
                            sx={{
                              width: '100%',
                              height: '35px',
                              '& .MuiInputBase-root': {
                                height: '100%',
                              },
                              '& .MuiOutlinedInput-root': {
                                height: '100%',
                              },
                            }}
                            value={item.desp}
                            onChange={(e) => handleInputChange(index, 'desp', e.target.value)}
                            className={isPrintMode ? 'no-print' : ''}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                        {isPrintMode ? (
                          <div className="print-mpnno">{item.mpnno}</div>
                        ) : (
                          <TextField
                            sx={{
                              width: '100%',
                              height: '35px',
                              '& .MuiInputBase-root': {
                                height: '100%',
                              },
                              '& .MuiOutlinedInput-root': {
                                height: '100%',
                              },
                            }}
                            value={item.mpnno}
                            onChange={(e) => handleInputChange(index, 'mpnno', e.target.value)}
                            className={isPrintMode ? 'no-print' : ''}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                        {isPrintMode ? (
                          <div className="print-qty">{item.qty}</div>
                        ) : (
                          <TextField
                            sx={{
                              width: '100%',
                              height: '35px',
                              '& .MuiInputBase-root': {
                                height: '100%',
                              },
                              '& .MuiOutlinedInput-root': {
                                height: '100%',
                              },
                            }}
                            type="number"
                            value={item.qty || ''}
                            onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                            className={isPrintMode ? 'no-print' : ''}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                        {isPrintMode ? (
                          <div className="print-unitPrice">{item.unitPrice}</div>
                        ) : (
                          <TextField
                            sx={{
                              width: '100%',
                              height: '35px',
                              '& .MuiInputBase-root': {
                                height: '100%',
                              },
                              '& .MuiOutlinedInput-root': {
                                height: '100%',
                              },
                            }}
                            type="number"
                            value={item.unitPrice || '0'}
                            onChange={(e) => handleInputChange(index, 'unitPrice', e.target.value)}
                            className={isPrintMode ? 'no-print' : ''}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                        {item.taxableAmount || 0}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={6} align="left" sx={{ borderBottom: '1px solid #000', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                      Comments or Special Instructions:
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                      TOTAL:
                    </TableCell>
                    <TableCell align="center">₹ {quoteData.qval.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow sx={{ height: '30px' }}>
                    <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                      {!isPrintMode ? (
                        <TextField
                          select
                          name="taxid"
                          id="taxid"
                          value={quoteData.taxid}
                          sx={{
                            width: '36%',
                            height: '35px',
                            '& .MuiInputBase-root': {
                              height: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                              height: '100%',
                            },
                          }}
                          onChange={handleSelectChange}
                          className={isPrintMode ? 'no-print' : ''}
                        >
                          {gst.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      ) : (
                        <div className="print-tax">{getTaxNameById(quoteData.taxid)}</div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {!isPrintMode ? (
                        <TextField
                          sx={{
                            width: '100%',
                            height: '35px',
                            '& .MuiInputBase-root': {
                              height: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                              height: '100%',
                            },
                          }}
                          type="number"
                          value={igst}
                          onChange={(e) => setIgst(parseFloat(e.target.value) || 0)}
                          className={isPrintMode ? 'no-print' : ''}
                        />
                      ) : (
                        <div className="print-igst">{igst.toFixed(2)}</div>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold', borderTop: '1px solid #000' }}>
                      TOTAL:
                    </TableCell>
                    <TableCell align="center" sx={{ borderTop: '1px solid #000' }}>₹ {quoteData.total.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box mt={2}>
              <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 18 }}>
                SPECIAL TERMS & CONDITIONS:
              </Typography>
            </Box><br />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="initial"
              alignItems="flex-start"
              textAlign="left"
              sx={{ position: 'relative', left: '52px' }}
            >
              <Box>
                {term.filter(item => item.type === "0").map((item, index) => (
                  <Box key={index} mb={1}>
                    <Typography
                      sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, fontFamily: 'Arial, sans-serif' }}
                      variant="body2"
                    >
                      {item?.sname}:
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box sx={{ position: 'relative', left: '40px' }}>
                {term.filter(item => item.type === "0").map((item, index) => (
                  <Box key={index} mb={1}>
                    <Typography
                      sx={{ fontSize: 14, color: 'black', fontFamily: 'Arial, sans-serif' }}
                      variant="body2"
                    >
                      {item?.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box

              mt={2}
            >
              <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 18, color: 'black', fontFamily: 'Arial, sans-serif' }}><strong>STANDARD TERMS & CONDITIONS:</strong></Typography><br />
              {term.filter(item => item.type === "1").map((item, index) => (
                <Box key={index} mb={1.5}>
                  <Typography
                    sx={{ fontSize: 14, color: 'black', fontFamily: 'Arial, sans-serif' }}
                    variant="body2"
                  >
                    {index + 1} . {item?.name}
                  </Typography>
                </Box>
              ))}
              <Box
                sx={{
                  width: '250px',
                  height: '45px',
                  bgcolor: '#e0e0e0',
                  p: 1,
                  borderRadius: '5px',
                }}
              >
                <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                  Queries to be Addressed to:
                </Typography>
              </Box>
              <br />
              <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black' fontSize={14}>
                Mr/s. V. Suganthy, Mob: 8807060127 | Email: marketing@saarctecs.com<br />
                Mr/s. C. Srinivita, Mob: 8807060125 | Email: marketing@saarctecs.com<br />
                Mr/M. Sridavi, Mob: 8807042319 | Email: applicationengg@saarctecs.com
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="45px"
              >
                <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                  THANK YOU FOR YOUR BUSINESS SUPPORT
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="caption"><strong>Prepared By</strong></Typography>
                </Box>
                <Box>
                  <Typography variant="caption">Checked By</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box><Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handlePrint1(null, () => contentToPrint.current);
            }}
            sx={{ position: 'absolute', left: 10, bottom: 25, margin: 1 }}
            className="no-print"
          >
            Print
          </Button>

          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button sx={{ position: 'absolute', right: 40, bottom: 25, margin: 1 }} onClick={handleSubmit} variant="contained" color="primary" className="no-print" >
              Save
            </Button>
          </Box>
        </Box>

      </Box>
      <style>
        {`
        @media print {
                      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                          text-align: left;
                          
                          bottom: 0;
                          right: 0;
                          top: -5px;
                          left: 0;
                          margin: 0;
                          padding: 0 8px;
                          pointer-events: none;
                          border-radius: inherit;
                          border-style: solid;
                          border-width: 0px !important;
                          overflow: hidden;
                          min-width: 0%;
                          border-color: rgba(0, 0, 0, 0.23);
                           .print-table {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    font-size: 9pt;
  }

  .no-print {
    display: none;
  }

  .print-desp,
  .print-mpnno,
  .print-qty,
  .print-unitPrice,
  .print-tax,
  .print-igst {
    font-size: 9pt;
  }

  @page {
    size: A4;
    margin: 10mm;
  }

                      }
                            
                    }

                    @media screen {
                        .printable-content {
                            display: none;
                        }
                    }
                        @media print {
  .no-print {
    display: none;
  }
}
                `}
      </style>

    </>
  );
};


export default Quotation;
