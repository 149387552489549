import { useEffect, useState } from 'react';
import { errorToast, successToast } from '../layouts/utile/toast';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from '../components/PageTitleWrapper';
import PageTitle from '../components/PageTitle';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material';
import { ToastContainer } from 'react-toastify';
import Footer from '../components/Footer';
import apiService from '../services/api-service';
import { FaPencil } from 'react-icons/fa6';

// For Grid List
interface Data {
  sname: string;
  name: string;
  per: number;
  isactive: string;
  id: number;

  // Add more fields as necessary
}

const status = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];

function Tax() {
  // For Grid Table
  const [data, setData] = useState<Data[]>([]);

  // For Grid Table Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //Form data
  const [formData, setFormData] = useState<any>({
    id: '',
    sname: '',
    name: '',
    per: '',
    isactive: '',
    cby: '',
    con: '',
    mby: '',
    mon: ''
  });

  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    apiService
      .getTax(rowsPerPage.toString(), (page + 1).toString(), '')
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [rowsPerPage, page, formData]);

  //For Pagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Textbox and Select box onchage
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: '',
      sname: '',
      name: '',
      per: '',
      isactive: '',
      cby: '',
      con: '',
      mby: '',
      mon: ''
    });
    setSelectedItem(null);
  };

  // Insert and Update
  let submit = (event: any) => {
    event.preventDefault(); // Prevents default form submission behavior
    // Insert
    if (selectedItem == null) {
      let data = {
        sname: formData?.sname,
        name: formData?.name,
        per: formData?.per,
        isactive: formData?.isactive,
        cby: '1'
      };

      apiService
        .insertTax(data)
        .then((response) => {
          console.log('response', response.data);
          if (response?.data.key == '400') {
            errorToast(response?.data?.message);
          } else {
            successToast(response.data);
            setSelectedItem(null);
            setFormData({
              sname: '',
              name: '',
              per: '',
              isactive: '',
              cby: '',
              con: '',
              mby: '',
              mon: ''
            });
            handleClose();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
    // Update
    else {
      let data = {
        id: formData?.id,
        sname: formData?.sname,
        name: formData?.name,
        per: formData?.per,
        isactive: formData?.isactive,
        cby: '1'
      };
      apiService
        .updateTax(Number(formData.id), data)
        .then((response) => {
          if (response?.data.key == '400') {
            errorToast(response?.data?.message);
          } else {
            successToast(response.data);
            setSelectedItem(null);
            setFormData({
              id: '',
              sname: '',
              name: '',
              per: '',
              isactive: '',
              cby: '',
              con: '',
              mby: '',
              mon: ''
            });
            handleClose();
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };
  // Handler for Edit Button click
  const handleEditClick = (item: Data) => {
    setFormData({
      id: item?.id.toString(),
      sname: item?.sname,
      name: item?.name,
      per: item?.per,
      isactive: item?.isactive
    });
    setSelectedItem(item);
    setOpen(true);
  };
  return (
    <>
      <Helmet>
        <title>Tax - Admin</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle heading="Tax" subHeading="" />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              Add New
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Short Name</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Tax</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice().map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.sname}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.per}</TableCell>
                        <TableCell
                          style={{
                            color: row.isactive == '1' ? 'green' : 'red'
                          }}
                        >
                          {row.isactive == '1' ? 'Active' : 'In-Active'}
                        </TableCell>
                        <TableCell>
                        <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={Math.floor(data.length == 0 ? 0 : data[0]['count'])}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <form>
            <DialogTitle>{selectedItem ? 'Edit' : 'Add'}</DialogTitle>
            <DialogContent>
              <TextField
                required
                margin="dense"
                id="sname"
                label="Short Name"
                name="sname"
                fullWidth
                value={formData?.sname}
                onChange={handleChange}
              />
              <TextField
                required
                margin="dense"
                id="name"
                label="Name"
                name="name"
                fullWidth
                value={formData?.name}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                id="per"
                label="Tax"
                name="per"
                fullWidth
                value={formData?.per}
                onChange={handleChange}
              />
              <TextField
                select
                margin="dense"
                label="Status"
                name="isactive"
                fullWidth
                value={
                  formData?.isactive === '' ? '' : Number(formData?.isactive)
                }
                onChange={handleChange}
              >
                {status.map((option: { value: any; label: any }) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={submit} variant="contained" color="primary">
                {selectedItem ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Container>
      <Footer />
    </>
  );
}
export default Tax;
