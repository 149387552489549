import axios from "axios";

 axios.defaults.withCredentials = true;
export default axios.create({
  withCredentials : true,
   baseURL: "https://saarcapi.netkampus.in/api",
 //  baseURL: "https://localhost:7202/api",
    //  baseURL: "http://192.168.1.45:86/api",
  headers: {
    "Content-type": "application/json", 
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
});

