// import { TabPanel } from '@mui/lab';
import { Box, Button, Card, CardContent, CardHeader, Container, DialogActions, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from 'src/services/api-service';
import { successToast } from 'src/layouts/utile/toast';
import './designdev.css'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { AlignHorizontalCenter } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

interface Data1 {
  id: number;
  enqno: string;
  enqdate: Date;
  cusname: string;
}

interface LocationState1 {
  id: number;
}

interface Data {
  id: number;
  name: string;
  compliance: string;
  attach: string;
  remarks: string;
}

interface Test {
  id: number;
  type: string;
  name: string;
}

interface Model {
  id: number;
  casemodel: string;
}

interface EssData {
  essdataid: string;
  remarks: string;
  cby: string;
  name: string;
  attach: string;
  compliance: string;
}

interface TestAccess {
  type: string;
  testaccessid: string;
  name: string;
  bit: string;
}

interface FormData {
  ifinsert: boolean;
  informid: number;
  partno: string;
  drawid: string | number; // Assuming state.id is either a string or number, adjust as needed
  ecnid: number;
  refno: string;
  issueno: string;
  pagerevno: string;
  intype: string;
  casetype: string;
  casemodelid: number;
  result: string;
  designby: string;
  authby: string;
  status: string;
  cby: string;
  listessdata: EssData[];
  listtest: TestAccess[];
  listaccess: TestAccess[];
  listitem: TestAccess[];
  listrequire: TestAccess[];
}

interface ReviewData {
  listreqitems: ReqItems[];
  ifinsert: boolean;
  drawid: string | number;
  enqdetid: number;
  eqipsize: string;
  eqipwt: string;
  refdrgthere: number;
  suplocspd: number;
  eqipposition: string;
  anysplmreq: number;
  eqliftbeltreq: number;
  nameplatereq: number;
  spltestreq: number;
  senspd: number;
  printcolor: string;
  prv: number;
  descnt: number;
  lhook: number;
  wheel: number;
  slings: number;
  flprov: number;
  lidhandle: number;
  locks: number;
  latchlookasst: number;
  huin: number;
  foam: string;
  casetype: string;
  caseresult: string;
  rofoam: string;
  roprintcolor: string;
  cby: "1";
  revno: string;
  revpoints: string;
  note: string;
}

interface ReqItems {
  accessid: number;
  status: number
}

interface OutputData {
  ifinsert: boolean;
  drawid: string | number;
  cptdwgattachsc: Date;
  cptdwgattachhc: Date;
  casedwgattchsc: Date;
  casedwgattchhc: Date;
  foamdwgattachsc: Date;
  foamdwgattachhc: Date;
  scnprtdetattachsc: Date;
  scnprtdetattachhc: Date;
  billofmatattachsc: Date;
  billofmatattachhc: Date;
  stfdwgattachsc: Date;
  stfdwgattachhc: Date;
  prddevplnaatchsc: Date;
  prddevplnaatchhc: Date;
  dfmeaattachsc: Date;
  dfmeaattachhc: Date;
  cby: "1"
}

interface VerifyData {
  ifinsert: boolean;
  drawid: string | number;
  enqdetid: number;
  cieqwidth: string;
  cieqheight: string;
  cieqlen: string;
  fdwgbtmwidth: string;
  fdwgbtmheight: string;
  fdwgbtmlen: string;
  fdwgtopwidth: string;
  fdwgtopheight: string;
  fdwgtoplen: string;
  fcutbtmwidth: string;
  fcutbtmheight: string;
  fcutbtmlen: string;
  fcuttopwidth: string;
  fcuttopheight: string;
  fcuttoplen: string;
  cdexterwidth: string;
  cdexterheight: string;
  cdexterlen: string;
  cdinterwidth: string;
  cdinterheight: string;
  cdinterlen: string;
  handle: string;
  cby: "1"
  listrequirements: Requirements[];
}

interface Requirements {
  accessid: number;
  status: number
}
interface ValidData {
  id: number;
  detailstobechecked: string;
  chkwith: string;
}

interface ValidDetailsData {
  ifinsert: boolean;
  drawid: string | number;
  ecnid: number;
  drawvalid: number;
  detailscheck: string[];
  casedwg: number;
  bom: number;
  foamdwg: number;
  printingdwg: number;
  remarkslistdate: number;
  eqpwt: string;
  casewt: string;
  accwt: string;
  foamwt: string;
  stiffwt: string;
  finalwt: string;
}


type Props = {}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// const UpdatePage = (props: TabPanelProps) => {
const UpdatePage: React.FC = () => {

  const [tableData, setTableData] = useState<Data1[]>([]);
  const [value, setValue] = useState(0);
  const location = useLocation();
  const state = location.state as LocationState1 | undefined;
  const [data, setData] = useState<Data[]>([]);
  const [test, setTest] = useState<Test[]>([]);
  const [access, setAccess] = useState<Test[]>([]);
  const [activeItem, setActiveItem] = useState<string>("Input Form");
  const [model, setModel] = useState<Model[]>([]);
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
  const [radioValues, setRadioValues] = useState<{ [key: number]: string }>({});
  const [radioValues1, setRadioValues1] = useState<{ [key: number]: string }>({});
  const [selectedAccessIds, setSelectedAccessIds] = useState<Set<number>>(new Set());
  const [checkboxes, setCheckboxes] = useState({
    conceptDwgAttached: false,
    casedrawingattached: false,
    foamdrawingattached: false,
    screenprintingdetailsattached: false,
    billmaterialattached: false,
    stiffnerdrawingattached: false,
    proddevplanattached: false,
    dfmeaattached: false
  });

  const [formData, setFormData] = useState<FormData>({
    ifinsert: true,
    informid: 0,
    partno: "",
    drawid: state.id,
    ecnid: 0,
    refno: "",
    issueno: "",
    pagerevno: "",
    intype: "",
    casetype: "",
    casemodelid: 0,
    result: "",
    designby: "1",
    authby: "1",
    status: "Initiate",
    cby: "1",
    listessdata: [
      {
        essdataid: "",
        remarks: "",
        cby: "1",
        name: "",
        attach: "",
        compliance: ""
      }
    ],
    listtest: [
      {
        type: "test",
        testaccessid: "",
        name: "",
        bit: ""
      }
    ],
    listaccess: [
      {
        type: "Accessories",
        testaccessid: "",
        name: "",
        bit: ""
      }
    ],
    listitem: [
      {
        type: "item",
        testaccessid: "",
        name: "",
        bit: ""
      }
    ],
    listrequire: [
      {
        type: "require",
        testaccessid: "",
        name: "",
        bit: ""
      }
    ]
  })

  const [reviewData, setReviewData] = useState<ReviewData>({
    listreqitems: [
      {
        accessid: 0,
        status: 1
      }
    ],
    ifinsert: true,
    drawid: state.id,
    enqdetid: 0,
    eqipsize: "",
    eqipwt: "",
    refdrgthere: null,
    suplocspd: null,
    eqipposition: "",
    anysplmreq: null,
    eqliftbeltreq: null,
    nameplatereq: null,
    spltestreq: null,
    senspd: null,
    printcolor: "",
    prv: null,
    descnt: null,
    lhook: null,
    wheel: null,
    slings: null,
    flprov: null,
    lidhandle: null,
    locks: null,
    latchlookasst: null,
    huin: null,
    foam: "",
    casetype: "",
    caseresult: "",
    rofoam: "",
    roprintcolor: "",
    cby: "1",
    revno: "",
    revpoints: "",
    note: ""
  })

  const [outputData, setoutputData] = useState<OutputData>({
    ifinsert: true,
    drawid: state.id,
    cptdwgattachsc: new Date(),
    cptdwgattachhc: new Date(),
    casedwgattchsc: new Date(),
    casedwgattchhc: new Date(),
    foamdwgattachsc: new Date(),
    foamdwgattachhc: new Date(),
    scnprtdetattachsc: new Date(),
    scnprtdetattachhc: new Date(),
    billofmatattachsc: new Date(),
    billofmatattachhc: new Date(),
    stfdwgattachsc: new Date(),
    stfdwgattachhc: new Date(),
    prddevplnaatchsc: new Date(),
    prddevplnaatchhc: new Date(),
    dfmeaattachsc: new Date(),
    dfmeaattachhc: new Date(),
    cby: "1"
  })

  const [verifyData, setverifyData] = useState<VerifyData>({
    ifinsert: true,
    drawid: state.id,
    enqdetid: 0,
    cieqwidth: "",
    cieqheight: "",
    cieqlen: "",
    fdwgbtmwidth: "",
    fdwgbtmheight: "",
    fdwgbtmlen: "",
    fdwgtopwidth: "",
    fdwgtopheight: "",
    fdwgtoplen: "",
    fcutbtmwidth: "",
    fcutbtmheight: "",
    fcutbtmlen: "",
    fcuttopwidth: "",
    fcuttopheight: "",
    fcuttoplen: "",
    cdexterwidth: "",
    cdexterheight: "",
    cdexterlen: "",
    cdinterwidth: "",
    cdinterheight: "",
    cdinterlen: "",
    handle: "",
    cby: "1",
    listrequirements: [
      {
        accessid: 0,
        status: 0
      }
    ]
  })

  const [validData, setValidData] = useState<ValidData[]>([
    {
      id: 0,
      detailstobechecked: "",
      chkwith: "",
    },
    // Add more objects as needed
  ]);

  const [validDetailsData, setvalidDetailsData] = useState<ValidDetailsData[]>([
    {
      ifinsert: true,
      drawid: state.id,
      ecnid: 0,
      drawvalid: 0,
      detailscheck: [],
      casedwg: 0,
      bom: 0,
      foamdwg: 0,
      printingdwg: 0,
      remarkslistdate: 0,
      eqpwt: "",
      casewt: "",
      accwt: "",
      foamwt: "",
      stiffwt: "",
      finalwt: "",
    },
  ]);

  const handleChange1 = (event: any) => {
    const { name, value } = event.target;
  }


  useEffect(() => {
    if (state?.id) {
      apiService.getEssData(Number(state.id))
        .then(response => {
          //setData(response.data);
          console.log(response.data, "response.data12");

          setFormData(prevState => ({
            ...prevState,
            listessdata: response.data
          }));
          console.log(formData, "formData12");
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });

      apiService.getDropdown("dsn_mas_testaccess")
        .then(response => {
          console.log(response, "response")
          // for test
          const filteredData = response.data.filter((item: Test) => item.type === 'test');
          setFormData(prevState => ({
            ...prevState,
            listtest: filteredData
          }));

          // for accessories
          const filteredData1 = response.data.filter((item: Test) => item.type === 'Accessories');
          console.log(filteredData1);

          setFormData(prevState => ({
            ...prevState,
            listaccess: filteredData1
          }));
          console.log(filteredData1, "listaccess");

          //for items
          const filteredData2 = response.data.filter((item: Test) => item.type === 'item');
          console.log(filteredData2);

          setFormData(prevState => ({
            ...prevState,
            listitem: filteredData2
          }));

          const filteredData3 = response.data.filter((item: Test) => item.type === 'require');
          console.log(filteredData3);

          setFormData(prevState => ({
            ...prevState,
            listrequire: filteredData3
          }));

        })
        .catch(error => {
          console.error("Error fetching dropdown data:", error);
        });

      apiService.getDropdown("dsn_mas_model").then(response => {
        setModel(response.data);
      }).catch((err) => {
        console.log("err", err)
      });
    }

    apiService.getDropdown("dsn_draw_validation").then(response => {
      setValidData(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getInputForm(state.id).then(response => {
      console.log("API Response:", response.data);
      const api = response.data
      setFormData(api as FormData);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getEnq_Date(Number(state.id))
      .then(response => {
        console.log("Fetched list data:", response.data);
        setTableData(response.data);
      })
      .catch(err => {
        console.log("Error fetching grid data:", err);
      });

    apiService.getReviewForm(state.id).then(response => {
      console.log("API Response:", response.data);
      const api = response.data
      setReviewData(api as ReviewData);
    }).catch((err) => {
      console.log("err", err)
    });

  }, [state?.id]);
  console.log('api', formData);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRemarksChange = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    // Create a deep copy of the listessdata array
    const newTableRows = [...formData?.listessdata];

    // Update the specific row's remarks and essdataid
    newTableRows[index] = {
      ...newTableRows[index],
      [name]: value,
      essdataid: rowid.toString(),
    };

    // Update the state with the new list
    setFormData({ ...formData, listessdata: newTableRows });

    console.log(formData, "formData.listessdata");
  };


  const handleReqItemsChange = (testaccessid: any, index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    // Ensure testaccessid is properly set and not an empty string
    const validAccessId = testaccessid || 0; // Default to 0 if testaccessid is falsy

    setFormData(prevData => {
      const updatedList = [...prevData.listitem];
      updatedList[index] = {
        ...updatedList[index],
        testaccessid: isChecked ? validAccessId : null // Store validAccessId if checked, otherwise set to null
      };
      return { ...prevData, listitem: updatedList };
    });
  };

  const handleBitChange = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Update local state for the specific row's radio button selection
    setRadioValues(prevValues => ({
      ...prevValues,
      [rowid]: value,  // Ensure this update only applies to the current row
    }));

    // Update the global formData state
    const newTableRows = [...formData?.listtest];
    newTableRows[index].bit = value;  // Update the corresponding bit value as string "1" or "0"
    newTableRows[index]["testaccessid"] = rowid.toString();
    setFormData({ ...formData, listtest: newTableRows });
    console.log(newTableRows, "Updated listtest");
  };

  const handleBitChange1 = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Update local state for the specific row's radio button selection
    setRadioValues1(prevValues => ({
      ...prevValues,
      [rowid]: value,  // Ensure this update only applies to the current row
    }));

    // Update the global formData state
    const newTableRows = [...formData?.listaccess];
    newTableRows[index].bit = value;  // Update the corresponding bit value as string "1" or "0"
    newTableRows[index]["testaccessid"] = rowid.toString();
    setFormData({ ...formData, listaccess: newTableRows });
    console.log(newTableRows, "Updated listaccess");
  };


  const handleInputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleReviewChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setReviewData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleCasemodelIdChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const selectedValue = parseFloat(event.target.value); // Convert string to number

    setFormData((prevFormData) => ({
      ...prevFormData,
      casemodelid: selectedValue, // Update the number value
    }));
  };


  const handleReviewChanges1 = (
    e: React.ChangeEvent<HTMLInputElement>,
    testaccessid: string,
    index: number
  ) => {
    const { checked } = e.target;
    const { name, value } = e.target;
  
    console.log(e.target, testaccessid);  // Log target and testaccessid for debugging
    console.log(index, "index");          // Log index to make sure it's valid
  
    // Ensure reviewData and listreqitems are initialized correctly
    const newEssentialTableRows = { ...reviewData };
  
    // Initialize listreqitems as an empty array if not already done
    if (!newEssentialTableRows.listreqitems) {
      newEssentialTableRows.listreqitems = [];
    }
  
    // Validate if index is in range
    if (index < 0 || index >= newEssentialTableRows.listreqitems.length) {
      console.error("Invalid index:", index);
      return; // Stop execution if the index is out of bounds
    }
  
    const res = {
      accessid: testaccessid,
      status: 1
    };
  
    // Update or create new entry for the specific index in listreqitems
    if (!newEssentialTableRows.listreqitems[index]) {
      newEssentialTableRows.listreqitems[index] = {
        accessid: Number(res.accessid),  // Convert to number
        status: res.status
        
      };
    } else {
      // Update the existing item
      newEssentialTableRows.listreqitems[index][name] = value;
    }
  
    // Update state with the modified data
    setReviewData(newEssentialTableRows);
    console.log("Updated reviewData",newEssentialTableRows);
  
    // Update the checked state of the checkbox
    setCheckedItems((prevState) => ({
      ...prevState,
      [testaccessid]: checked,
    }));
  };
  


  const handleValidationChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number
  ) => {
    const { name, value, checked, type } = e.target;

    // Create a copy of the validDetailsData array
    const updatedValidDetailsData = [...validDetailsData];

    // Prepare the data to update with all required fields
    let res: ValidDetailsData = {
      ifinsert: true,
      drawid: state.id,
      ecnid: 0,
      drawvalid: id,  // This line ensures drawvalid is set correctly
      detailscheck: [],
      casedwg: 0,
      bom: 0,
      foamdwg: 0,
      printingdwg: 0,
      remarkslistdate: 0,
      eqpwt: "",
      casewt: "",
      accwt: "",
      foamwt: "",
      stiffwt: "",
      finalwt: "",
    };

    // If the row at the given index doesn't exist, create it with default values
    if (!updatedValidDetailsData[index]) {
      updatedValidDetailsData[index] = res;
    }

    // Update the row data based on the input type
    const currentRow = updatedValidDetailsData[index];

    if (type === 'text') {
      // Update text input fields
      updatedValidDetailsData[index] = {
        ...currentRow,
        [name]: value,
      };
    } else if (type === 'checkbox') {
      if (name === 'detailscheck') {
        // Manage detailscheck array
        let updatedDetailscheck = [...(currentRow.detailscheck || [])];
        if (checked) {
          if (!updatedDetailscheck.includes(value)) {
            updatedDetailscheck.push(value);
          }
        } else {
          updatedDetailscheck = updatedDetailscheck.filter(item => item !== value);
        }
        updatedValidDetailsData[index] = {
          ...currentRow,
          detailscheck: updatedDetailscheck,
        };
      } else {
        // Manage other checkbox fields (e.g., casedwg, bom, etc.)
        updatedValidDetailsData[index] = {
          ...currentRow,
          [name]: checked ? 1 : 0,
        };
      }
    }

    // Ensure drawvalid is not overwritten incorrectly
    updatedValidDetailsData[index] = {
      ...updatedValidDetailsData[index],
      drawvalid: id, // Explicitly set drawvalid
    };

    // Update the state with the modified array
    setvalidDetailsData(updatedValidDetailsData);

    console.log(updatedValidDetailsData, "validDetailsData");
  };


  const handleStatusChanges = (e: React.ChangeEvent<HTMLInputElement>, testaccessid: number, index: number) => {
    const { name, value } = e.target;
    console.log(e.target, testaccessid);

    // Create a shallow copy of the current state
    const newEssentialTableRows = { ...verifyData };

    // Ensure the listrequirements array exists
    if (!newEssentialTableRows.listrequirements) {
      newEssentialTableRows.listrequirements = [];
    }

    // Create or update the current row's data
    const updatedRow = {
      ...newEssentialTableRows.listrequirements[index], // Preserve existing fields
      accessid: testaccessid, // Ensure accessid is set
      [name]: value, // Update the status or other fields
    };

    // Replace the row in the array
    newEssentialTableRows.listrequirements[index] = updatedRow;

    // Update the state with the new data
    setverifyData(newEssentialTableRows);
    console.log(newEssentialTableRows, "verifyData");
  };


  const handleOutputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setoutputData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleVerifyChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setverifyData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDropdownChange = (e: SelectChangeEvent<unknown>, index: number) => {
    const { name, value } = e.target;

    setverifyData((prevState: any) => ({
      ...prevState,
      [name]: value,  // Update the specific field in verifyData
    }));
  };

  const [isPrintable, setIsPrintable] = useState(false);
  const [showImage, setShowImage] = useState(false)
  const [rows, setRows] = useState([{}]); // Initialize with one empty row
  const [selectedItem, setSelectedItem] = useState<FormData>(null);
  const [selectedItem1, setSelectedItem1] = useState<ReviewData>(null);
  const [selectedItem2, setSelectedItem2] = useState<VerifyData>(null);


  const addRow = () => {
    setRows([...rows, {}]); // Add a new empty row
  };

  const handleClose = () => {
    setFormData(prevState => {
      // Clear remarks field in listessdata
      const newListessdata = prevState.listessdata.map(item => ({
        ...item,
        remarks: ""
      }));

      // Clear only testaccessid and bit in listtest
      const newListtest = prevState.listtest.map(item => ({
        ...item,
        testaccessid: "",
        bit: ""
      }));

      // Clear only testaccessid and bit in listaccess
      const newListaccess = prevState.listaccess.map(item => ({
        ...item,
        testaccessid: "",
        bit: ""
      }));

      return {
        ...prevState,
        ifinsert: true,
        informid: 0,
        partno: "",
        drawid: state.id,
        ecnid: 0,
        refno: "",
        issueno: "",
        pagerevno: "",
        intype: "",
        casetype: "",
        casemodelid: 0,
        result: "",
        designby: "1",
        authby: "1",
        status: "Initiate",
        cby: "1",
        listessdata: newListessdata,  // Updated listessdata with cleared remarks
        listtest: newListtest,  // Updated listtest with cleared radio buttons
        listaccess: newListaccess  // Updated listaccess with cleared radio buttons
      };
    });
    setSelectedItem(null);
  };

  // const handleClose1 = () => {
  //   setReviewData(prevState => ({
  //     ...prevState,
  //     listreqitems: [],
  //     ifinsert: true,
  //     drawid: state.id,
  //     enqdetid: 0,
  //     eqipsize: "",
  //     eqipwt: "",
  //     refdrgthere: "",
  //     suplocspd: "",
  //     eqipposition: "",
  //     anysplmreq: "",
  //     eqliftbeltreq: "",
  //     nameplatereq: "",
  //     spltestreq: "",
  //     senspd: "",
  //     printcolor: "",
  //     prv: "",
  //     descnt: "",
  //     lhook: "",
  //     wheel: "",
  //     slings: "",
  //     flprov: "",
  //     lidhandle: "",
  //     locks: "",
  //     latchlookasst: "",
  //     huin: "",
  //     foam: "",
  //     casetype: "",
  //     caseresult: "",
  //     rofoam: "",
  //     roprintcolor: "",
  //     cby: "1",
  //     revno: "",
  //     revpoints: "",
  //     note: ""
  //   }));
  //   setCheckedItems({});
  // };

  const handleClose2 = () => {
    setoutputData({
      ifinsert: true,
      drawid: state?.id || "",
      cptdwgattachsc: new Date(0),  // Setting to a default Date value
      cptdwgattachhc: new Date(0),
      casedwgattchsc: new Date(0),
      casedwgattchhc: new Date(0),
      foamdwgattachsc: new Date(0),
      foamdwgattachhc: new Date(0),
      scnprtdetattachsc: new Date(0),
      scnprtdetattachhc: new Date(0),
      billofmatattachsc: new Date(0),
      billofmatattachhc: new Date(0),
      stfdwgattachsc: new Date(0),
      stfdwgattachhc: new Date(0),
      prddevplnaatchsc: new Date(0),
      prddevplnaatchhc: new Date(0),
      dfmeaattachsc: new Date(0),
      dfmeaattachhc: new Date(0),
      cby: "1"
    });
    setCheckboxes({
      conceptDwgAttached: false,
      casedrawingattached: false,
      foamdrawingattached: false,
      screenprintingdetailsattached: false,
      billmaterialattached: false,
      stiffnerdrawingattached: false,
      proddevplanattached: false,
      dfmeaattached: false
    });
  };


  const handleClose3 = () => {
    setverifyData(prevState => {
      console.log('Previous State:', prevState);

      const newListrequire = prevState.listrequirements.map(item => ({
        ...item,
        accessid: 0,  // Clear the accessid field
        status: 0     // Clear the status field
      }));

      console.log('New List Requirements:', newListrequire);

      return {
        ...prevState,
        ifinsert: true,
        drawid: state.id,
        enqdetid: 0,
        cieqwidth: "",
        cieqheight: "",
        cieqlen: "",
        fdwgbtmwidth: "",
        fdwgbtmheight: "",
        fdwgbtmlen: "",
        fdwgtopwidth: "",
        fdwgtopheight: "",
        fdwgtoplen: "",
        fcutbtmwidth: "",
        fcutbtmheight: "",
        fcutbtmlen: "",
        fcuttopwidth: "",
        fcuttopheight: "",
        fcuttoplen: "",
        cdexterwidth: "",
        cdexterheight: "",
        cdexterlen: "",
        cdinterwidth: "",
        cdinterheight: "",
        cdinterlen: "",
        handle: "",  // Clear the handle field
        cby: "1",
        listrequirements: newListrequire // Set the updated listrequirements
      };
    });

    setSelectedItem2(null);
  };

  const [isSaved, setIsSaved] = useState(false);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  //FOR INPUT FORM SUBMIT
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Save button clicked'); // Log to verify button click
    try {
      const dataToSubmit = formData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postdesigndata(dataToSubmit);

      // Log and display success message
      console.log('Form submitted successfully:', response.data);
      toast.success("Input Form saved successfully!");

      // Set the form as saved and scroll to top
      setIsSaved(true);
      window.scrollTo(0, 0);

    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };



  const printRef = useRef(null);
  const contentToPrint: any = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => setShowImage(true),
    onBeforePrint: () => setShowImage(true),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  //FOR REVIEW SUBMIT
  const handleSubmitReview = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = reviewData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postreviewdata(dataToSubmit);
      // Handle response
      console.log('Form submitted successfully:', response.data);
      successToast(response.data);
      // console.log('setReviewData',value);
      setIsSaved(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  //FOR OUTPUT FORM SUBMIT
  const handleSubmitOutput = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = outputData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postoutputdata(dataToSubmit);
      // Handle response
      console.log('Form submitted successfully:', response.data);
      successToast(response.data);
      // console.log('setReviewData',value);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  //FOR VERIFICATION FORM SUBMIT
  const handleSubmitVerification = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = verifyData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postverifydata(dataToSubmit);
      // Handle response
      console.log('Form submitted successfully:', response.data);
      successToast(response.data);
      // console.log('setReviewData',value);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  //FOR DRAWING VALIDATION FORM SUBMIT
  const handleSubmitValidation = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = validDetailsData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postdrawvaliddata(dataToSubmit);
      // Handle response
      console.log('Form submitted successfully:', response.data);
      successToast(response.data);
      // console.log('setReviewData',value);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };


  return (
    <>
      <Helmet>
        <title>Design & Development - Design</title>
      </Helmet>
      <br></br>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <CardHeader title="Design and Development" />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px', color: 'white' }}>
                  <Tab
                    label="Back"
                    onClick={() => navigate('/design/designdev')}
                    sx={{ backgroundColor: 'blue', "&:hover": { color: 'white' } }}
                  />
                </div>
              </div>
              <Divider />
              <CardContent>
                <Box sx={{ width: '100%' }}>
                  {!isSaved && (
                    <div className="tabs-container no-print">
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="primary"
                        indicatorColor="primary"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Input Form" sx={{ "&:hover": { color: "white" } }} />
                        {/* <Tab label="Planning" sx={{ "&:hover": { color: "white" } }}/> */}
                        <Tab label="Review" sx={{ "&:hover": { color: "white" } }} />
                        <Tab label="Output" sx={{ "&:hover": { color: "white" } }} />
                        <Tab label="Verification" sx={{ "&:hover": { color: "white" } }} />
                        <Tab label="Drawing Validation" sx={{ "&:hover": { color: "white" } }} />
                      </Tabs>
                    </div>
                  )}
                  <TabPanel value={value} index={0}>
                    <div className="main-content" >
                      {!isSaved ? (
                        <div className="display-area">
                          <div className="panel">
                            <Typography variant="h6" gutterBottom>
                              <b><u>EQUIPMENT DETAILS</u></b>
                            </Typography>
                            <Table className="compact-table">
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ textAlign: 'center' }} ><b>SL. NO</b></TableCell>
                                  <TableCell style={{ textAlign: 'center' }}><b>DESIGN DATA DETAILS</b></TableCell>
                                  <TableCell style={{ textAlign: 'center' }}><b>GIVEN DETAILS & FILES</b></TableCell>
                                  <TableCell style={{ textAlign: 'center' }}><b>REMARKS</b></TableCell>
                                </TableRow>
                                {formData?.listessdata?.map((row: any, index: number) => (
                                  <TableRow key={row.essdataid}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>   <div>
                                      {/* Always display the compliance value */}
                                      <div>{row.compliance}</div>
                                      {/* Conditionally display the attach link only if attach is present */}
                                      {row.attach && (
                                        <a
                                          href={`https://docs.google.com/viewer?url=${encodeURIComponent(row.attach)}&embedded=true`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            color: hoverIndex === index ? '#0056b3' : '#007bff', // Change color on hover for specific row
                                            textDecoration: hoverIndex === index ? 'underline' : 'none', // Add underline on hover for specific row
                                          }}
                                          onMouseEnter={() => setHoverIndex(index)} // Set hover for this specific row
                                          onMouseLeave={() => setHoverIndex(null)}  // Reset hover when mouse leaves
                                        >
                                          View
                                        </a>
                                      )}
                                    </div></TableCell>
                                    <TableCell>
                                      <TextField
                                        required
                                        name="remarks"
                                        value={row.remarks}
                                        onChange={(e) => handleRemarksChange(row.essdataid, index, e)}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>

                          <div className="display-area">
                            <div className="panel">
                              <Typography variant="h6" gutterBottom>
                                <b><u>TEST DETAILS (AS PER MIL-STD-810G/JSS-0253-01/JSG 0102 & AS PER SAARC QAP)</u></b>
                              </Typography>

                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Table className="table-body">
                                    <TableBody>
                                      <TableRow className="table-header">
                                        <TableCell><b>SL. NO</b></TableCell>
                                        <TableCell><b>NAME OF THE TEST</b></TableCell>
                                        <TableCell><b>Y/N</b></TableCell>
                                      </TableRow>
                                      {formData?.listtest?.map((row: any, index: number) => (
                                        <TableRow key={row.testaccessid}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>{row.name}</TableCell>
                                          <TableCell>
                                            <FormControl component="fieldset">
                                              <RadioGroup
                                                value={radioValues[row.id] ?? (row.bit === 'True' || row.bit === true ? "1" : row.bit === 'False' || row.bit === false ? "0" : "")}
                                                onChange={(e) => {
                                                  handleBitChange(row.id, index, e);
                                                }}
                                                row
                                                className="compact-radio-group"
                                              >
                                                <FormControlLabel value="1" control={<Radio size="small" />} label="Yes" />
                                                <FormControlLabel value="0" control={<Radio size="small" />} label="No" />
                                              </RadioGroup>
                                            </FormControl>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Grid>

                                <Grid item xs={6}>
                                  <Table className="table-body">
                                    <TableBody>
                                      <TableRow className="table-header">
                                        <TableCell><b>SL. NO</b></TableCell>
                                        <TableCell><b>NAME OF THE ACCESSORIES</b></TableCell>
                                        <TableCell><b>APPLICABLE Y/N</b></TableCell>
                                      </TableRow>
                                      {formData?.listaccess?.map((row: any, index: number) => (
                                        <TableRow key={row.testaccessid}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>{row.name}</TableCell>
                                          <TableCell>
                                            <FormControl component="fieldset">
                                              <RadioGroup
                                                value={radioValues1[row.id] ?? (row.bit === 'True' || row.bit === true ? "1" : row.bit === 'False' || row.bit === false ? "0" : "")}
                                                onChange={(e) => handleBitChange1(row.id, index, e)}
                                                row
                                                className="compact-radio-group"
                                              >
                                                <FormControlLabel name="bit" value="1" control={<Radio size="small" />} label="Yes" />
                                                <FormControlLabel name="bit" value="0" control={<Radio size="small" />} label="No" />
                                              </RadioGroup>
                                            </FormControl>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Grid>
                              </Grid>
                            </div>
                          </div>

                          <Grid>
                            <div className="display-area">
                              <div className="panel">
                                <Typography variant="h6" gutterBottom>
                                  <b><u>CASE SELECTION</u></b>
                                </Typography>
                                <Grid item>
                                  <Table className="casetable">
                                    <TableBody>
                                      <TableRow className="table-header">
                                        <TableCell><b>PART NO</b></TableCell>
                                        <TableCell><b>TYPE</b></TableCell>
                                        <TableCell><b>MODEL</b></TableCell>
                                        <TableCell><b>RESULT</b></TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <TextField
                                            required
                                            name="partno"
                                            value={formData?.partno}
                                            onChange={handleInputChanges}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            required
                                            select
                                            id="casetype"
                                            name="casetype"
                                            fullWidth
                                            value={formData?.casetype}
                                            onChange={handleInputChanges}
                                          >
                                            <MenuItem value="Existing Case">Existing Case</MenuItem>
                                            <MenuItem value="NPD Case">NPD Case</MenuItem>
                                          </TextField>
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            required
                                            select
                                            id="casemodelid"
                                            name="casemodelid"
                                            fullWidth
                                            value={formData?.casemodelid || ''}
                                            onChange={handleCasemodelIdChange}
                                          >
                                            {model.map((option) => (
                                              <MenuItem key={option.id} value={option.id}>
                                                {option.casemodel}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            required
                                            select
                                            id="result"
                                            name="result"
                                            fullWidth
                                            value={formData?.result}
                                            onChange={handleInputChanges}
                                          >

                                            <MenuItem value="Can be used">Can be used</MenuItem>
                                            <MenuItem value="Can Be Fusioned">Can Be Fusioned</MenuItem>
                                            <MenuItem value="Top NPD">Top NPD</MenuItem>
                                            <MenuItem value="Bottom NPD">Bottom NPD</MenuItem>
                                            <MenuItem value="To Be Made">To Be Made</MenuItem>
                                          </TextField>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Grid>
                              </div>
                            </div>
                          </Grid>

                          <DialogActions>
                            <Button onClick={handleClose} color="primary" className="btn-print">
                              Clear
                            </Button>
                            <Button onClick={handleSubmit} variant='contained' color="primary">
                              Save
                            </Button>
                          </DialogActions>
                        </div>
                      ) : (

                        <div className="display-area" id="printable-content" style={{ marginTop: '-40px' }} ref={contentToPrint}>
                          <div className="display-area1">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <img
                                src={require('../assets/logosaarc.png')}
                                alt="Logo"
                                style={{ width: '60px', height: '60%', marginTop: '15px', marginBottom: '30px' }}
                              />
                              <div style={{ flex: 1, textAlign: 'center' }}>
                                <Typography gutterBottom style={{ marginBottom: '20px' }}>
                                  <h2><b>INPUT FORM</b></h2>
                                </Typography>
                              </div>
                            </div>
                            {tableData.map((item:any, index) => (
                              <div key={item.id} style={{ marginBottom: "20px" }}>
                                <div><strong>Enquiry No:</strong> {item.enqno}</div>
                                <div><strong>Enquiry Date:</strong> {item?.enqdate}</div>
                                <div><strong>Customer Name:</strong> {item.cusname}</div>
                              </div>
                            ))}
                            <div>
                              <Typography variant="h6" gutterBottom>
                                <b><u>EQUIPMENT DETAILS</u></b>
                              </Typography>
                              <Table className="table-body">
                                <TableBody>
                                  <TableRow>
                                    <TableCell><b>SL. NO</b></TableCell>
                                    <TableCell><b>DESIGN DATA DETAILS</b></TableCell>
                                    <TableCell><b>GIVEN DETAILS & FILES</b></TableCell>
                                    <TableCell><b>REMARKS</b></TableCell>
                                  </TableRow>
                                  {formData?.listessdata?.map((row, index) => (
                                    <TableRow key={row.essdataid}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>{row.name}</TableCell>
                                      <TableCell>{row.compliance}</TableCell>
                                      <TableCell>{row.remarks}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                            <div>
                              <br></br>
                              <Typography variant="h6" gutterBottom>
                                <b><u>TEST DETAILS (AS PER MIL-STD-810G/JSS-0253-01/JSG 0102 & AS PER SAARC QAP)</u></b>
                              </Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Table className="table-body">
                                    <TableBody>
                                      <TableRow className="table-header">
                                        <TableCell><b>SL. NO</b></TableCell>
                                        <TableCell><b>NAME OF THE TEST</b></TableCell>
                                        <TableCell><b>Y/N</b></TableCell>
                                      </TableRow>
                                      {formData?.listtest?.map((row, index) => (
                                        <TableRow key={row.testaccessid}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>{row.name}</TableCell>
                                          <TableCell>{row.bit === '1' ? 'Yes' : 'No'}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Grid>
                                <Grid item xs={6}>
                                  <Table className="table-body">
                                    <TableBody>
                                      <TableRow className="table-header">
                                        <TableCell><b>SL. NO</b></TableCell>
                                        <TableCell><b>NAME OF THE ACCESSORIES</b></TableCell>
                                        <TableCell><b>APPLICABLE Y/N</b></TableCell>
                                      </TableRow>
                                      {formData?.listaccess?.map((row, index) => (
                                        <TableRow key={row.testaccessid}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>{row.name}</TableCell>
                                          <TableCell>{row.bit === '1' ? 'Yes' : 'No'}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Grid>
                              </Grid>
                            </div>
                            <br></br>
                            <div>
                              <Typography variant="h6" gutterBottom>
                                <b><u>CASE SELECTION</u></b>
                              </Typography>
                              <Table className="table-body">
                                <TableBody>
                                  <TableRow>
                                    <TableCell><b>PART NO</b></TableCell>
                                    <TableCell><b>TYPE</b></TableCell>
                                    <TableCell><b>MODEL</b></TableCell>
                                    <TableCell><b>RESULT</b></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>{formData?.partno}</TableCell>
                                    <TableCell>{formData?.casetype}</TableCell>
                                    <TableCell>{model.find(m => m.id === formData?.casemodelid)?.casemodel}</TableCell>
                                    <TableCell>{formData?.result}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </div>
                          </div>
                          <Button onClick={() => {
                            handlePrint(null, () => contentToPrint.current);
                          }} style={{ marginTop: '10px', marginLeft: '10px' }} className="no-print"
                            variant="contained"
                            color="primary"
                            startIcon={<FontAwesomeIcon icon={faPrint} />}>
                            Print
                          </Button>
                        </div>
                      )}
                      <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                    </div>

                  </TabPanel>

                  {/* // FOR PLANNING 
                  <TabPanel value={value} index={1}>
                  </TabPanel> */}

                  {/* FOR REVIEW */}
                  <TabPanel value={value} index={1}>
                    <div>
                      {!isSaved ? (
                        <div>
                          <div className="panel">
                            <div className="main-heading">INPUT DETAILS</div>
                            <div className="content-grid">
                              <div className="column">
                                <div>
                                  <TextField
                                    margin="dense"
                                    id="revno"
                                    label="I/P NO & DATE
                            ENQ NO"
                                    name="revno"
                                    value={reviewData?.revno}
                                    fullWidth
                                    onChange={handleReviewChanges}
                                  />
                                  <TextField
                                    margin="dense"
                                    id="eqipsize"
                                    label="EQUIPMENT SIZE"
                                    name='eqipsize'
                                    value={reviewData?.eqipsize}
                                    fullWidth
                                    onChange={handleReviewChanges}
                                  />
                                  <TextField

                                    margin="dense"
                                    id="eqipwt"
                                    label="EQUIPMENT WEIGHT"
                                    name='eqipwt'
                                    value={reviewData?.eqipwt}
                                    fullWidth
                                    onChange={handleReviewChanges}
                                  />
                                </div>
                                <br></br>
                                <div>

                                  <FormControl component="fieldset" className="radioWithMargin">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>REFER DRG THERE</FormLabel>
                                      </Grid>
                                      <Grid item>
                                        <RadioGroup
                                          value={reviewData?.refdrgthere}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="refdrgthere" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="refdrgthere" value="0" control={<Radio />} label="No" />

                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset" >
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item  >
                                        <FormLabel component="legend" sx={{ mr: 15 }}>SUPPORT LOCATION SPECIFIED</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup
                                          value={reviewData?.suplocspd}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="suplocspd" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="suplocspd" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>EQUIPMENT POSITION</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup
                                          value={reviewData?.eqipposition}
                                          onChange={handleReviewChanges}
                                          row>
                                          <FormControlLabel name="eqipposition" value="H" control={<Radio />} label="H" />
                                          <FormControlLabel name="eqipposition" value="V" control={<Radio />} label="V" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 10 }}>ANY SPL MARKING REQUIRED?</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup
                                          value={reviewData?.anysplmreq}
                                          onChange={handleReviewChanges}
                                          row>
                                          <FormControlLabel name="anysplmreq" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="anysplmreq" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 5 }}>EQPT LIFTING BELT REQUIRED?</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.eqliftbeltreq}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="eqliftbeltreq" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="eqliftbeltreq" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>NAME PLATE REQUIRED?</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.nameplatereq}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="nameplatereq" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="nameplatereq" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>SPECIAL TEST REQUIRED?</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.spltestreq}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="spltestreq" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="spltestreq" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 19 }}>SENSITIVITY SPECIFIED?</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.senspd}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="senspd" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="senspd" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" >PRINTING COLOUR</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.printcolor}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="printcolor" value="Yellow" control={<Radio />} label="Yellow" />
                                          <FormControlLabel name="printcolor" value="White" control={<Radio />} label="White" />
                                          <FormControlLabel name="printcolor" value="Black" control={<Radio />} label="Black" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                </div>
                              </div>
                              <div className="column">
                                <div>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>PRV</FormLabel>
                                      </Grid>
                                      <Grid item  >
                                        <RadioGroup value={reviewData?.prv}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="prv" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="prv" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>


                                  <FormControl component="fieldset" >
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>DESCNT</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.descnt}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="descnt" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="descnt" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>LHOOK</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.lhook}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="lhook" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="lhook" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>WHEEL</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.wheel}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="wheel" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="wheel" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>SLINGS</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.slings}
                                          onChange={handleReviewChanges}
                                          row>
                                          <FormControlLabel name="slings" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="slings" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 20 }}>FL PROVISION</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.flprov}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="flprov" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="flprov" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 20 }}>LID HANDLE</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.lidhandle}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="lidhandle" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="lidhandle" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 66 }}>LOCK</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.locks}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="locks" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="locks" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 29 }}>HU. IN</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.huin}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="huin" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="huin" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 20 }}>FOAM</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.foam}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="foam" value="EPE" control={<Radio />} label="EPE" />
                                          <FormControlLabel name="foam" value="XLPE" control={<Radio />} label="XLPE" />
                                          <FormControlLabel name="foam" value="PU" control={<Radio />} label="PU" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="panel">
                            <div className="content-grid">
                              <div className="table-container">
                                <div className="table">
                                  <div className="main-heading">REVIEW OUTPUTS</div>

                                  <TextField
                                    required
                                    select
                                    id="casetype"
                                    name="casetype"
                                    fullWidth
                                    value={reviewData?.casetype}
                                    onChange={handleReviewChanges}
                                    style={{ marginBottom: '16px' }} // Adds space below the first TextField
                                  >
                                    <MenuItem value="Existing Case">Existing Case</MenuItem>
                                    <MenuItem value="NPD Case">NPD Case</MenuItem>
                                  </TextField>

                                  <TextField
                                    required
                                    select
                                    id="result"
                                    name="caseresult"
                                    fullWidth
                                    value={reviewData?.caseresult}
                                    onChange={handleReviewChanges}
                                    style={{ marginBottom: '16px' }}
                                  >
                                    <MenuItem value="Can Be Used">Can Be Used</MenuItem>
                                    <MenuItem value="Can Be Fusioned">Can Be Fusioned</MenuItem>
                                    <MenuItem value="Top NPD">Top NPD</MenuItem>
                                    <MenuItem value="Bottom NPD">Bottom NPD</MenuItem>
                                    <MenuItem value="To Be Made">To Be Made</MenuItem>
                                  </TextField>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>FOAM</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.rofoam}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="rofoam" value="EPE" control={<Radio />} label="EPE" />
                                          <FormControlLabel name="rofoam" value="XLPE" control={<Radio />} label="XLPE" />
                                          <FormControlLabel name="rofoam" value="PU" control={<Radio />} label="PU" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>

                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend">PRINTING COLOUR</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.roprintcolor}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="roprintcolor" value="Yellow" control={<Radio />} label="Yellow" />
                                          <FormControlLabel name="roprintcolor" value="White" control={<Radio />} label="White" />
                                          <FormControlLabel name="roprintcolor" value="Black" control={<Radio />} label="Black" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                </div>

                                <div className="table">
                                  <div className="main-heading">REQUIRED ITEMS</div>
                                  <div className="items-container">
                                    {formData?.listitem?.map((row: any, index: number) => (
                                      <div className="item" key={row.testaccessid}>
                                        <input type="checkbox"
                                          id={`checkbox-${row.testaccessid}`} // Ensure testaccessid is used here
                                          value={row.testaccessid}
                                          name="accessid"
                                          onChange={(e) => handleReviewChanges1(e, row.testaccessid, index)} />
                                        <label htmlFor={`checkbox-${row.id}`}>{row.name}</label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="panel">
                            <div className="table-container">
                              <div className="table">
                                <div >
                                  <label htmlFor="revpoints">REVIEW POINTS</label>
                                  <textarea name="revpoints" onChange={handleReviewChanges} value={reviewData?.revpoints} rows="1"></textarea>
                                </div>
                                <div className="note-section">
                                  <label htmlFor="note">NOTE</label>
                                  <textarea name="note" value={reviewData?.note} onChange={handleReviewChanges} rows="4"></textarea>
                                </div>
                                <br></br>
                                <label className='review'>REVIEW HISTORY</label>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Rev. No</TableCell>
                                      <TableCell>Rev. Date</TableCell>
                                      <TableCell>Review Points</TableCell>
                                      <TableCell>ECN No</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map((row, index) => (
                                      <TableRow key={index}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <button type="button" onClick={addRow}>Add Row</button>
                              </div>

                            </div>
                            <DialogActions>
                              {/* <Button onClick={handleClose1} color="primary" className="btn-print" >
                          Clear
                        </Button> */}
                              <Button onClick={handleSubmitReview} variant='contained' color="primary" >
                                Save
                              </Button>
                            </DialogActions>
                          </div>
                        </div>
                      ) : (
                        <div >
                          <div ref={contentToPrint} className="reviewprint">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <img
                                src={require('../assets/logosaarc.png')}
                                alt="Logo"
                                style={{ width: '60px', height: '60%', marginTop: '-15px', marginBottom: '30px' }}
                              />
                              <div style={{ flex: 1, textAlign: 'center' }}>
                                <Typography gutterBottom style={{ marginBottom: '20px', marginTop: '-45px' }}>
                                  <h2><b>REVIEW FORM</b></h2>
                                </Typography>
                              </div>
                            </div>

                            <div className="one-by-one">
                              <div>
                                <label><strong>I/P NO & DATE ENQ NO:</strong></label>
                                <p>{reviewData.revno}</p>
                              </div>
                              <div>
                                <label><strong>EQUIPMENT SIZE:</strong></label>
                                <p>{reviewData.eqipsize}</p>
                              </div>
                              <div>
                                <label><strong>EQUIPMENT WEIGHT:</strong></label>
                                <p>{reviewData.eqipwt}</p>
                              </div>
                            </div>

                            <div className="two-in-row">
                              <div className="field">
                                <strong>REFER DRG THERE:</strong>
                                <span className="value">{reviewData.refdrgthere === 1 ? 'Yes' : reviewData.refdrgthere === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>SUPPORT LOCATION SPECIFIED:</strong>
                                <span className="value">{reviewData.suplocspd === 1 ? 'Yes' : reviewData.suplocspd === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>EQUIPMENT POSITION:</strong>
                                <span className="value">{reviewData.eqipposition}</span>
                              </div>
                              <div className="field">
                                <strong>ANY SPL MARKING REQUIRED?</strong>
                                <span className="value">{reviewData.anysplmreq === 1 ? 'Yes' : reviewData.anysplmreq === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>EQPT LIFTING BELT REQUIRED?</strong>
                                <span className="value">{reviewData.eqliftbeltreq === 1 ? 'Yes' : reviewData.eqliftbeltreq === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>NAME PLATE REQUIRED?</strong>
                                <span className="value">{reviewData.nameplatereq === 1 ? 'Yes' : reviewData.nameplatereq === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>SPECIAL TEST REQUIRED?</strong>
                                <span className="value">{reviewData.spltestreq === 1 ? 'Yes' : reviewData.spltestreq === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>SENSITIVITY SPECIFIED?</strong>
                                <span className="value">{reviewData.senspd === 1 ? 'Yes' : reviewData.senspd === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>PRINTING COLOUR:</strong>
                                <span className="value">{reviewData.printcolor}</span>
                              </div>
                              <div className="field">
                                <strong>PRV:</strong>
                                <span className="value">{reviewData.prv === 1 ? 'Yes' : reviewData.prv === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>DESCNT:</strong>
                                <span className="value">{reviewData.descnt === 1 ? 'Yes' : reviewData.descnt === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>LHOOK:</strong>
                                <span className="value">{reviewData.lhook === 1 ? 'Yes' : reviewData.lhook === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>WHEEL:</strong>
                                <span className="value">{reviewData.wheel === 1 ? 'Yes' : reviewData.wheel === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>SLINGS:</strong>
                                <span className="value">{reviewData.slings === 1 ? 'Yes' : reviewData.slings === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>FL PROVISION:</strong>
                                <span className="value">{reviewData.flprov === 1 ? 'Yes' : reviewData.flprov === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>LID HANDLE:</strong>
                                <span className="value">{reviewData.lidhandle === 1 ? 'Yes' : reviewData.lidhandle === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>LOCK:</strong>
                                <span className="value">{reviewData.locks === 1 ? 'Yes' : reviewData.locks === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>HU. IN:</strong>
                                <span className="value">{reviewData.huin === 1 ? 'Yes' : reviewData.huin === 0 ? 'No' : 'N/A'}</span>
                              </div>
                              <div className="field">
                                <strong>FOAM:</strong>
                                <span className="value">{reviewData.foam}</span>
                              </div>
                            </div>

                            <div className="review-outputs">
                              <div className="section">
                                <div className="main-heading">REVIEW OUTPUTS</div>
                                <div className="field-row">
                                  <div className="field">
                                    <strong>CASE TYPE:</strong>
                                    <span className="value">{reviewData?.casetype}</span>
                                  </div>
                                  <div className="field">
                                    <strong>RESULT:</strong>
                                    <span className="value">{reviewData?.caseresult}</span>
                                  </div>
                                </div>
                                <div className="field-row">
                                  <div className="field">
                                    <strong>FOAM:</strong>
                                    <span className="value">{reviewData?.rofoam}</span>
                                  </div>
                                  <div className="field">
                                    <strong>PRINTING COLOUR:</strong>
                                    <span className="value">{reviewData?.roprintcolor}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="section">
                                <div className="main-heading">REQUIRED ITEMS</div>
                                <div className="items-container">
                                {formData?.listitem?.map((row: any, index: number) => (
                                      <div className="item" key={row.testaccessid}>
                                        <input type="checkbox"
                                          id={`checkbox-${row.testaccessid}`} // Ensure testaccessid is used here
                                          value={row.testaccessid}
                                          name="accessid"
                                          onChange={(e) => handleReviewChanges1(e, row.testaccessid, index)} />
                                        <label htmlFor={`checkbox-${row.id}`}>{row.name}</label>
                                      </div>
                                    ))}
                                </div>
                              </div>

                              <div className="section">
                                <div className="main-heading">REVIEW DETAILS</div>
                                <div className="field-row">
                                  <div className="field">
                                    <strong>REVIEW POINTS:</strong>
                                    <span className="value">{reviewData?.revpoints}</span>
                                  </div>
                                </div>
                                <div className="field-row">
                                  <div className="field">
                                    <strong>NOTE:</strong>
                                    <span className="value">{reviewData?.note}</span>
                                  </div>
                                </div>
                                <div className="review-history">
                                  <strong>REVIEW HISTORY:</strong>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Rev. No</TableCell>
                                        <TableCell>Rev. Date</TableCell>
                                        <TableCell>Review Points</TableCell>
                                        <TableCell>ECN No</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {rows.map((row, index) => (
                                        <TableRow key={index}>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Button onClick={() => {
                            handlePrint(null, () => contentToPrint.current);
                          }} style={{ marginTop: '10px', marginLeft: '10px' }} className="no-print"
                            variant="contained"
                            color="primary"
                            startIcon={<FontAwesomeIcon icon={faPrint} />}>
                            Print
                          </Button>
                        </div>
                      )}
                      <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                    </div>
                  </TabPanel>


                  {/* FOR OUTPUT FORM */}
                  <TabPanel value={value} index={2}>
                    <div className="panel">
                      <div className="main-heading">DESIGN OUTPUT DETAILS</div>
                      <table>
                        <thead>
                          <tr>
                            <th rowSpan={2} className='designop'>DESIGN OUTPUT</th>
                            <th colSpan={2} className='designop'>OUTPUT DATE</th>
                          </tr>
                          <tr>
                            <th className='designop'>SC</th>
                            <th className='designop' >HC</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><input className='designop' type="checkbox" checked={checkboxes.conceptDwgAttached}
                              onChange={(e) =>
                                setCheckboxes({ ...checkboxes, conceptDwgAttached: e.target.checked })
                              } />Concept dwg attached for finalization</td>
                            <td><input type="date" className="textbox-style" name="cptdwgattachsc"
                              value={outputData?.cptdwgattachsc}
                              onChange={handleOutputChanges} /></td>
                            <td><input type="date" className="textbox-style" name="cptdwgattachhc"
                              value={outputData?.cptdwgattachhc}
                              onChange={handleOutputChanges} /></td>
                          </tr>
                          <tr>
                            <td><input className='designop' type="checkbox" checked={checkboxes.casedrawingattached}
                              onChange={(e) =>
                                setCheckboxes({ ...checkboxes, casedrawingattached: e.target.checked })} />Case drawing attached</td>
                            <td><input type="date" className="textbox-style" name="casedwgattchsc"
                              value={outputData?.casedwgattchsc}
                              onChange={handleOutputChanges} /></td>
                            <td><input type="date" className="textbox-style" name="casedwgattchhc"
                              value={outputData?.casedwgattchhc}
                              onChange={handleOutputChanges} /></td>
                          </tr>
                          <tr>
                            <td><input className='designop' type="checkbox" checked={checkboxes.foamdrawingattached}
                              onChange={(e) =>
                                setCheckboxes({ ...checkboxes, foamdrawingattached: e.target.checked })} />Foam drawing attached</td>
                            <td><input type="date" className="textbox-style" name="foamdwgattachsc"
                              value={outputData?.foamdwgattachsc}
                              onChange={handleOutputChanges} /></td>
                            <td><input type="date" className="textbox-style" name="foamdwgattachhc"
                              value={outputData?.foamdwgattachhc}
                              onChange={handleOutputChanges} /></td>
                          </tr>
                          <tr>
                            <td><input className='designop' type="checkbox" checked={checkboxes.screenprintingdetailsattached}
                              onChange={(e) =>
                                setCheckboxes({ ...checkboxes, screenprintingdetailsattached: e.target.checked })} />Screen Printing details attached</td>
                            <td><input type="date" className="textbox-style" name="scnprtdetattachsc"
                              value={outputData?.scnprtdetattachsc}
                              onChange={handleOutputChanges} /></td>
                            <td><input type="date" className="textbox-style" name="scnprtdetattachhc"
                              value={outputData?.scnprtdetattachhc}
                              onChange={handleOutputChanges} /></td>
                          </tr>
                          <tr>
                            <td><input className='designop' type="checkbox" checked={checkboxes.billmaterialattached}
                              onChange={(e) =>
                                setCheckboxes({ ...checkboxes, billmaterialattached: e.target.checked })} />Bill of material attached</td>
                            <td><input type="date" className="textbox-style" name="billofmatattachsc"
                              value={outputData?.billofmatattachsc}
                              onChange={handleOutputChanges} /></td>
                            <td><input type="date" className="textbox-style" name="billofmatattachhc"
                              value={outputData?.billofmatattachhc}
                              onChange={handleOutputChanges} /></td>
                          </tr>
                          <tr>
                            <td><input className='designop' type="checkbox" checked={checkboxes.stiffnerdrawingattached}
                              onChange={(e) =>
                                setCheckboxes({ ...checkboxes, stiffnerdrawingattached: e.target.checked })} />Stiffner drawing attached</td>
                            <td><input type="date" className="textbox-style" name="stfdwgattachsc"
                              value={outputData?.stfdwgattachsc}
                              onChange={handleOutputChanges} /></td>
                            <td><input type="date" className="textbox-style" name="stfdwgattachhc"
                              value={outputData?.stfdwgattachhc}
                              onChange={handleOutputChanges} /></td>
                          </tr>
                          <tr>
                            <td><input className='designop' type="checkbox" checked={checkboxes.proddevplanattached}
                              onChange={(e) =>
                                setCheckboxes({ ...checkboxes, proddevplanattached: e.target.checked })} />Product development plan attached</td>
                            <td><input type="date" className="textbox-style" name="prddevplnaatchsc"
                              value={outputData?.prddevplnaatchsc}
                              onChange={handleOutputChanges} /></td>
                            <td><input type="date" className="textbox-style" name="prddevplnaatchhc"
                              value={outputData?.prddevplnaatchhc}
                              onChange={handleOutputChanges} /></td>
                          </tr>
                          <tr>
                            <td><input className='designop' type="checkbox" checked={checkboxes.dfmeaattached}
                              onChange={(e) =>
                                setCheckboxes({ ...checkboxes, dfmeaattached: e.target.checked })} />DFMEA attached</td>
                            <td><input type="date" className="textbox-style" name="dfmeaattachsc"
                              value={outputData?.dfmeaattachsc}
                              onChange={handleOutputChanges} /></td>
                            <td><input type="date" className="textbox-style" name="dfmeaattachhc"
                              value={outputData?.dfmeaattachhc}
                              onChange={handleOutputChanges} /></td>
                          </tr>
                        </tbody>
                      </table>
                      <DialogActions>
                        <Button onClick={handleClose2} color="primary" >
                          Clear
                        </Button>

                        <Button onClick={handleSubmitOutput} variant='contained' color="primary" >
                          Save
                        </Button>
                      </DialogActions>
                    </div>
                    <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </TabPanel>

                  {/* FOR VERIFICATION SUBMIT */}
                  <TabPanel value={value} index={3}>
                    <div className="panel">
                      <table>
                        <thead>
                          <tr>
                            <td rowSpan={2} className='tableline'></td>
                            <th rowSpan={2} className='designop'>CUSTOMER'S INPUT</th>
                            <th colSpan={4} className='designop'>OUTPUT </th>
                          </tr>

                          <tr>
                            <th className='designop'>FOAM DWG DIMNS</th>
                            <th className='designop'>FOAM CUT DIMNS</th>
                            <th className='designop'>CASE DIMNS</th>
                            <th className='designop'>CASE DIMNS</th>
                          </tr>
                          <tr>
                            <td rowSpan={2} className='tableline'></td>
                            <th className='designop'>EQPT. DIMNS.</th>
                            <th className='designop'>BOTTOM FOAM </th>
                            <th className='designop'>BOTTOM FOAM</th>
                            <th className='designop'>EXTERIOR DIMN.</th>
                            <th className='designop'>INTERIOR DIMN.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td rowSpan={7} className='verticaltext'>DIMENSIONS</td>
                            <td><input type="text" className="textbox-style" name="cieqwidth" value={verifyData?.cieqwidth} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="fdwgbtmwidth" value={verifyData?.fdwgbtmwidth} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="fcutbtmwidth" value={verifyData?.fcutbtmwidth} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="cdexterwidth" value={verifyData?.cdexterwidth} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="cdinterwidth" value={verifyData?.cdinterwidth} onChange={handleVerifyChanges} /></td>
                          </tr>
                          <tr>
                            <td><input type="text" className="textbox-style" name="cieqheight" value={verifyData?.cieqheight} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="fdwgbtmheight" value={verifyData?.fdwgbtmheight} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="fcutbtmheight" value={verifyData?.fcutbtmheight} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="cdexterheight" value={verifyData?.cdexterheight} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="cdinterheight" value={verifyData?.cdinterheight} onChange={handleVerifyChanges} /></td>
                          </tr>
                          <tr>
                            <td><input type="text" className="textbox-style" name="cieqlen" value={verifyData?.cieqlen} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="fdwgbtmlen" value={verifyData?.fdwgbtmlen} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="fcutbtmlen" value={verifyData?.fcutbtmlen} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="cdexterlen" value={verifyData?.cdexterlen} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="cdinterlen" value={verifyData?.cdinterlen} onChange={handleVerifyChanges} /></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td className='designop'><b>TOP FOAM</b></td>
                            <td className='designop'><b>TOP FOAM</b></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td><input type="text" className="textbox-style" name="fdwgtopwidth" value={verifyData?.fdwgtopwidth} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="fcuttopwidth" value={verifyData?.fcuttopwidth} onChange={handleVerifyChanges} /></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td><input type="text" className="textbox-style" name="fdwgtopheight" value={verifyData?.fdwgtopheight} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="fcuttopheight" value={verifyData?.fcuttopheight} onChange={handleVerifyChanges} /></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td><input type="text" className="textbox-style" name="fdwgtoplen" value={verifyData?.fdwgtoplen} onChange={handleVerifyChanges} /></td>
                            <td><input type="text" className="textbox-style" name="fcuttoplen" value={verifyData?.fcuttoplen} onChange={handleVerifyChanges} /></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <td rowSpan={17} className='verticaltext'>REQUIREMENTS</td>
                          {formData?.listrequire?.map((row: any, index: number) => (
                            <tr key={row.testaccessid} >
                              <td className="designop1" colSpan={3}>
                                {row.name}
                                {/* Conditionally render dropdown for the first item */}
                                {index === 0 && (
                                  <FormControl variant="outlined" size="small" style={{ marginLeft: '8px', marginTop: '1px' }}>
                                    <Select
                                      // labelId={`dropdown-label-${index}`}
                                      // id={`dropdown-${index}`} 
                                      name="handle"
                                      value={row.handle}
                                      onChange={(e: SelectChangeEvent<unknown>) => handleDropdownChange(e, index)}
                                      style={{ width: '130px', marginLeft: '20px', padding: '2px', marginTop: '0.01px' }}
                                    >
                                      <MenuItem value="tiny">TINY</MenuItem>
                                      <MenuItem value="ss">SS</MenuItem>
                                      <MenuItem value="plastic">PLASTIC</MenuItem>
                                    </Select>
                                  </FormControl>
                                )}
                              </td>
                              <td>
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    value={row.status}
                                    onChange={(e) => handleStatusChanges(e, row.testaccessid, index)}
                                    className="compact-radio-group"
                                    row
                                  >
                                    <FormControlLabel name="status" value="1" control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel name="status" value="0" control={<Radio size="small" />} label="No" />
                                  </RadioGroup>
                                </FormControl>
                              </td>
                              <td></td> {/* Empty column */}
                            </tr>
                          ))}

                        </tbody>
                      </table>
                      <DialogActions>
                        <Button onClick={handleClose3} color="primary" >
                          Clear
                        </Button>
                        <Button onClick={handleSubmitVerification} variant='contained' color="primary" >
                          Save
                        </Button>
                      </DialogActions>
                    </div>
                    <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </TabPanel>

                  {/* FOR DRAWING VALIDATION */}
                  <TabPanel value={value} index={4}>
                    <div className="panel">
                      <table>
                        <thead>
                          <tr>
                            <th className='designop'>DETAILS TO BE CHECKED</th>
                            <th className='designop'>CHK WITH</th>
                            <th className='designop'>CASE DWG</th>
                            <th className='designop'>BOM</th>
                            <th className='designop'>FOAM DWG</th>
                            <th className='designop'>PRINTING DWG</th>
                            <th className='designop'>REMARKS/LIST DATE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {validData?.map((row, index) => (
                            <tr key={row.id} >
                              <td className="valid" style={{ width: '150px' }} >
                                {row.detailstobechecked}
                                {index === 3 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='Req' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> REQ
                                    <input type="checkbox" className="checkbox-style" value='Not Req' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> NOT REQ
                                  </div>
                                )}
                                {index === 4 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='latch' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> LATCH
                                    <input type="checkbox" className="checkbox-style" value='hinge' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> HINGE
                                    <input type="checkbox" className="checkbox-style" value='handle' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> HANDLE
                                  </div>
                                )}
                                {index === 5 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='rivet' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> RIVET
                                    <input type="checkbox" className="checkbox-style" value='bolt' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> BOLT
                                    <input type="checkbox" className="checkbox-style" value='big' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> BIG
                                    SS CA <input type="checkbox" className="checkbox-style" value='2240' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2240
                                    <input type="checkbox" className="checkbox-style" value='2230' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2230
                                    <input type="checkbox" className="checkbox-style" value='2220' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2220
                                  </div>
                                )}
                                {index === 7 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    SS CA<input type="checkbox" className="checkbox-style" value='2150' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '5px', marginRight: '10px' }} /> 2150
                                    <input type="checkbox" className="checkbox-style" value='4350' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 4350
                                    <input type="checkbox" className="checkbox-style" value='4760' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 4760
                                  </div>
                                )}
                                {index === 10 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='epe' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> EPE
                                    <input type="checkbox" className="checkbox-style" value='xlpe' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> XLPE
                                    <input type="checkbox" className="checkbox-style" value='pu' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> PU
                                  </div>
                                )}
                                {index === 13 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='xlpe' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> XLPE
                                    <input type="checkbox" className="checkbox-style" value='silica gel' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> SILICA GEL
                                  </div>
                                )}
                                {index === 15 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='latch' name="text style" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> TEXT STYLE
                                    <input type="checkbox" className="checkbox-style" value='latch' name="ht eveness" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> HT EVENESS
                                  </div>
                                )}
                                {index === 17 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='yellow' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> YELLOW
                                    <input type="checkbox" className="checkbox-style" value='black' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> BLACK
                                    <input type="checkbox" className="checkbox-style" value='white' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> WHITE
                                  </div>
                                )}
                                {index === 19 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='green' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> GREEN
                                    <input type="checkbox" className="checkbox-style" value='grey' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> GREY
                                    <input type="checkbox" className="checkbox-style" value='black' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> BLACK
                                  </div>
                                )}
                                {index === 20 && (
                                  <div style={{ marginTop: '10px' }}>
                                    <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                      <input type="checkbox" className="checkbox-style" value='plastic' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> PLASTIC
                                      <input type="checkbox" className="checkbox-style" value='ss' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> SS
                                      <input type="checkbox" className="checkbox-style" value='tiny' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> TINY
                                    </div>
                                    <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                      SS CA <input type="checkbox" className="checkbox-style" value='2000' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '5px', marginRight: '10px' }} /> 2000
                                      <input type="checkbox" className="checkbox-style" value='2050' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2050
                                      <input type="checkbox" className="checkbox-style" value='2110' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2110
                                    </div>
                                  </div>
                                )}
                                {index === 21 && (
                                  <div style={{ marginTop: '10px' }}>
                                    <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                      <input type="checkbox" className="checkbox-style" value='prv' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> PRV
                                      <input type="checkbox" className="checkbox-style" value='humidity' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '10px', marginRight: '10px' }} /> HUMIDITY
                                      <input type="checkbox" className="checkbox-style" value='doc hold' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '10px', marginRight: '10px' }} /> DOC
                                    </div>
                                    <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                      SS CA<input type="checkbox" className="checkbox-style" value='2320' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '2px', marginRight: '10px' }} />  2320
                                      <input type="checkbox" className="checkbox-style" value='4000' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 4000
                                      <input type="checkbox" className="checkbox-style" value='4050' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 4050
                                    </div>
                                  </div>
                                )}
                                {index === 22 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='screen printing' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '5px', marginRight: '10px' }} /> SCREEN PRINTING
                                    <input type="checkbox" className="checkbox-style" value='name plate' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '5px', marginRight: '10px' }} /> NAME PLATE
                                  </div>
                                )}
                                {index === 23 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='1.5' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> 1.5
                                    <input type="checkbox" className="checkbox-style" value='3' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 3
                                    <input type="checkbox" className="checkbox-style" value='2.5' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2.5 SPECIFY
                                  </div>
                                )}
                                {index === 24 && (
                                  <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" className="checkbox-style" value='req' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> REQ
                                    <input type="checkbox" className="checkbox-style" value='not req' name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> NOT REQ
                                  </div>
                                )}
                              </td>
                              {index === 11 ? (
                                <td className="verticaltext" rowSpan={5}>
                                  {row.chkwith}
                                </td>
                              ) : index > 11 && index < 16 ? null : (
                                <td className="designop">
                                  {row.chkwith}
                                </td>
                              )}
                              {index === 9 ? (
                                <>
                                  <td className="designop">CASE WT. <input type="text" className="textbox-style" value={validDetailsData[index]?.casewt || ''} name="casewt" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                  <td className="designop">ACC. WT. <input type="text" className="textbox-style" value={validDetailsData[index]?.accwt || ''} name="accwt" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                  <td className="designop">FOAM WT. <input type="text" className="textbox-style" value={validDetailsData[index]?.foamwt || ''} name="foamwt" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                  <td className="designop">STIFF. WT.<input type="text" className="textbox-style" value={validDetailsData[index]?.stiffwt || ''} name="stiffwt" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                  <td className="designop">FINAL WT. <input type="text" className="textbox-style" value={validDetailsData[index]?.finalwt || ''} name="finalwt" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                </>
                              ) : index === 8 ? (
                                <>
                                  <td className="designop" colSpan={4}><input type="text" className="textbox-style" value={validDetailsData[index]?.eqpwt || ''} name="eqpwt" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                  <td className="tick"><input type="checkbox" className="styled-checkbox" value='1' name="remarkslistdate" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                </>
                              ) : (
                                <>
                                  <td className="tick"><input type="checkbox" className="styled-checkbox" value='1' name="casedwg" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                  <td className="tick"><input type="checkbox" className="styled-checkbox" value='1' name="bom" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                  <td className="tick"><input type="checkbox" className="styled-checkbox" value='1' name="foamdwg" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                  <td className="tick"><input type="checkbox" className="styled-checkbox" value='1' name="printingdwg" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                  <td className="tick"><input type="checkbox" className="styled-checkbox" value='1' name="remarkslistdate" onChange={(e) => handleValidationChanges(e, row.id, index)} /></td>
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <DialogActions>
                        <Button /*onClick={handleClose4}*/ color="primary" className="btn-print" >
                          Clear
                        </Button>
                        <Button onClick={handleSubmitValidation} variant='contained' color="primary" >
                          Save
                        </Button>
                      </DialogActions>
                      <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                    </div>
                  </TabPanel>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  )
}


function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}


export default UpdatePage
