import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import City from './admin/city';
// import { Home } from '@mui/icons-material';
import User from './admin/user';
import Priority from './crm/priority';
import Status from './crm/status';

import FollowType from './crm/followtype';
import Role from './crm/role';
import Mode from './crm/mode';
import Type from './crm/type';
import Employee from './admin/employee';
import Division from './crm/division';
// import DesignDev from './design/designdev';
import UpdatePage from './design/updatepage';



import { Home } from '@mui/icons-material';
import HomePage from './layouts/home/home';

import Designation from './admin/designation';
import Company from './admin/company';
import Plant from './admin/plant';
import Team from './crm/team';


import Department from './admin/department';
import QuoteTerms from './crm/quoteterms';
import AddEnquiry from './crm/addenquiry';
import UpdateEnquiry from './crm/updateenquiry';
import EnquiryDetail from './crm/enquiry';
import Enquiry from './crm/enquiry';
import Quotation from './crm/Quotation';
import DashboardCrypto from 'src/content/dashboards/Crypto';
import WatchList from './content/dashboards/Crypto/WatchList';
import HomeScreen from './crm/Home';
import FinancialYear from './admin/financialyear';
import Customer from './crm/customer';
import Target from './crm/target';
import DailyFollowupReport from './crm/dailyfollowreport';
import DesignDev from './design/designdev';
import UpdateQuotation from './crm/updatequotation';
import BudgetQuotation from './crm/BudgetQuotation';
import EssentialData from './crm/essential';
import Tax from './admin/tax';
import MisDashboard from './crm/MISDashboard';
import EnqStsReport from './crm/enquirystatuswisereport';

import MonthlyvsActual from './crm/Monthlywisereport';
import Sidebar from './layouts/SidebarLayout/sidebar1';
// import MisDashboards from './admin/misdashboard';
import EnquiryTable from './crm/customerdetail';



const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />
      },
      {
        path: 'crypto',
        element: <Crypto />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  },
  {
    path: '/admin',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'City',
        element: <City />
      },
      {
        path: 'user',
        element: <User />
      } ,
      {
        path: 'designation',
        element: <Designation />
      },
      {
        path: 'company',
        element: <Company />
      },
      {
        path: 'plant',
        element: <Plant />
      },{
     
        path: 'department',
        element: <Department />
      },
      {
        path: 'employee',
        element: <Employee/>
      }, 
      {
        path: 'employee',
        element: <Employee/>
      },
      {
        path: 'financialyear',
        element: <FinancialYear/>
      },
      {
        path: 'tax',
        element: <Tax/>
      },
      // {
      //   path: 'misdashboards',
      //   element: <MisDashboards/>
      // }

    ]
  },
  // {
  //   path: '/crm',
  //   element: <SidebarLayout />,
  //   children: [
  //     {
  //       path: '',
  //       element: <Navigate to="enquiry" replace />
  //     },
  //     {
  //       path: 'priority',
  //       element: <Priority />
  //     },
  //     {
  //       path: 'followtype',
  //       element: <FollowType />
  //     },
  //     {
  //       path: 'enquiry',
  //       element: <Enquiry />
  //     },
  //     {
  //       path: 'division',
  //       element: <Division />
  //     } 
  //     // ,
  //     // {
  //     //   path: 'addenquiry',
  //     //   element: <Addenquiry />
  //     // }

  //   ]
  // },
  
  {
  path: '/crm',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: <Navigate to="buttons" replace />
    },
    {
      path: 'priority',
      element: <Priority />
    },
    {
      path: 'status',
      element: <Status />
    },
    {
          path: 'followtype',
      element: <FollowType />
    },
    {
      path: 'role',
      element: <Role />
    },
    {
      path: 'mode',
      element: <Mode />
    },
    {
      path: 'type',
      element: <Type />
    },
    {
      path: 'team',
      element: <Team />
    },
    {
      path: 'quoteterms',
      element: <QuoteTerms />
    },
    {
      path: 'enquiry',
      element: <Enquiry />
    },
    {
      path: 'addenquiry',
      element: <AddEnquiry />
    },
    {
      path: 'updateenquiry',
      element: <UpdateEnquiry />
    },
    {
      path: 'quotation',
      element: <Quotation />
    },
    {
      path: 'customer',
      element: <Customer />
    },
    {
      path: 'target',
      element: <Target />
    },
    {
      path: 'dailyfollowupreport',
      element: <DailyFollowupReport />
    },
    {
      path:'enquirystatuswisereport',
      element:<EnqStsReport />
    },
    // {
    //   path: 'dashboard',
    //   element: <Dashboard />
    // }
    // ,
    {
      path: 'home',
      element: <HomeScreen />
    },
    {
      path: 'budgetquotationpending',
      element: <BudgetQuotation />
    },
    {
      path: 'updatequotationpending',
      element: <UpdateQuotation />
    },
    {
      path: 'essentialdata',
      element: <EssentialData />
    },
    {
      path: 'misdashboard',
      element: <MisDashboard />
    },
    
    {
      path: 'monthlyvsactual',
      element: <MonthlyvsActual />
    },
    // {
    //   path: 'statusreport',
    //   element: <Statusreport />
    // },
    {
      path: 'customerdetail',
      element: <EnquiryTable />
    },
    // {
    //   path: 'crmdashboard',
    //   element: <CrmDashboard />
    // },


  ]
  },
  {
    path: '/design',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="enquiry" replace />
      },
      {
        path: 'designdev',
        element: <DesignDev />
      },
      {
        path: 'updatepage',
        element: <UpdatePage/>
      }
      

      
    ]
  },

  {
    path: '/home',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'homepage',
        element: <HomePage />
      },
      {
        path: 'sidebar',
        element: <Sidebar />
      }

    ]
  }
];
export default routes;
