import React from 'react';
import MenuItem1 from './MenuItem1';
import './sidebar1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight, faTachometerAlt, faShoppingCart, faUsers, faChartBar, faFolder, faGear,faAnchor,faTruckFast,faFileEdit,faUsersGear,faStore,faCheckDouble,faWarehouse,faCaravan,faHandshake,faGears,faCalculator } from '@fortawesome/free-solid-svg-icons';
import * as icons from '@material-ui/icons';

const Sidebar: React.FC = () => {
  return (
    <div className="sidebar">
      {/* <MenuItem1 title="Dashboard"  icon={<FontAwesomeIcon icon={faTachometerAlt} />} url="/crm/addenquiry"/>
      <MenuItem1 title="Orders" icon={<FontAwesomeIcon icon={faShoppingCart} />} />
      <MenuItem1 title="Customers" icon={<FontAwesomeIcon icon={faUsers} />} />
      <MenuItem1 title="Reports" icon={<FontAwesomeIcon icon={faChartBar} />} /> */}
      <MenuItem1 title="Super Admin" icon={<FontAwesomeIcon icon={faGear} />} subItems={[
        { title: 'User Settings', 
          subItems: [
            { title: 'User Role', url: '/crm/role', icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'User', url: '/admin/user', icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Department', url: '/admin/department', icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Designation', url: '/admin/designation', icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Employee', url: '/admin/employee' , icon: <FontAwesomeIcon icon={faCircleRight} />},
            { title: 'Places (City)', url: '/admin/city' , icon: <FontAwesomeIcon icon={faCircleRight} />}
          ] },
        { title: 'Company Register', 
          subItems: [
            { title: 'Company', url: '/admin/company', icon: <FontAwesomeIcon icon={faCircleRight} />   },
            { title: 'Plant', url: '/admin/plant', icon: <FontAwesomeIcon icon={faCircleRight} />   },
            { title: 'Financial Year', url: '/admin/financialyear' , icon: <FontAwesomeIcon icon={faCircleRight} />  }
          ] },
      ]} />
       <MenuItem1 title="Marketing CRM" icon={<FontAwesomeIcon icon={faAnchor} />} subItems={[
        { title: 'General Settings', 
          subItems: [
            { title: 'Mode Of Enquiry', url: '/crm/mode', icon: <FontAwesomeIcon icon={faCircleRight} />  },
            { title: 'Enquiry Type', url: '/crm/type' , icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Followup Type', url: '/crm/followtype' , icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Priority', url: '/crm/priority', icon: <FontAwesomeIcon icon={faCircleRight} />  },
            { title: 'Status', url: '/crm/status', icon: <FontAwesomeIcon icon={faCircleRight} />  },
            { title: 'Terms & Conditions', url: '/crm/quoteterms' , icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Team', url: '/crm/team' , icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Target', url: '/crm/target' , icon: <FontAwesomeIcon icon={faCircleRight} /> }
          ] },
          { title: 'Customer Register', url: '/crm/customer' , icon: <FontAwesomeIcon icon={faCircleRight} /> },
          { title: 'Lead/Enquiry Register', url: '/crm/addenquiry', icon: <FontAwesomeIcon icon={faCircleRight} />  },
          { title: 'Lead/Enquiry Followup', url: '/crm/enquiry' , icon: <FontAwesomeIcon icon={faCircleRight} /> },
        { title: 'Reports', 
          subItems: [
            { title: 'Monthly Report', url: '/crm/Monthlyvsactual'  , icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Enquiry Status Wise Report', url: '/crm/enquirystatuswisereport' , icon: <FontAwesomeIcon icon={faCircleRight} />  },
            { title: 'Enquiry MIS Report', url: '/crm/misdashboard' , icon: <FontAwesomeIcon icon={faCircleRight} />  },
            { title: 'Daily Followup Report', url: '/crm/dailyfollowupreport' , icon: <FontAwesomeIcon icon={faCircleRight} />  },
            { title: 'Customer Detail Report', url: '/crm/customerdetail' , icon: <FontAwesomeIcon icon={faCircleRight} />  },
            { title: 'Enquiry Analysis Dashboard', url: '/crm/crmdashboard' , icon: <FontAwesomeIcon icon={faCircleRight} />  }
          ] },
      ]} />
       <MenuItem1 title="Design" icon={<FontAwesomeIcon icon={faFileEdit} />} subItems={[
        { title: 'General Settings', 
          subItems: [
            { title: 'Test & Accessories', url: '/design/role' , icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Modal', url: '/design/modal', icon: <FontAwesomeIcon icon={faCircleRight} />  }
          ] },
          { title: 'Design Followup', url: '/design/designdev', icon: <FontAwesomeIcon icon={faCircleRight} />  },
        { title: 'Reports', 
          subItems: [
            { title: 'Concept Drawing Report', url: '/design/company'  , icon: <FontAwesomeIcon icon={faCircleRight} /> },
            { title: 'Production Drawing Report ', url: '/admin/plant' , icon: <FontAwesomeIcon icon={faCircleRight} />  },
            { title: 'ECN Report', url: '/admin/financialyear'  , icon: <FontAwesomeIcon icon={faCircleRight} /> }
          ] },
      ]} />
        <MenuItem1 title="Sale Order" icon={<FontAwesomeIcon icon={faShoppingCart} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="Production" icon={<FontAwesomeIcon icon={faGears} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
       <MenuItem1 title="Stock & Inventory" icon={<FontAwesomeIcon icon={faStore} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="Quality Management" icon={<FontAwesomeIcon icon={faCheckDouble} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="Ware House" icon={<FontAwesomeIcon icon={faWarehouse} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
         <MenuItem1 title="Logistics" icon={<FontAwesomeIcon icon={faTruckFast} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="Service" icon={<FontAwesomeIcon icon={faHandshake} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="HRMS Payroll" icon={<FontAwesomeIcon icon={faUsersGear} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
       <MenuItem1 title="Accounts" icon={<FontAwesomeIcon icon={faCalculator} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />

    </div>
  );
};

export default Sidebar;
