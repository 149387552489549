import React, { useEffect, useRef, useState } from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, CardContent, Card, Button, TextField, MenuItem, Grid } from '@mui/material';

import { faFileExcel, faPrint } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

import apiService from 'src/services/api-service';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import PageTitle from 'src/components/PageTitle';
import saveAs from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';

interface DataItem1 {
  DataType: string;
  Month: string,
  quarter: string;
  newex: string;
  count: number;

}

interface ProcessedData {
  enquiries: {
    [key: string]: number;
  };
  poReceived: {
    [key: string]: number;
  };
  conversionRatio: {
    orderConversionPercentage: number;
    orderConversionPercentageRepeat: number;
    orderConversionPercentageFY21_24: number;
  };
}

const initialProcessedData: { [key: string]: ProcessedData } = {
  Q1: { enquiries: {}, poReceived: {}, conversionRatio: { orderConversionPercentage: 0, orderConversionPercentageRepeat: 0, orderConversionPercentageFY21_24: 0 } },
  Q2: { enquiries: {}, poReceived: {}, conversionRatio: { orderConversionPercentage: 0, orderConversionPercentageRepeat: 0, orderConversionPercentageFY21_24: 0 } },
  Q3: { enquiries: {}, poReceived: {}, conversionRatio: { orderConversionPercentage: 0, orderConversionPercentageRepeat: 0, orderConversionPercentageFY21_24: 0 } },
  Q4: { enquiries: {}, poReceived: {}, conversionRatio: { orderConversionPercentage: 0, orderConversionPercentageRepeat: 0, orderConversionPercentageFY21_24: 0 } }
};

const MonthlyvsActual: React.FC = () => {

  const [data, setData] = useState<DataItem1[]>([]);
  const [year, setYear] = useState([]);


  useEffect(() => {


    apiService.getDropdown("mas_finyear").then(response => {
      setYear(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
  }, []);

  const [fiscalYear, setFiscalYear] = useState<string>('');

  useEffect(() => {
    apiService.getMonthlyQuator(fiscalYear)
      .then(response => {
        setData(response.data);
        console.log('Data fetched :', response.data);
      })
      .catch(err => console.error('Error fetching count data:', err));

  }, [fiscalYear]);



  const handleConvert = () => {
    const table1Data = [
      [`REPORTS FOR THE YEAR ${fiscalYear} (ENQ vs PO)`],
      [''],
      ['S.NO', 'ENQUIRIES', '', '', '', 'PO RECEIVED', '', '', '', 'CONVERSION RATIO %', '', ''],
      ['', 'NEW', 'EX', 'REPEAT', 'TILL MARCH 2024', 'NEW', 'EX', 'REPEAT', 'TILL MARCH 2024', 'ORDER CONVERSION PERCENTAGE', 'ORDER CONVERSION PERCENTAGE (REPEAT)', 'ORDER CONVERSION PERCENTAGE (FY21-24)'],
      ...['Q1', 'Q2', 'Q3', 'Q4'].map((quarter, index) => {
        const quarterlyData = getQuarterlyData(quarter);
        const quarterlyData1 = getQuarterlyData(quarter);

        return [
          quarter,
          quarterlyData.find(d => d.newex === 'New')?.count || 0,
          quarterlyData.find(d => d.newex === 'Ex')?.count || 0,
          quarterlyData.find(d => d.newex === 'Repeat')?.count || 0,
          0,
          quarterlyData1.find(d => d.newex === 'New')?.count || 0,
          quarterlyData1.find(d => d.newex === 'Ex')?.count || 0,
          quarterlyData1.find(d => d.newex === 'Repeat')?.count || 0,
          0,
          '0%',
          '0%',
          '0%'
        ];
      }),
      ['TOTAL', totals.New, totals.Ex, totals.Repeat, 0, totals.New1, totals.Ex1, totals.Repeat1, 0, '0%', '0%', '0%']
    ];

    const table2Data = [
      [`REPORTS FOR THE YEAR ${fiscalYear} (ENQ vs PO)`],
      [''],
      ['S.NO', 'MONTH', 'ENQUIRIES', '', '', '', 'PO RECEIVED', '', '', '', 'ORDER CONVERSION PERCENTAGE', '', '', 'TOTAL'],
      ['', '', 'NEW', 'EX', 'REPEAT', 'TILL MARCH 2024', 'NEW', 'EX', 'REPEAT', 'TILL MARCH 2024', 'NEW', 'REPEAT', 'FY 21-24'],
      ...['Apr', 'May', 'Jun', 'Q1', 'Jul', 'Aug', 'Sep', 'Q2', 'Oct', 'Nov', 'Dec', 'Q3', 'Jan', 'Feb', 'Mar', 'Q4'].map((period, index) => {
        const isQuarter = period.startsWith('Q');
        const data = isQuarter ? getQuarterlyData(period) : getMonthlyData(period);
        const data1 = isQuarter ? getQuarterlyData(period) : getMonthlyData(period);

        return [
          index + 1,
          period,
          data.find(d => d.newex === 'New')?.count || 0,
          data.find(d => d.newex === 'Ex')?.count || 0,
          data.find(d => d.newex === 'Repeat')?.count || 0,
          0,
          isQuarter ? 0 : data1.find(d => d.newex === 'New')?.count || 0,
          isQuarter ? 0 : data1.find(d => d.newex === 'Ex')?.count || 0,
          isQuarter ? 0 : data1.find(d => d.newex === 'Repeat')?.count || 0,
          0,
          '0%',
          '0%',
          0,
          0
        ];
      }),
      ['TOTAL', '', totals.New, totals.Ex, totals.Repeat, 0, totals.New1, totals.Ex1, totals.Repeat1, 0, '0%', '0%', 0, 0]
    ];

    const workbook = XLSX.utils.book_new();

    const worksheet1 = XLSX.utils.aoa_to_sheet(table1Data);
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'Enquiries vs PO');

    const worksheet2 = XLSX.utils.aoa_to_sheet(table2Data);
    XLSX.utils.book_append_sheet(workbook, worksheet2, 'Reports for the Year');

    XLSX.writeFile(workbook, 'Enquiries_vs_PO.xlsx');
  };

  const [showImage, setShowImage] = useState(false)

  const printRef = useRef(null);
  const contentToPrint: any = useRef(null);

  const handlePrint1 = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Monthly Wise Report",
    onBeforePrint: () => setShowImage(true),
    onAfterPrint: () => setShowImage(false),
  });

  const getMonthlyData = (month) => data.filter(d => d.Month === month && d.DataType === 'QuarterlyData');
  const getMonthlyData1 = (month) => data.filter(d => d.Month === month && d.DataType === 'POReceivedData');

  const getQuarterlyData = (quarter) => data.filter(d => d.quarter === quarter && d.DataType === 'QuarterlyData');
  const getQuarterlyData1 = (quarter) => data.filter(d => d.quarter === quarter && d.DataType === 'POReceivedData');

  const calculateTotal = (type) => {
    return data.filter(d => d.DataType === 'POReceivedData')
      .filter(d => d.newex === type)
      .reduce((sum, d) => sum + d.count, 0);
  }

  const totals = {
    New: 0,
    Ex: 0,
    Repeat: 0,
    New1: 0,
    Ex1: 0,
    Repeat1: 0,
    New2: 0,
    Ex2: 0,
    Repeat2: 0,
    New3: 0,
    Ex3: 0,
    Repeat3: 0,
  };

  const accumulateTotals = (data, data1) => {
    data.forEach(d => {
      if (d.newex === 'New') totals.New += d.count / 2;
      if (d.newex === 'Ex') totals.Ex += d.count / 2;
      if (d.newex === 'Repeat') totals.Repeat += d.count / 2;
    });

    data1.forEach(d => {
      if (d.newex === 'New') totals.New1 += d.count / 2;
      if (d.newex === 'Ex') totals.Ex1 += d.count / 2;
      if (d.newex === 'Repeat') totals.Repeat1 += d.count / 2;
    });

  };

  const accumulateTotals1 = (quarterlyData, quarterlyData1) => {
    quarterlyData.forEach(d => {
      if (d.newex === 'New') totals.New2 += d.count;
      if (d.newex === 'Ex') totals.Ex2 += d.count;
      if (d.newex === 'Repeat') totals.Repeat2 += d.count;
    });

    quarterlyData1.forEach(d => {
      if (d.newex === 'New') totals.New3 += d.count;
      if (d.newex === 'Ex') totals.Ex3 += d.count;
      if (d.newex === 'Repeat') totals.Repeat3 += d.count;
    });

  };

  return (
    <>
      <PageTitleWrapper>
        <Grid container alignItems="center" spacing={3}>

          <Grid item xs={12} sm={4}>
            <PageTitle heading={`Monthly Wise Report ${fiscalYear}`} />
          </Grid>


          <Grid item xs={12} sm={8} container spacing={1} justifyContent="flex-end">
            <Grid item>
              <TextField
                required
                select
                label="Financial Year"
                value={fiscalYear}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFiscalYear(e.target.value);
                }}
                inputProps={{ maxLength: 11 }}
                sx={{
                  m: 1,
                  width: '23ch',
                  '& .MuiInputBase-root': {
                    height: '5%'
                  }
                }}
              >
                {year.map((option) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<FontAwesomeIcon icon={faPrint} />}
                onClick={() => {
                  handlePrint1(null, () => contentToPrint.current);
                }}
                sx={{
                  m: 1,
                  width: '12ch',
                  '& .MuiInputBase-root': {
                    height: '5%'
                  }
                }}
              >
                Print
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="success"
                endIcon={<FontAwesomeIcon icon={faFileExcel} />}
                onClick={handleConvert}
                sx={{
                  m: 1,
                  width: '12ch',
                  '& .MuiInputBase-root': {
                    height: '5%'
                  }
                }}
              >
                Excel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PageTitleWrapper >
      <Card >
        <CardContent ref={contentToPrint}>
          <div className="printable-content" style={{ padding: '20px' }}>
            <header style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
              <img
                src={require('../assets/logosaarc.png')}
                alt="Logo"
                style={{ width: '50px', height: '50px', marginRight: '20px' }}
              />
              <h1 style={{ margin: 0 }}>Monthly Wise Report {fiscalYear}</h1>
            </header>
          </div>
          <TableContainer style={{ maxWidth: '100%' }}>
            <Table style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2}><strong>S.NO</strong></TableCell>
                  <TableCell colSpan={5}><strong>ENQUIRIES</strong></TableCell>
                  <TableCell colSpan={5}><strong>PO RECEIVED</strong></TableCell>
                  <TableCell colSpan={3}><strong>CONVERSION RATIO %</strong></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>NEW</TableCell>
                  <TableCell>EX</TableCell>
                  <TableCell>REPEAT</TableCell>
                  <TableCell>TILL MARCH 2024</TableCell>
                  <TableCell>TOTAL</TableCell>
                  <TableCell>NEW</TableCell>
                  <TableCell>EX</TableCell>
                  <TableCell>REPEAT</TableCell>
                  <TableCell>TILL MARCH 2024</TableCell>
                  <TableCell>TOTAL</TableCell>
                  <TableCell>ORDER CONVERSION PERCENTAGE</TableCell>
                  <TableCell>ORDER CONVERSION PERCENTAGE (REPEAT)</TableCell>
                  <TableCell>ORDER CONVERSION PERCENTAGE (FY21-24)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {['Q1', 'Q2', 'Q3', 'Q4'].map((quarter, index) => {
                  const quarterlyData = getQuarterlyData(quarter);
                  const quarterlyData1 = getQuarterlyData1(quarter);

                  accumulateTotals1(quarterlyData, quarterlyData1);

                  return (
                    <TableRow key={quarter}>
                      <TableCell>{quarter}</TableCell>
                      <TableCell>{quarterlyData.find(d => d.newex === 'New')?.count || 0}</TableCell>
                      <TableCell>{quarterlyData.find(d => d.newex === 'Ex')?.count || 0}</TableCell>
                      <TableCell>{quarterlyData.find(d => d.newex === 'Repeat')?.count || 0}</TableCell>
                      <TableCell>{0}</TableCell>
                      <TableCell>{0}</TableCell>
                      <TableCell>{quarterlyData1.find(d => d.newex === 'New')?.count || 0}</TableCell>
                      <TableCell>{quarterlyData1.find(d => d.newex === 'Ex')?.count || 0}</TableCell>
                      <TableCell>{quarterlyData1.find(d => d.newex === 'Repeat')?.count || 0}</TableCell>
                      <TableCell>{0}</TableCell>
                      <TableCell>{0}%</TableCell>
                      <TableCell>{0}%</TableCell>
                      <TableCell>{0}%</TableCell>
                      <TableCell>{0}%</TableCell>
                    </TableRow>
                  );
                })}

                <TableRow>
                  <TableCell colSpan={1} style={{ textAlign: 'center', padding: '10px' }}>TOTAL</TableCell>
                  <TableCell>{totals.New2}</TableCell>
                  <TableCell>{totals.Ex2}</TableCell>
                  <TableCell>{totals.Repeat2}</TableCell>
                  <TableCell>{0}</TableCell>
                  <TableCell>{totals.New3}</TableCell>
                  <TableCell>{totals.Ex3}</TableCell>
                  <TableCell>{totals.Repeat3}</TableCell>
                  <TableCell>{0}</TableCell>
                  <TableCell>{0}</TableCell>
                  <TableCell>{0}</TableCell>
                  <TableCell>{0}%</TableCell>
                  <TableCell>{0}%</TableCell>
                  <TableCell>{0}%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <TableContainer >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={14} style={{ textAlign: 'center', padding: '10px' }}>
                    REPORTS FOR THE YEAR {fiscalYear} (ENQ vs PO)
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} style={{ padding: '10px' }}>S.NO</TableCell>
                  <TableCell rowSpan={2} style={{ padding: '10px' }}>MONTH</TableCell>
                  <TableCell colSpan={4} style={{ textAlign: 'center', padding: '10px' }}>ENQUIRIES</TableCell>
                  <TableCell colSpan={4} style={{ textAlign: 'center', padding: '10px' }}>PO RECEIVED</TableCell>
                  <TableCell colSpan={3} style={{ textAlign: 'center', padding: '10px' }}>ORDER CONVERSION PERCENTAGE</TableCell>
                  <TableCell rowSpan={2} style={{ padding: '10px' }}>TOTAL</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: '10px' }}>NEW</TableCell>
                  <TableCell style={{ padding: '10px' }}>EX</TableCell>
                  <TableCell style={{ padding: '10px' }}>REPEAT</TableCell>
                  <TableCell style={{ padding: '10px' }}>TILL MARCH 2024</TableCell>
                  <TableCell style={{ padding: '10px' }}>NEW</TableCell>
                  <TableCell style={{ padding: '10px' }}>EX</TableCell>
                  <TableCell style={{ padding: '10px' }}>REPEAT</TableCell>
                  <TableCell style={{ padding: '10px' }}>TILL MARCH 2024</TableCell>
                  <TableCell style={{ padding: '10px' }}>NEW</TableCell>
                  <TableCell style={{ padding: '10px' }}>REPEAT</TableCell>
                  <TableCell style={{ padding: '10px' }}>FY 21-24</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {['Apr', 'May', 'Jun', 'Q1', 'Jul', 'Aug', 'Sep', 'Q2', 'Oct', 'Nov', 'Dec', 'Q3', 'Jan', 'Feb', 'Mar', 'Q4'].map((period, index) => {
                  const isQuarter = period.startsWith('Q');
                  let data = [];
                  let data1 = [];

                  if (isQuarter) {
                    data = getQuarterlyData(period);
                  } else {
                    data = getMonthlyData(period);
                    data1 = getMonthlyData1(period);
                  }

                  accumulateTotals(data, data1);

                  return (
                    <TableRow
                      key={period}
                      style={{
                        backgroundColor: isQuarter ? '#f0f0f0' : 'inherit',
                        fontWeight: isQuarter ? 'bold' : 'normal',
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{period}</TableCell>
                      <TableCell>{data.find(d => d.newex === 'New')?.count || 0}</TableCell>
                      <TableCell>{data.find(d => d.newex === 'Ex')?.count || 0}</TableCell>
                      <TableCell>{data.find(d => d.newex === 'Repeat')?.count || 0}</TableCell>
                      <TableCell>{0}</TableCell>
                      <TableCell>{isQuarter ? 0 : data1.find(d => d.newex === 'New')?.count || 0}</TableCell>
                      <TableCell>{isQuarter ? 0 : data1.find(d => d.newex === 'Ex')?.count || 0}</TableCell>
                      <TableCell>{isQuarter ? 0 : data1.find(d => d.newex === 'Repeat')?.count || 0}</TableCell>
                      <TableCell>{0}</TableCell>
                      <TableCell>{0}</TableCell>
                      <TableCell>{0}</TableCell>
                      <TableCell>{0}%</TableCell>
                      <TableCell>{0}%</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell colSpan={2} style={{ color: '#fff', textAlign: 'center', padding: '10px' }}>TOTAL</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{totals.New}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{totals.Ex}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{totals.Repeat}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{0}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{totals.New1}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{totals.Ex1}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{totals.Repeat1}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{0}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{0}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{0}</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{0}%</TableCell>
                  <TableCell style={{ color: '#fff', padding: '10px' }}>{0}%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <style>
        {`
                    @media print {
                        .printable-content {
                            display: block !important;
                        }
   .css-rorn0c-MuiTableContainer-root {
    width: 100%;
    overflow-x: unset;

}
                    }

                    @media screen {
                        .printable-content {
                            display: none;
                        }
                    }
                `}
      </style>
    </>
  );
};

export default MonthlyvsActual;

