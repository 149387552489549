import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Button,
    Container,
    Grid,
    Card,
    CardContent,
    Box,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Modal,
    Typography
} from '@mui/material';
import Footer from 'src/components/Footer';
import apiService from 'src/services/api-service';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


interface LocationState {
    message?: string;
}

function Enquiry() {
    // For Grid Table
    const [data, setData] = useState([]);
    const [countData, setCountData] = useState([]);
    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState<string>('Today');


    const location = useLocation();

    useEffect(() => {
        // Type assertion to specify the expected shape of location.state
        const state = location.state as LocationState;

        if (state?.message) {
            toast.success(state.message);
        }
    }, [location.state]);
    // Fetch count data once on component mount
    useEffect(() => {
        apiService.getcount({})
            .then(response => {
                setCountData(response.data);
            })
            .catch(err => console.error('Error fetching count data:', err));
    }, []);

    // Fetch list data when activeItem, rowsPerPage, or page changes
    useEffect(() => {
        if (activeItem) {
            apiService.getlist(rowsPerPage.toString(), activeItem, (page + 1).toString())
                .then(response => {
                    setData(response.data);
                })
                .catch(err => console.error('Error fetching list data:', err));
        }
    }, [activeItem, rowsPerPage, page]);

    // Filter data based on search query
    useEffect(() => {
        const filtered = data.filter(item =>
            Object.values(item).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, data]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const toggleSearchModal = () => {
        setIsSearchModalOpen(!isSearchModalOpen);
    };

    const addNewEnquiry = () => {
        navigate('/crm/addenquiry');
    };

    const updateEnquiry = (id: number) => {
        console.log("id",id);
        
        navigate('/crm/updateenquiry', { state: { id} });
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const handleFilter = () => {
        setIsSearchModalOpen(false); // Close the modal after filtering
    };

    const handleClick = (type:string) => {
        setActiveItem(type);
        console.log(`${type} clicked`);
    };
    const getBackgroundColor = (type: string) => {
        return activeItem === type ? '#00184a' : '#ffffff';
    };
    const getColor = (type: string) => {
        return activeItem === type ? 'white' : 'black';
    };

    return (
        <>
            <Helmet>
                <title>Enquiry - Admin</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle heading="Enquiry" subHeading="" />
            </PageTitleWrapper>

            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        '& .header-item': {
                                            width: '13%',
                                            display: 'inline-block',
                                            textAlign: 'center',
                                            padding: '10px',
                                            margin: '0 5px',
                                            borderRadius: '5px',
                                            boxShadow: '0 4px 5px rgba(0, 0, 0, 0.1)',
                                            cursor: 'pointer'
                                        },
                                        '& .Pending': {
                                            backgroundColor: getBackgroundColor('Pending'),
                                            color: getColor('Pending'),
                                            fontWeight: 'bold'
                                        },
                                        '& .Today': {
                                            backgroundColor: getBackgroundColor('Today'),
                                            color: getColor('Today'),
                                            fontWeight: 'bold'
                                        },
                                        '& .Upcoming': {
                                            backgroundColor: getBackgroundColor('Upcoming'),
                                            color: getColor('Upcoming'),
                                            fontWeight: 'bold'
                                        },
                                        '& .Order': {
                                            backgroundColor: getBackgroundColor('Order'),
                                            color: getColor('Order'),
                                            fontWeight: 'bold'
                                        },
                                        '& .Reject': {  
                                            backgroundColor: getBackgroundColor('Reject'),
                                            color: getColor('Reject'),
                                            fontWeight: 'bold'
                                        }
                                    }}
                                >
                                    {countData.map((item) => (
                                        <div
                                            key={item.name}
                                            className={`header-item ${item.name}`}
                                            onClick={() => handleClick(item.name)}
                                        >
                                            {`${item.name} Follow`}<br />{item.count}
                                        </div>
                                    ))}

                                    <Button sx={{ margin: 1 }} onClick={toggleSearchModal} variant='contained' color="primary">
                                        <FontAwesomeIcon icon={faFilter as IconProp} /> Filter
                                    </Button>
                                    <Button sx={{ margin: 1 }} onClick={addNewEnquiry} variant='contained' color="warning">
                                        <FontAwesomeIcon icon={faUserPlus as IconProp} /> Add Enquiry
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                        <br />
                    </Grid>
                </Grid>

                <Modal
                    open={isSearchModalOpen}
                    onClose={toggleSearchModal}
                    aria-labelledby="search-filter-modal"
                    aria-describedby="search-filter-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography variant="h6" component="h2">
                            Search/Filter
                        </Typography>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Search"
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFilter}
                            sx={{ mt: 2 }}
                        >
                            Apply Filter
                        </Button>
                    </Box>
                </Modal>

                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="tableHead">S.No</TableCell>
                                    <TableCell className="tableHead">Enquiry No</TableCell>
                                    <TableCell className="tableHead">Date</TableCell>
                                    <TableCell className="tableHead">Type</TableCell>
                                    <TableCell className="tableHead">Customer</TableCell>
                                    <TableCell className="tableHead">MKTG. Officer</TableCell>
                                    <TableCell className="tableHead">Last Follow</TableCell>
                                    <TableCell className="tableHead">Next Follow</TableCell>
                                    <TableCell className="tableHead">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell className="tableBody">{index + 1}</TableCell>
                                        <TableCell style={{color: '#00184a'}} className="tableBody"><Button onClick={() =>{
                                             updateEnquiry(row.id)
                                        }}> {row.enqno} </Button></TableCell>
                                        <TableCell className="tableBody">{row.enqdate}</ TableCell>
                                        <TableCell className="tableBody">{row.type}</TableCell>
                                        <TableCell className="tableBody">{row.cusname}</TableCell>
                                        <TableCell className="tableBody">{row.mktname}</TableCell>
                                        <TableCell className="tableBody">{row.lastfdate}</TableCell>
                                        <TableCell className="tableBody">{row.nffdate}</TableCell>
                                        <TableCell className="tableBody">{row.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container>
            <Footer />
        </>
    );
}


export default Enquiry;
