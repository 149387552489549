import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
import { useNavigate } from 'react-router-dom';


interface Data {
    id: number,
    enqid: number,
    qdate: string | Date,
    qno: number,
    Rno: number,
    qval: number,
    cusref: string,
    cuspocname: string,
    cuspocno: string,
    cusdesign: string,
    status: string,
    lastqid: number,
    subject: string,
    cby: string,
    con: string | Date,
    mby: null,
    mon: null,
    taxid: number,
    taxamt: number,
    total: number,
    appby: null,
    appdate: null,
    comments: null,
    enqno: string,
    qdates: string | Date,
    creatby: string
}

const status = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'In-Active'
    }
];

function BudgetQuotation() {

    // For Grid Table
    // const [data, setData] = useState<Data[]>([]);
    const [data, setData] = useState([]);

    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    //Form data 
    const [formData, setFormData] = useState<any>({
        id: 0,
        enqid: 0,
        qdate: "",
        qno: 0,
        Rno: 0,
        qval: 0,
        cusref: "",
        cuspocname: "",
        cuspocno: "",
        cusdesign: "",
        status: "",
        lastqid: 0,
        subject: "",
        cby: "",
        con: "",
        mby: null,
        mon: null,
        taxid: 0,
        taxamt: 0,
        total: 0,
        appby: null,
        appdate: null,
        comments: null,
        enqno: '',
        qdates: '',
        creatby: ''
    });
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    // useEffect(() => {

    //     console.log(rowsPerPage.toString(), "size");
    //     console.log((page + 1).toString(), "page");

    //     apiService.getRole(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
    //         setData(response.data);
    //     }).catch((err) => {
    //         console.log("err", err)
    //     })
    // }, [rowsPerPage, page, formData]);
    useEffect(() => {
        apiService.getBudgetQuotationpending({})
            .then(response => {
                setData(response.data);
                console.log('check id ',response.data);
                

            })
            
            .catch(err => console.error('Error fetching count data:', err));
    }, []);
    console.log();
    



    //For Pagination 
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // Textbox and Select box onchage
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setFormData({
            id: '',
            role: '',
            isactive: '',
        });
        setSelectedItem(null);
    };

    // Insert and Update 
    let submit = (event: any) => {
        event.preventDefault(); // Prevents default form submission behavior
        // Insert
        if (selectedItem == null) {

            let data = {
                "id": formData?.id,
                "role": formData?.role,
                "isactive": formData?.isactive,
                "cby": "1"
            };

            apiService.insertRole(data
            ).then(response => {
                console.log("response", response.data);
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message)
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        role: '',
                        isactive: '',
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
        // Update
        else {
            let data = {
                "id": formData?.id,
                "role": formData?.role,
                "isactive": formData?.isactive,
                "cby": "1"
            };
            apiService.updateRole(Number(formData.id), data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        role: '',
                        isactive: '',
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
    };
    const navigate = useNavigate();
 
    const updateQuotation = (id: number, qno: string , enqid:number) => {
        console.log("id", id);
        console.log("qno", qno);
       
        
        navigate('/crm/updatequotationpending', { state: { id, qno , enqid } });
    }

    return (
        <>
            <Helmet>
                <title>Budget Quotation Pending</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle
                    heading="Budget Quotation Pending"
                    subHeading=""
                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >

                    <Grid item xs={12}>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Enquiry No</TableCell>
                                            <TableCell>Customer</TableCell>
                                            <TableCell>Quotation No</TableCell>
                                            <TableCell>Quotation Date</TableCell>
                                            <TableCell>Quotation Value</TableCell>

                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data.slice().map((row, index) => (
                                            <TableRow key={row.id} onClick={() => updateQuotation(row.id, row.qno, row.enqid )}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.enqno}</TableCell>
                                                <TableCell >{row.cuspocname}</TableCell>
                                                <TableCell>{row.qno}</TableCell>
                                                <TableCell>{row.qdate}</TableCell>
                                                <TableCell>{row.qval}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                                component="div"
                                count={data.length == 0 ? 0 : data[0]["count"]}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                            /> */}
                        </Paper>
                    </Grid>
                </Grid>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container>
            <Footer />

        </>
    );

}


export default BudgetQuotation;