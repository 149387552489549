import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import apiService from 'src/services/api-service';
import Circle from './circle';
import './homecss.css';
import { Navigate } from 'react-router';
const saarclogo = require("../assets/netcampus.jpeg");
const saarclogo2 = require("../assets/netcampus2.png");

// const Container = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   background: #d1ebea;
// `;

// const Circle = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   border: 2px solid white;
//   background: #fff;
//   color: #000;
//   font-size: 1rem;
//   width: 120px;
//   height: 120px;
//   text-align: center;
//   position: absolute;
// `;

// const CenterCircle = styled(Circle)`

//   width: 180px;
//   height: 180px;
//   font-size: 1rem;
//   font-weight: bold;
//    position: absolute;
// `;

// const OuterCircle = styled.div`
//   position: relative;
//   width: 500px;
//   height: 500px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const Text = styled.span`
//   color: white;
//   font-size: 1.5rem;
//   position: absolute;
//   top: 10px;
//   left: 10px;
// `;

// const positions = [
//     { top: '50%', left: '0%' },  // HCM
//     { top: '15%', left: '15%' },  // FICO
//     { top: '0%', left: '50%' },  // PP
//     { top: '15%', left: '85%' },  // SD
//     { top: '50%', left: '100%' },  // MM
//     { top: '85%', left: '85%' },  // QM
//     { top: '100%', left: '50%' },  // PP
//     { top: '85%', left: '15%' },  // EWM
//     { top: '50%', left: '0%' },  // SCM
//     { top: '15%', left: '15%' },  // CRM
//     { top: '0%', left: '50%' },  // BASIS
//     { top: '15%', left: '85%' },  // ABAP
// ];

const circlesData = [
    { label: 'Quality Management', color: '#F44336', url: '/crm/enquiry' },

    { label: 'Accounts', color: '#3F51B5', url: '/crm/enquiry' },
    { label: 'Design & Development', color: '#FF5722', url: '/design/designdev' },
    { label: 'Production', color: '#4CAF50', url: '/crm/enquiry' },

    { label: 'Warehouse & Logistics', color: '#00796B', url: '/crm/enquiry' },

    { label: 'Marketing CRM', color: '#388E3C', url: '/crm/enquiry' },
    { label: 'Sale Order', color: '#763d75', url: '/crm/enquiry' },
    { label: 'Stock & Inventory', color: '#3F51B5', url: '/crm/enquiry' },
    { label: 'HRMS (Payroll)', color: '#D32F2F', url: '/crm/enquiry' },

    { label: 'Ware House', color: '#8BC34A', url: '/crm/enquiry' },


];

// const labels = [
//   "HCM", "FICO", "PP", "SD", "MM", "QM", "PP", "EWM", "SCM", "CRM", "BASIS", "ABAP"
// ];
interface Label {
    id: number;
    name: string;
}

const HomeScreen: React.FC = () => {
    const isAuthenticated = true; // Replace with actual authentication logic

    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      return <Navigate to="/" />;
    }
    const [labels, setLabels] = useState<Label[]>([]);

    useEffect(() => {
        apiService.getDropdown("mas_module")
            .then(response => {
                setLabels(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });
    }, []);
    return (
        // <Container>
        //     <OuterCircle>
        //         <CenterCircle>NetCampus</CenterCircle>
        //         {labels.map((label, index) => (
        //             <Circle key={label.id} style={positions[index % positions.length]}>
        //                 {label.name}
        //             </Circle>
        //         ))}
        //     </OuterCircle>
        // </Container>
        //      <div className="sap-container">
        //      <div className="sap-center">SAP</div>
        //      <div className="sap-modules">
        //        {labels.map((module, index) => (
        //          <div key={index} className="sap-module">
        //            {module.name}
        //          </div>
        //        ))}
        //      </div>
        //    </div>
        <>
            <div className="diagram">
                <div className="center-circle">  <img src={saarclogo} /> <br />
                    <h3> CRM </h3> </div>
                {circlesData.map((circle, index) => (
                    <Circle key={index} label={circle.label} color={circle.color} url={circle.url} />
                ))}

            </div>
        </>
    );
};

export default HomeScreen;
