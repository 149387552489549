import React, { useEffect, useRef, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, Button, MenuItem, Grid, useMediaQuery, useTheme } from '@mui/material';
import apiService from 'src/services/api-service';
import { useLocation, useNavigate } from 'react-router-dom';
import { successToast } from 'src/layouts/utile/toast';
import { useReactToPrint } from 'react-to-print';
import { Helmet } from 'react-helmet-async';

interface FollowDataItem {

    id: number;
    fdate: Date; // Change from string to Date
    ftypeid: number;
    priority: string;
    fby: number;
    desp: string;
    nfdate: Date; // Change from string to Date
    statusid: number;
    ftypename: string;
    fbyname: string;
    statusname: string;
    fdate_s: string;
    nfdate_s: string;


}
type FollowData = FollowDataItem[];

interface EnqQuote {
    id: number;
    qdate: Date;          // Date object for date values
    qdate_s: string;      // String representation of date, if needed
    qno: string;          // Quote number
    rno: number;          // Reference number
    qval: number;         // Quote value
    cusref: string;       // Customer reference
    cuspocname: string;  // Customer Point of Contact name
    cuspocno: string;    // Customer Point of Contact number
    cusdesign: string;   // Customer design
    subject: string;     // Subject of the quote
}

interface EssentialTableRow {
    enqdetid: number;
    essdataid: number;
    compliance: string;
    attach: string;
}

interface TableRowData {
    id: number;
    enqdetid: number | null;  // Null or number depending on the data
    desp: string;
    mpnno: number | null;     // Null or number depending on the data
    qty: number;
    essentialTableRows: EssentialTableRow[];
    unitPrice?: number; // Add this if it is part of your data
    taxableAmount?: number; // Add this if it is part of your data
    igst?: number;
}

interface FormData {
    enquiry: Array<{
        id: number,
        enqid: number,
        qdate: string,
        qno: number,
        Rno: number,
        qval: number,
        cusref: string,
        cuspocname: string,
        cuspocno: string,
        cusdesign: string,
        status: string,
        lastqid: number,
        subject: string,
        cby: string,
        con: string,
        mby: string,
        mon: string,
        taxid: number,
        taxamt: number,
        total: number,
        appby: null,
        appdate: null,
        comments: null,
        finyearid: number,
        empid: number,
        enqdate: string,
        enqno: string,
        newex: string,
        typeid: number,
        modeid: number,
        cusid: number,
        divid: number,
        rfxenqno: string,
        dueon: string,
        desp: string,
        basicvalue: number,
        ccperson: string,
        cdesign: string,
        cmobile: string,
        cmail: string,
        tcperson: string,
        tdesign: string,
        tmobile: string,
        tmail: string,
        bankname: string,
        branchname: string,
        accno: string,
        ifsc: string,
        statusid: number,
        code: string,
        sname: string,
        name: string,
        cityid: number,
        cperson: string,
        mobile: string,
        email: string,
        gstno: string,
        pan: string,
        tinno: string,
        isactive: number,
        isexe: null,
        ismajor: null,
        add1: string,
        add2: string,
        add3: string,
        add4: string,
        add5: string,
        cadd1: string,
        cadd2: string,
        cadd3: string,
        cadd4: string,
        cadd5: string,
        ceo: string,
        add6: string,
        add7: string,
        cadd6: string,
        cadd7: string,
        qid: number,
        mpnno: string,
        qty: number,
        unitprice: number,
        Taxableamt: number,
        Taxid: number,
        enqdetid: number
    }>

    ,
    follow: null,
    enqdet: Array<{
        id: number;
        qid: number;
        desp: string;
        mpnno: string;
        qty: number;
        unitprice: number;
        Taxableamt: number;
        Taxid: number;
        taxamt: number;
        total: number;
        enqdetid: number;
    }>;
    enqdet_essdata: null,
    quote: null,
    quotedet: null


}
interface GST {
    id: number;
    name: string;
}

interface LocationState1 {
    id: number;
    qno: string;
    enqid: number;
}

const UpdateQuotation: React.FC = () => {

    const location = useLocation();
    const state = location.state as LocationState1;
    const navigate = useNavigate();
    const [companyData, setCompanyData] = useState<any[]>([]); // Assuming companyData will be an array of objects
    const [selectedCompany, setSelectedCompany] = useState<any>(null);
    const [term, setTerm] = useState([])
    const [retrive, setRetrive] = useState([])
    const defaultTextValues = [
        "Many thanks for enquiry. We are pleased to offer our best Quotation for the supply of Transit Cases.",
        "Looking forward to your valuable purchase order to serve you on time."
    ];

    const [formData, setFormData] = useState<FormData>(
        {
            enquiry: [
                {
                    id: 0,
                    enqid: 0,
                    qdate: "",
                    qno: 0,
                    Rno: 0,
                    qval: 0,
                    cusref: '',
                    cuspocname: '',
                    cuspocno: '',
                    cusdesign: '',
                    status: "",
                    lastqid: 0,
                    subject: "",
                    cby: "",
                    con: "7",
                    mby: "",
                    mon: "",
                    taxid: 0,
                    taxamt: 0,
                    total: 0,
                    appby: null,
                    appdate: null,
                    comments: null,
                    finyearid: 0,
                    empid: 0,
                    enqdate: "",
                    enqno: "",
                    newex: "",
                    typeid: 0,
                    modeid: 0,
                    cusid: 0,
                    divid: 0,
                    rfxenqno: "",
                    dueon: "",
                    desp: "",
                    basicvalue: 0,
                    ccperson: "",
                    cdesign: "",
                    cmobile: "",
                    cmail: "",
                    tcperson: "",
                    tdesign: "",
                    tmobile: "",
                    tmail: "",
                    bankname: "",
                    branchname: "",
                    accno: "",
                    ifsc: "",
                    statusid: 0,
                    code: "",
                    sname: "",
                    name: "",
                    cityid: 0,
                    cperson: "",
                    mobile: "",
                    email: "",
                    gstno: "",
                    pan: "",
                    tinno: "",
                    isactive: 0,
                    isexe: null,
                    ismajor: null,
                    add1: "",
                    add2: "",
                    add3: "",
                    add4: "",
                    add5: "",
                    cadd1: "",
                    cadd2: "",
                    cadd3: "",
                    cadd4: "",
                    cadd5: "",
                    ceo: "",
                    add6: '',
                    add7: '',
                    cadd6: '',
                    cadd7: '',
                    qid: 0,
                    mpnno: "",
                    qty: 0,
                    unitprice: 0,
                    Taxableamt: 0,
                    Taxid: 0,
                    enqdetid: 0
                }

            ],
            follow: null,
            enqdet: [
                {
                    id: 0,
                    qid: 0,
                    desp: "",
                    mpnno: "",
                    qty: 0,
                    unitprice: 0,
                    Taxableamt: 0,
                    Taxid: 0,
                    taxamt: 0,
                    total: 0,
                    enqdetid: 0
                }
            ],
            enqdet_essdata: null,
            quote: null,
            quotedet: null
        });



    const [quoteData, setQuoteData] = useState({
        enqquotedet: formData.enqdet.map(row => ({
            enqdetid: row.id,
            desp: row.desp,
            mpnno: row.mpnno,
            qty: Number(row.qty), // Ensure qty is a number
            unitprice: row.unitprice,
            taxableamt: row.Taxableamt,
            taxamt: 0,
            total: 0
        })), // Add the item field if required
        id: state.id,
        query: '',
        qdate: formData.enquiry[0].qdate,
        enqid: state.enqid, // Initialize enqid
        qval: formData.enquiry[0].qval,
        cusref: formData.enquiry[0].cusref,
        cuspocname: formData.enquiry[0].cuspocname,
        cuspocno: formData.enquiry[0].cuspocno,
        cusdesign: formData.enquiry[0].cusdesign,
        status: '',
        qno: state.qno,
        lastqid: 0,
        subject: '',
        cby: "1",
        taxid: 1,
        taxamt: 0,
        total: 0,
    });


    const [igst, setIgst] = useState<number>(0);

    useEffect(() => {
        const totalAmount = formData.enqdet.reduce((sum, row) => sum + (row.Taxableamt || 0), 0);
        const finalTotal = totalAmount + igst;

        setQuoteData(prevQuoteData => ({
            ...prevQuoteData,
            enqquotedet: formData.enqdet.map(row => ({
                id: row.id,
                enqdetid: row.enqdetid,
                desp: row.desp,
                mpnno: row.mpnno,
                qty: row.qty,
                unitprice: row.unitprice,
                taxableamt: row.Taxableamt,
                taxamt: 0, // Assuming IGST is 18%
                total: 0
            })),
            id: state.id,
            query: '',
            qdate: formData.enquiry[0].qdate,
            enqid: state.enqid,
            cusref: formData.enquiry[0].cusref,
            cuspocname: formData.enquiry[0].cuspocname,
            cuspocno: formData.enquiry[0].cuspocno,
            cusdesign: formData.enquiry[0].cusdesign,
            qno: state.qno,
            lastqid: 0,
            subject: '',
            cby: "1",
            qval: totalAmount,
            taxamt: igst,
            total: finalTotal
        }));
    }, [formData, igst]);

    const handleApprovalClick = async () => {
        try {
            setQuoteData(prevQuoteData => ({
                ...prevQuoteData,
                status: 'Successfully Approved',
            }));

            const dataToUpdate = {
                ...quoteData,
                status: 'Successfully Approved',
            };

            console.log("Quotation Data", dataToUpdate);
            const response = await apiService.postQuote(dataToUpdate);
            console.log('Update Quotation', response.data);
            navigate('/crm/budgetquotationpending')


            successToast(response.data);
            console.log('Quote saved successfully:', response.data);

        } catch (error) {
            console.error('Error saving quote:', error);
        }
    };

    const handleRejectClick = async () => {
        try {
            setQuoteData(prevQuoteData => ({
                ...prevQuoteData,
                status: 'Approval pending',
            }));

            const dataToUpdate = {
                ...quoteData,
                status: 'Approval pending',
            };

            console.log("Quotation Data", dataToUpdate);
            const response = await apiService.postQuote(dataToUpdate);

            console.log('Update Quotation', response.data);
            navigate('/crm/budgetquotationpending')
            successToast(response.data);
            console.log('Quote saved successfully:', response.data);
        } catch (error) {
            console.error('Error saving quote:', error);
        }
    };


    // Ensure you have a way to set the item field
    const handleItemChange = (event) => {
        setQuoteData(prevData => ({
            ...prevData,
            item: event.target.value
        }));
    };






    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [currentDate] = useState(getCurrentDate());
    const [gst, setGst] = useState<GST[]>([]);


    useEffect(() => {
        apiService.getDropdown("mas_company").then(response => {
            setCompanyData(response.data?.map((data: any) => ({
                id: data?.id,
                name: data?.name,
                sname: data?.sname,
                mobile: data?.mobile,
                phone: data?.phone,
                email: data?.email,
                pan: data?.pan,
                gstno: data?.gstno,
                tinno: data?.tinno,
                hsncode: data?.hsncode,
                gemsellerid: data?.gemsellerid,
                add1: data?.add1,
                add2: data?.add2,
                add3: data?.add3,
                add4: data?.add4,
                add5: data?.add5,
                mon: data?.mon
            })));
        }).catch(err => {
            console.log("err", err);
        });
    }, []);



    useEffect(() => {
        apiService.getDropdown("saarc_smt.crm_mas_quoteterms")
            .then(response => {
                setTerm(response.data);
                console.log("setTerm", response.data);
            })
            .catch(err => {
                console.log("err", err);
            });
        apiService.getDropdown("mas_tax").then(response => {
            setGst(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_ent_enqquote")
            .then(response => {
                setRetrive(response.data);
                console.log("setTerm", response.data);
            })
            .catch(err => {
                console.log("err", err);
            });
    }, []);



    useEffect(() => {
        console.log(state.id, "ttttt");
        if (state.id) {
            // Fetch data if id is provided (for updating an enquiry)
            apiService.getUpdateQuote(Number(state.id))
                .then(response => {
                    const data = response.data;
                    console.log("response.Quotation", data);


                    // Update the formData state with the fetched data
                    setFormData(data as FormData);
                    console.log("formData", formData);
                })
                .catch(error => {
                    console.error("Error fetching data: ", error);
                });
        }
    }, [state.id]);

    useEffect(() => {
        if (companyData.length > 0) {
            setSelectedCompany(companyData[0]);
        }
    }, [companyData]);






    const handleInputChange = (index, field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            enqdet: prevFormData.enqdet.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        [field]: field === 'qty' || field === 'unitprice' ? parseFloat(value) || '' : value,
                        Taxableamt:
                            (field === 'qty' || field === 'unitprice')
                                ? (field === 'qty' ? parseFloat(value) || 0 : item.qty) *
                                (field === 'unitprice' ? parseFloat(value) || 0 : item.unitprice)
                                : item.Taxableamt,
                    }
                    : item
            ),
        }));
    };



    const calculateTotal = () => {
        return formData.enqdet.reduce((sum, row) => sum + (row.Taxableamt || 0), 0);
    };

    const calculateUnitprice = () => {
        return formData.enqdet.reduce((sum, row) => sum + (row.unitprice || 0), 0);
    };

    const unittotal = calculateUnitprice()
    const totalAmount = calculateTotal();
    // const igstAmount = calculateIGST(totalAmount+unittotal);
    const finalTotal = totalAmount + igst + unittotal;


       const handleQuoteInputChange = (field, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            enquiry: [{
                ...prevFormData.enquiry[0],  // only update the first item
                [field]: value,
            }]

        }));
    };


    const [isPrintMode, setIsPrintMode] = useState<boolean>(false);

    const contentToPrint = useRef(null);
    const handlePrint1 = useReactToPrint({
        onBeforePrint: () => setIsPrintMode(true),
        onAfterPrint: () => setIsPrintMode(false),
        removeAfterPrint: true,


    });


    useEffect(() => {
        calculateGST();
    }, [quoteData.qval, quoteData.taxid]);

    const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = event.target.value as number;
        quoteData.taxid = value;
        calculateGST();
    };

    const calculateGST = () => {
        const gstRate = quoteData.taxid === 1 ? 0.18 : 0.12;
        const gstAmount = quoteData.qval * gstRate;
        setIgst(gstAmount);
    };

    const getTaxNameById = (id: number): string => {
        const taxOption = gst.find(option => option.id === id);
        return taxOption ? taxOption.name : '';
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    // console.log('check api datas',formData);


    return (
        <>   <Helmet>
            <title>Quotation</title>
        </Helmet>
            <Box ref={contentToPrint}>
                <Box className="print-container">
                    <Box p={4} sx={{ fontFamily: 'Arial, sans-serif', bgcolor: '#fff' }}>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={12} mb={-2}>
                        <Grid container alignItems="center" spacing={1} direction="row">
                                    <Grid item>
                                        <img
                                            src={require('src/assets/logosaarc.png')}
                                            alt="Company Logo"
                                            style={{ width: 40, height: 40 }}
                                            />
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: 'red',
                                                fontSize: 23,
                                                fontFamily: 'Arial, sans-serif',
                                                display: 'inline',
                                            }}
                                        >
                                            {selectedCompany?.name?.split(' ')[0]}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#000',
                                                fontSize: 22,
                                                fontFamily: 'Arial, sans-serif',
                                                display: 'inline',
                                            }}
                                        >
                                            &nbsp;{selectedCompany?.name?.split(' ').slice(1).join(' ')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={7} sm={7}>

                                <Box sx={{ bgcolor: '#e0e0e0', p: 2, borderRadius: '5px' }}>
                                    <Typography variant="subtitle2" sx={{ fontFamily: 'Arial, sans-serif', color: 'red', mb: 1, fontSize: 18 }}>
                                        PE Molded Advanced CEMILAC certified Mil Std. Containers
                                    </Typography>
                                    <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                                        {selectedCompany?.add1}, {selectedCompany?.add2}
                                    </Typography>
                                    <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                                        {selectedCompany?.add3} - {selectedCompany?.add5}, {selectedCompany?.add4}, India
                                    </Typography>
                                    <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                                        Phone: {selectedCompany?.phone} Fax: {selectedCompany?.mobile}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, fontFamily: 'Arial, sans-serif', fontSize: 18 }}>
                                        <strong>GST No:</strong> {selectedCompany?.gstno} &nbsp;&nbsp; <strong>HSN Code:</strong> {selectedCompany?.hsncode}
                                    </Typography>
                                    <Typography variant="body2" fontSize="18" fontFamily='Arial, sans-serif'>
                                        <strong>GEM SELLER ID:</strong> {selectedCompany?.gemsellerid}
                                    </Typography>
                                </Box>
                                <Box>
                                    {/* <br /> */}
                                    <Grid item xs={12} sm={6} md={6} >
                                        <Box sx={{ p: 1, borderRadius: '5px' }}>
                                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 17, fontFamily: 'Arial, sans-serif' }} variant="body2" >
                                                To
                                            </Typography>
                                            <Typography sx={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif',fontSize: 14 }} variant="body2" paragraph>
                                                {formData?.enquiry[0].cadd1} , {formData?.enquiry[0].cadd2} ,<br />
                                                {formData?.enquiry[0].cadd3} , {formData?.enquiry[0].cadd5} <br />
                                                {formData?.enquiry[0].cadd4} , {formData?.enquiry[0].cadd6},,{formData?.enquiry[0].cadd7} 
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Box>

                            </Grid>

                          
                            <Grid item xs={5}>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'Black', fontSize: 20 }}>
                                    BUDGETARY QUOTATION
                                </Typography>
                                <Grid container direction="row" spacing={1} mt={1}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, padding: '2px 0' }}>
                                            SAARC Enquiry No:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-6px' }}>
                                        {!isPrintMode ? (
                                            <TextField
                                                InputProps={{ readOnly: true }}
                                                variant="outlined"
                                                value={quoteData.enqid}
                                                sx={{
                                                    height: '35px',
                                                    padding: '2px 0',
                                                    '& .MuiInputBase-root': {
                                                        height: '100%',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '100%',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="body2">{quoteData.enqid}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, padding: '2px 0' }}>
                                            Quotation No:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-4px' }}>
                                        {!isPrintMode ? (
                                            <TextField
                                                value={quoteData.qno}
                                                InputProps={{ readOnly: true }}
                                                variant="outlined"
                                                sx={{
                                                    height: '35px',
                                                    padding: '2px 0',
                                                    '& .MuiInputBase-root': {
                                                        height: '100%',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '100%',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="body2">{quoteData.qno}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, padding: '2px 0' }}>
                                            Quotation Date:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-3px' }}>
                                        {!isPrintMode ? (
                                            <TextField
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                name="qdate"
                                                value={formData?.enquiry[0].qdate.split('T')[0]}
                                                onChange={(e) => handleQuoteInputChange('qdate', e.target.value)}
                                                variant="outlined"
                                                sx={{
                                                    width: '90%' ,
                                                    height: '35px',
                                                    padding: '2px 0',
                                                    '& .MuiInputBase-root': {
                                                        height: '100%',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '100%',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="body2">{quoteData.qdate}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, padding: '2px 0' }}>
                                            Customer Ref:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-6px' }}>
                                        {!isPrintMode ? (
                                            <TextField
                                                onChange={(e) => handleQuoteInputChange('cusref', e.target.value)}
                                                variant="outlined"
                                                value={formData?.enquiry[0].cusref}
                                                sx={{
                                                    height: '35px',
                                                    padding: '2px 0',
                                                    '& .MuiInputBase-root': {
                                                        height: '100%',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '100%',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="body2">{quoteData.cusref}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, padding: '2px 0' }}>
                                            Customer POC Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-6px' }}>
                                        {!isPrintMode ? (
                                            <TextField
                                                onChange={(e) => handleQuoteInputChange('cuspocname', e.target.value)}
                                                variant="outlined"
                                                value={formData?.enquiry[0].cuspocname}
                                                sx={{
                                                    height: '35px',
                                                    padding: '2px 0',
                                                    '& .MuiInputBase-root': {
                                                        height: '100%',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '100%',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="body2">{quoteData.cuspocname}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, padding: '2px 0' }}>
                                            Customer POC No:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-6px' }}>
                                        {!isPrintMode ? (
                                            <TextField
                                                onChange={(e) => handleQuoteInputChange('cuspocno', e.target.value)}
                                                variant="outlined"
                                                value={formData.enquiry[0].cuspocno}
                                                sx={{
                                                    height: '35px',
                                                    padding: '2px 0',
                                                    '& .MuiInputBase-root': {
                                                        height: '100%',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '100%',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="body2">{formData.enquiry[0].cuspocno}</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, padding: '2px 0' }}>
                                            Designation:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-6px' }}>
                                        {!isPrintMode ? (
                                            <TextField
                                                onChange={(e) => handleQuoteInputChange('cusdesign', e.target.value)}
                                                variant="outlined"
                                                value={formData.enquiry[0].cusdesign}
                                                sx={{
                                                    height: '35px',
                                                    padding: '2px 0',
                                                    '& .MuiInputBase-root': {
                                                        height: '100%',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '100%',
                                                    },
                                                }}
                                            />
                                        ) : (
                                            <Typography variant="body2">{quoteData.cusdesign}</Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}  >
                <Box
                  sx={{
                    position: 'relative',
                    marginTop: '-5%',
                    width: '100%',
                    padding: isSmallScreen ? '0px' : '5px',
                    boxSizing: 'border-box',
                  }}
                >
                  <Typography
                    variant="body2"
                    paragraph
                    fontFamily='Arial, sans-serif'
                    color='black'
                    sx={{
                      width: '100%',
                      padding: '10px',
                      boxSizing: 'border-box',
                    }}
                  >
                    Dear Sir/Madam,
                    <br />
                    {!isPrintMode ? (
                      <TextField
                        variant="outlined"
                        defaultValue={defaultTextValues}
                        multiline
                        rows={isSmallScreen ? 4 : 2}
                        sx={{
                          width: '100%',
                          maxWidth: '1130px',
                          '& .MuiInputBase-root': {
                            height: 'auto',
                            padding: '10px',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: 'auto',
                          },
                        }}
                      />
                    ) : (
                      <div className="print-value" style={{
                        width: '100%',
                        maxWidth: '1130px',
                        padding: '10px',
                        boxSizing: 'border-box',
                      }}>
                        {defaultTextValues}
                      </div>
                    )}
                  </Typography>
                </Box>
              </Grid>
                        </Grid>


                        <TableContainer
                            
                            className="print-table"
                            sx={{
                                width: '100%',
                                padding: isSmallScreen ? '0px' : '5px',
                                boxSizing: 'border-box',
                                overflowX: isPrintMode ? 'hidden' : 'auto',
                                overflowY: isPrintMode ? 'hidden' : 'auto',
                                marginTop: '-2%'
                              }}
                        >
                            <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '5%' }}>Sl. No.</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '26%' }}>Description</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>SAARC MPN No</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '14%' }}>Quantity</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Unit Price</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Taxable Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {formData.enqdet.map((item, index) => (
                                        <TableRow key={index} sx={{ height: '30px' }}>
                                            <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>{index + 1}</TableCell>
                                            <TableCell align="left" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                                                {isPrintMode ? (
                                                    <div className="print-desp">{item.desp}</div>
                                                ) : (
                                                    <TextField
                                                    sx={{
                                                        width: '100%' ,
                                                        height: '35px',
                                                        '& .MuiInputBase-root': {
                                                          height: '100%',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                          height: '100%',
                                                        },
                                                      }}
                                                        value={item.desp}
                                                        onChange={(e) => handleInputChange(index, 'desp', e.target.value)}
                                                        className={isPrintMode ? 'no-print' : ''}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                                                {isPrintMode ? (
                                                    <div className="print-mpnno">{item.mpnno}</div>
                                                ) : (
                                                    <TextField
                                                    sx={{
                                                        width: '100%' ,
                                                        height: '35px',
                                                        '& .MuiInputBase-root': {
                                                          height: '100%',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                          height: '100%',
                                                        },
                                                      }}
                                                        value={item.mpnno}
                                                        onChange={(e) => handleInputChange(index, 'mpnno', e.target.value)}
                                                        className={isPrintMode ? 'no-print' : ''}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                                                {isPrintMode ? (
                                                    <div className="print-qty">{item.qty}</div>
                                                ) : (
                                                    <TextField
                                                    sx={{
                                                        width: '100%' ,
                                                        height: '35px',
                                                        '& .MuiInputBase-root': {
                                                          height: '100%',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                          height: '100%',
                                                        },
                                                      }}
                                                        type="number"
                                                        value={item.qty || ''}
                                                        onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                                                        className={isPrintMode ? 'no-print' : ''}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                                                {isPrintMode ? (
                                                    <div className="print-unitPrice">{item.unitprice}</div>
                                                ) : (
                                                    <TextField
                                                    sx={{
                                                        width: '100%' ,
                                                        height: '35px',
                                                        '& .MuiInputBase-root': {
                                                          height: '100%',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                          height: '100%',
                                                        },
                                                      }}
                                                        type="number"
                                                        value={item.unitprice || ''}
                                                        onChange={(e) => handleInputChange(index, 'unitPrice', e.target.value)}
                                                        className={isPrintMode ? 'no-print' : ''}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center" sx={{ color: 'Black', fontFamily: 'Arial, sans-serif' }}>
                                                {item.Taxableamt || 0}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={6} align="left" sx={{ borderBottom: '1px solid #000', fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                            Comments or Special Instructions:
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                            TOTAL:
                                        </TableCell>
                                        <TableCell align="center">₹ {quoteData.qval.toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}>
                                            {!isPrintMode ? (
                                                <TextField
                                                    select
                                                    name="taxid"
                                                    id="taxid"
                                                    sx={{
                                                        width: '30%' ,
                                                        height: '35px',
                                                        '& .MuiInputBase-root': {
                                                          height: '100%',
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                          height: '100%',
                                                        },
                                                      }}
                                                    value={quoteData.taxid}
                                                    onChange={handleSelectChange}
                                                    className={isPrintMode ? 'no-print' : ''}
                                                >
                                                    {gst.map((option) => (
                                                        <MenuItem key={option.id} value={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ) : (
                                                <div className="print-tax">{getTaxNameById(quoteData.taxid)}</div>
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            {!isPrintMode ? (
                                                <TextField
                                                sx={{
                                                    width: '100%' ,
                                                    height: '35px',
                                                    '& .MuiInputBase-root': {
                                                      height: '100%',
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                      height: '100%',
                                                    },
                                                  }}
                                                    type="number"
                                                    value={igst}
                                                    onChange={(e) => setIgst(parseFloat(e.target.value) || 0)}
                                                    className={isPrintMode ? 'no-print' : ''}
                                                />
                                            ) : (
                                                <div className="print-igst">{igst.toFixed(2)}</div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold', borderTop: '1px solid #000' }}>
                                            TOTAL:
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderTop: '1px solid #000' }}>₹ {quoteData.total.toFixed(2)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>


                        <Box mt={2}>
                            <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 18}}>
                                SPECIAL TERMS & CONDITIONS:
                            </Typography>
                        </Box><br />
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="initial"
                            alignItems="flex-start"
                            textAlign="left"
                            sx={{ position: 'relative', left: '52px' }}
                        >
                            <Box>
                                {term.filter(item => item.type === "0").map((item, index) => (
                                    <Box key={index} mb={2}>
                                        <Typography
                                            sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, fontFamily: 'Arial, sans-serif' }}
                                            variant="body2"
                                        >
                                            {item?.sname}:
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                            <Box sx={{ position: 'relative', left: '40px' }}>
                                {term.filter(item => item.type === "0").map((item, index) => (
                                    <Box key={index} mb={2}>
                                        <Typography
                                            sx={{ fontSize: 14, color: 'black', fontFamily: 'Arial, sans-serif' }}
                                            variant="body2"
                                        >
                                            {item?.name}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>

                        <Box mt={1}
                        >
                            <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 18, color: 'black', fontFamily: 'Arial, sans-serif' }}><strong>STANDARD TERMS & CONDITIONS:</strong></Typography><br />
                            {term.filter(item => item.type === "1").map((item, index) => (
                                <Box key={index} mb={1.5}>
                                    <Typography
                                        sx={{ fontSize: 14, color: 'black', fontFamily: 'Arial, sans-serif' }}
                                        variant="body2"
                                    >
                                        {index + 1} . {item?.name}
                                    </Typography>
                                </Box>
                            ))}
                            <Box
                                sx={{
                                    width: '250px',
                                    height: '45px',
                                    bgcolor: '#e0e0e0',
                                    p: 1,
                                    borderRadius: '5px',
                                }}
                            >
                                <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                                    Queries to be Addressed to:
                                </Typography>
                            </Box>
                            <br />
                            <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black' fontSize={14}>
                                Mr/s. V. Suganthy, Mob: 8807060127 | Email: marketing@saarctecs.com<br />
                                Mr/s. C. Srinivita, Mob: 8807060125 | Email: marketing@saarctecs.com<br />
                                Mr/M. Sridavi, Mob: 8807042319 | Email: applicationengg@saarctecs.com
                            </Typography>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="45px"
                            >
                                <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                                    THANK YOU FOR YOUR BUSINESS SUPPORT
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <Typography variant="caption"><strong>Prepared By</strong></Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption">Checked By</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Button variant="contained" color="primary" onClick={() => {
                        handlePrint1(null, () => contentToPrint.current);
                    }} sx={{ position: 'absolute', left: 10, bottom: 35, margin: 1 }} className="no-print">
                        Print
                    </Button>

                    <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button sx={{ position: 'absolute', right: 150, bottom: 35, margin: 1 }} onClick={handleApprovalClick} variant="contained" color="primary" className="no-print" >
                            Approve
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button sx={{ position: 'absolute', right: 40, bottom: 35, margin: 1 }} onClick={handleRejectClick} variant="contained" color="primary" className="no-print" >
                            Reject
                        </Button>
                    </Box>
                </Box>

            </Box>
            <style>
                {`
        @media print {
                      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                          text-align: left;
                          
                          bottom: 0;
                          right: 0;
                          top: -5px;
                          left: 0;
                          margin: 0;
                          padding: 0 8px;
                          pointer-events: none;
                          border-radius: inherit;
                          border-style: solid;
                          border-width: 0px !important;
                          overflow: hidden;
                          min-width: 0%;
                          border-color: rgba(0, 0, 0, 0.23);
                           .print-table {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    font-size: 9pt;
  }
    

  .no-print {
    display: none;
  }

  .print-desp,
  .print-mpnno,
  .print-qty,
  .print-unitPrice,
  .print-tax,
  .print-igst {
    font-size: 9pt;
  }

  @page {
    size: A4;
    margin: 10mm;
  }

                      }
                            
                    }

                    @media screen {
                        .printable-content {
                            display: none;
                        }
                    }
                        @media print {
  .no-print {
    display: none;
  }
    .print-table {
        overflow: hidden !important; /* Ensure no scroll bars appear */
    }
}
                `}
            </style>

        </>
    );
};


export default UpdateQuotation;
