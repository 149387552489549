// src/components/Circle.tsx
import React from 'react';
import './homecss.css';
import { FaAnchor, FaPencil } from 'react-icons/fa6';
import { Button } from '@mui/material';
import * as icons from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
interface CircleProps {
    label: string;
    color: string;
    url: string;
}
const pencilIcon = FaPencil({}).props.children as React.ReactNode;
const style = {
    width: '100px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `no-repeat center`,
    backgroundSize: 'contain'
  };

const Circle: React.FC<CircleProps> = ({ label, color,url }) => {
    return (
        <div className="circle" style={{ backgroundColor: color }}>
           
           <Button
           style={{ color: '#f5f5f5' }}
            disableRipple
            component={RouterLink}
            to={url}
            // startIcon={<icons.BrightnessLowTwoTone />}
        >
              {label}
            </Button> 
        </div>
    );
};

export default Circle;