import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useNavigate } from "react-router-dom";
import apiService from 'src/services/api-service';
import './designdev.css';

interface Data1 {
  id: number;
  enqno: string;
  enqdate: Date;
  type: string;
  sname: string;
  cusname: string;
  mktname: string;
  req: string;
  status: string;
}

const DesignDev: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activePartition, setActivePartition] = useState<string | null>(null);
  const [countData, setCountData] = useState([]);
  const [tableData, setTableData] = useState<Data1[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const data = [
    {
      label: 'New Enquiries',
      partitions: [
        { label: 'Pending', key: 'NewEnq_Pending' },
        { label: 'Completed', key: 'NewEnq_Close' }
      ]
    },
    {
      label: 'Engineering Change Request',
      partitions: [
        { label: 'Pending', key: 'ECR_Pending' },
        { label: 'Completed', key: 'ECR_Close' }
      ]
    }
  ];

  useEffect(() => {
    apiService.getDesignCount({})
      .then(response => {
        setCountData(response.data);
        console.log("response.data", response.data);
      })
      .catch(err => console.error('Error fetching count data:', err));
  }, []);

  useEffect(() => {
    if (activePartition) {
      apiService.getDesignList(activePartition, rowsPerPage.toString(), (page + 1).toString(), "")
        .then(response => {
          console.log("Fetched list data:", response.data);
          setTableData(response.data);
        })
        .catch(err => {
          console.log("Error fetching grid data:", err);
        });
    }
  }, [activePartition, page, rowsPerPage]);

  const navigate = useNavigate();

  const handleClick = (index: number, partitionKey: string) => {
    setActiveIndex(index);
    setActivePartition(partitionKey);
    setPage(0); // Reset page to 0 when a new partition is clicked
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleUpdateClick = (id: number) => {
    localStorage.setItem('activeIndex', JSON.stringify(activeIndex));
    localStorage.setItem('activePartition', activePartition);
    localStorage.setItem('tableData', JSON.stringify(tableData));
    localStorage.setItem('page', JSON.stringify(page));
    localStorage.setItem('rowsPerPage', JSON.stringify(rowsPerPage));

    navigate('/design/updatepage', { state: { id } });
  };

  return (
    <>
      <Helmet>
        <title>Design & Development - Design</title>
      </Helmet>
      
      <PageTitleWrapper>
        <header>
          <PageTitle
            heading="Design and Development"
            subHeading=""
          />  
        </header>              
      </PageTitleWrapper>
      
      <Card>
        <CardContent>
          <div className="top-row">
            {data.map((item, index) => (
              <div key={index} className="box-container">
                <span className="box-label">{item.label}</span>
                <div className="partitions-container">
                  {item.partitions.map(partition => (
                    <div
                      key={partition.key}
                      className={`box ${activeIndex === index && activePartition === partition.key ? 'active' : ''}`}
                      onClick={() => handleClick(index, partition.key)}
                    >
                      <span className="partition-label">{partition.label}</span>
                      <span className="partition-digit">
                        {countData.find(cd => cd.name === partition.key)?.count || 0}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
      <br />
      {activePartition && (
        <Grid item xs={12} className="grid-margin">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-head">
                    <TableCell>S.No</TableCell>
                    <TableCell>Enquiry No</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Short name</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>MKTG Officer</TableCell>
                    <TableCell>Requirement</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((item: any, index) => (
                    <TableRow key={item.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.enqno}</TableCell>
                      <TableCell>{item.enqdate}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.sname}</TableCell>
                      <TableCell>{item.cusname}</TableCell>
                      <TableCell>{item.mktname}</TableCell>
                      <TableCell>{item.req}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleUpdateClick(item.id)}
                        >
                          Update
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={tableData.length === 0 ? 0 : tableData[0]["count"]}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Paper>
        </Grid>
      )}
    </>
  );
}

export default DesignDev;
