import axios from "axios";
import http from "../http-common";

export default {
  login(data: any) {
    return http.post("/Login", data);
  },

  //city
  getCity(size: any, page: any, search: any) {
    return http.get(`/city?size=${size}&page=${page}&search=${search}`);
  },
  insertCity(data1: any) {
    return http.post("/city/insert", data1);
  },
  updateCity(id: any, data1: any) {
    return http.post(`/city/update?id=${id}`, data1);
  },

  //Designation
  getDesignation(size: any, page: any, search: any) {
    return http.get(`/designation?size=${size}&page=${page}&search=${search}`);
  },
  insertDesignation(data1: any) {
    return http.post("/designation/insert", data1);
  },
  updateDesignation(id: any, data1: any) {
    return http.post(`/designation/update?id=${id}`, data1);
  },
  //user
  getUser(size: any, page: any, search: any) {
    return http.get(`/user?size=${size}&page=${page}&search=${search}`);
  },
  insertUser(data1: any) {
    return http.post("/user/insert", data1);
  },
  updateUser(id: any, data1: any) {
    return http.post(`/user/update?id=${id}`, data1);
  },
  getUser_id(id: any) {
    return http.get(`/user/${id}`);
  },

  // Dropdown
  getDropdown(tablename: any) {
    return http.get(`/dropdown?tablename=${tablename}`);
  },
 

  //priority
  getPriority(size: any, page: any, search: any) {
    return http.get(`/priority?size=${size}&page=${page}&search=${search}`);
  },
  insertPriority(data1: any) {
    return http.post("/priority/insert", data1);
  },
  updatePriority(id: any, data1: any) {
    return http.post(`/priority/update?id=${id}`, data1);

  },
  //Status
  getStatus(size: any, page: any, search: any) {
    return http.get(`/status?size=${size}&page=${page}&search=${search}`);
  },
  insertStatus(data1: any) {
    return http.post("/status/insert", data1);
  },
  updateStatus(id: any, data1: any) {
    return http.post(`/status/update?id=${id}`, data1);

  },
  getCompany(size: any, page: any, search: any) {
    return http.get(`/company?size=${size}&page=${page}&search=${search}`);
  },
  insertCompany(data1: object) {
    return http.post("/company/insert", data1);
  },
  updateCompany(id: any, data1: any) {
    return http.post(`/company/update?id=${id}`, data1);

  },
  getPlant(size: any, page: any, search: any) {
    return http.get(`/plant?size=${size}&page=${page}&search=${search}`);
  },
  insertPlant(data1: object) {
    return http.post("/plant/insert", data1);
  },
  updatePlant(id: any, data1: any) {
    return http.post(`/plant/update?id=${id}`, data1);

  },
  getTeam(size: any, page: any, search: any) {
    return http.get(`/team?size=${size}&page=${page}&search=${search}`);
  },
  getEmployee1(size: any, page: any, search: any) {
    return http.get(`/team/GetEmployee?size=${size}&page=${page}&search=${search}`);
  },
  insertTeam(data1: object) {
    return http.post("/team/insert", data1);
  },
  updateTeam(id: any, data1: any) {
    return http.post(`/team/update?id=${id}`, data1);

  },
  
  //followtype
  getFollowtype(size: any, page: any, search: any) {
    return http.get(`/followtype?size=${size}&page=${page}&search=${search}`);
  },
  insertFollowtype(data1: any) {
    return http.post("/followtype/insert", data1);
  },
  updateFollowtype(id: any, data1: any) {
    return http.post(`/followtype/update?id=${id}`, data1); 
  },

  postExcustomer(size: number, page: number, search: "", typeid: "" ) 
  {
    return http.post(`/CRMindexcustomer`, {
      size: size.toString(),
      page: page.toString(),
      search,
      typeid
    })
  },
  getlist(size, click, page) {
    return http.get('/crm/getlist', {
      params: {
        size: size,
        click: click,
        page: page
      }
    });
  },
  getstatuslist(size, click, page, status) {
    return http.get('/crm/getstatuslist', {
      params: {
        size: size,
        click: click,
        page: page,
        status: status
      }
    });
  },
  getcount(data1: any) {
    return http.get("/crm/getcount", data1);
  },
  getId (id: number) {
    return http.get('/crm/getrow', {
      params: { id: id },
    });
  },
  

  //Role
  getRole(size: any, page: any, search: any) {
    return http.get(`/role?size=${size}&page=${page}&search=${search}`);
  },
  insertRole(data1: any) {
    return http.post("/role/insert", data1);
  },
  updateRole(id: any, data1: any) {
    return http.post(`/role/update?id=${id}`, data1);
  },

  //Mode
  getMode(size: any, page: any, search: any) {
    return http.get(`/mode?size=${size}&page=${page}&search=${search}`);
  },
  insertMode(data1: any) {
    return http.post("/mode/insert", data1);
  },
  updateMode(id: any, data1: any) {
    return http.post(`/mode/update?id=${id}`, data1);
  },

  //Type
  getType(size: any, page: any, search: any) {
    return http.get(`/type?size=${size}&page=${page}&search=${search}`);
  },
  insertType(data1: any) {
    return http.post("/type/insert", data1);
  },
  updateType(id: any, data1: any) {
    return http.post(`/type/update?id=${id}`, data1);
  },

  //Department
  getDepartment(size: any, page: any, search: any) {
    return http.get(`/department?size=${size}&page=${page}&search=${search}`);
  },
  getEmployee(size: any, page: any, search: any) {
    return http.get(`/department/GetEmployee?size=${size}&page=${page}&search=${search}`);
  },
  insertDepartment(data1: any) {
    return http.post("/department/insert", data1);
  },
  updateDepartment(id: any, data1: any) {
    return http.post(`/department/update?id=${id}`, data1);
  },


// // Enquiry
//   postExcustomer(size: number, page: number, search: "", typeid: "" ) 
//   {
//     return http.post(`/CRMindexcustomer`, {
//       size: size.toString(),
//       page: page.toString(),
//       search,
//       typeid
//     })
//   },
//   postFormdata( data1: any) 
//   {
//     return http.post(`/crm`, { data1 })
//   },
  //QuoteTerms
  getQuoteTerms(size: any, page: any, search: any) {
    return http.get(`/quoteterms?size=${size}&page=${page}&search=${search}`);
  },
  insertQuoteTerms(data1: any) {
    return http.post("/quoteterms/insert", data1);
  },
  updateQuoteTerms(id: any, data1: any) {
    return http.post(`/quoteterms/update?id=${id}`, data1);
  },
  //Employee
  getEmp(size: any, page: any, search: any) {
    return http.get(`/employee?size=${size}&page=${page}&search=${search}`);
  },
  insertEmp(data1: any) {
    return http.post("/employee/insert", data1);
  },
  updateEmp(id: any, data1: any) {
    return http.post(`/employee/update?id=${id}`, data1);
  },

  //Division
  getDivision(size: any, page: any, search: any) {
    return http.get(`division/?size=${size}&page=${page}&search=${search}`);
  },
  insertDivision(data1: any) {
    return http.post("/division/insert", data1);
  },
  updateDivision(id: any, data1: any) {
    return http.post(`/division/update?id=${id}`, data1);
  },


// Enquiry
  postFormdata( data1: any) 
  {
    return http.post(`/crm`,  data1 )
  },
  // Upload Files
  uploadFiles(data1: any) {
    console.log(data1,"formdata");
    
    return http.post("/FileUpload/upload", data1,{
      headers: {
          'Content-Type': 'multipart/form-data',}
      });
  },

  //Customer
  getCustomer(size: any, page: any, search: any) {
    return http.get(`/customer/?size=${size}&page=${page}&search=${search}`);
  },
  getCustomer_id(id: number) {
    return http.get(`/customer/${id}`);
  },
  insertCustomer(data1: any) {
    return http.post("/customer/insert", data1);
  },
  updateCustomer(id: any, data1: any) {
    return http.post(`/customer/update?id=${id}`, data1);
  },

  // Target
  getTarget(size: any, page: any, search: any) {
    return http.get(`/target?size=${size}&page=${page}&search=${search}`);
  },
  insertTarget(data1: any) {
    return http.post("/target/insert", data1);
  },
  updateTarget(id: any, data1: any) {
    return http.post(`/target/update?id=${id}`, data1);
  },

  //Design and Development
  getDesignCount(data1: any) {
    return http.get("/design/getcount", data1);
  },
  getDesignList(name : any,size: any, page: any, search: any) {
    return http.get(`/design/getlist?click=${name}&size=${size}&page=${page}&search=${search}`);
  },
  getEssData (id: number) {
    return http.get('/design/getdraw_Essdata', {
      params: { id: id },
    });
  },
  getEnq_Date (id: number) {
    return http.get('/design/getEnq_Date', {
      params: { id: id },
    });
  },

  getInputForm(drawid: any){
    return http.get(`/design/get_drawreg/${drawid}`);
  },
  postdesigndata( data1: any) 
  {
    return http.post(`/design/submit_drawreg`,  data1 )
  },
  getReviewForm(drawid: any){
    return http.get(`/design/get_reviewdata/${drawid}`);
  },
  postreviewdata( data1: any) 
  {
    return http.post(`/design/submit_review`,  data1 )
  },

  //for financial year
  getFinancialYear(size: any, page: any, search: any) {
    return http.get(`/financialyear?size=${size}&page=${page}&search=${search}`);
  },
  insertFinancialYear(data1: any) {
    return http.post("/financialyear/insert", data1);
  },
  updateFinancialYear(id: any, data1: any) {
    return http.post(`/financialyear/update?id=${id}`, data1);
  },



  // Report
  getdailyfollowupreport(size: any, page: any, search: any, fdate : any,todate : any,status :any) {
    return http.get(`/report/dailyfollowupreport/?size=${size}&page=${page}&search=${search}&fdate=${fdate}&todate=${todate}&status=${status}`);
  },
  getQuote(data:any){
    return http.post("/crm/Quote", data);
  },
  updateForm( data1: any) 
  {
    return http.post("/crm/enqupdate",  data1 );
  },
  getNotify(data1: any) {
    return http.get("/notification/count", data1);
  },
  getBudgetQuotationpending(data: any){
    return http.get("/notification/quotepending", data);
  },
  postQuote(data:any){
    return http.post("/crm/UpdateQuote", data);
  },
  getUpdateQuote (id: number) {
    console.log('log -->.', id);
    
    return http.get('/crm/getrowQuote', {
      params: { id: id },
    });
  },
  quotelist(id: number) {
    return http.get('/crm/GetQuote', {
      params: { id: id },
    });
  },
  getstatus(size, click, page) {
    return http.get('/crm/GetStatusCount', {
      params: {
        size: size,
        click: click,
        page: page
      }
    });
  },

  getEssentialData(size: any, page: any, search: any) {
    return http.get(`/essentialdata?size=${size}&page=${page}&search=${search}`);
  },
  insertEssentialData(data1: any) {
    return http.post("/essentialdata/insert", data1);
  },
  updateEssentialData(id: any, data1: any) {
    return http.post(`/essentialdata/update?id=${id}`, data1);
  },
  getTax(size: any, page: any, search: any) {
    return http.get(`/tax?size=${size}&page=${page}&search=${search}`);
  },
  insertTax(data1: any) {
    return http.post("/tax/insert", data1);
  },
  updateTax(id: any, data1: any) {
    return http.post(`/tax/update?id=${id}`, data1);
  },
  postoutputdata( data1: any) 
  {
    return http.post(`/design/submit_output`,  data1 )
  },

  postverifydata( data1: any) 
  {
    return http.post(`/design/submit_verification`,  data1 )
  },
  getDropdown_indame(tablename: any,idname: any) {
    return http.get(`/dropdown/gettable_idname?tablename=${tablename}&idname=${idname}`);
  },
  getMonthlyVsActual (fiscalYear:string) {
    // return http.get(`crm/getquarterlydata=${fiscalYear}`);
    return http.get('/crm/getquarterlydata', {
      params: { fiscalYear: fiscalYear },
    });
  },
  getMonthlyQuator (fiscalYear:string) {
    // return http.get(`crm/getquarterlydata=${fiscalYear}`);
    return http.get('/crm/getmonthlyquoter', {
      params: { fiscalYear: fiscalYear },
    });
  },
  postdrawvaliddata( data1: any) 
  {
    return http.post(`/design/submit_drawing_validation`,  data1 )
  },
  getEnquiry (search:string) {
    return http.get('/crm/getEnquiryData' ,{
      params: { search: search },
    });
  },
  getstatuswisereport(size: any, page: any,search: any, click: any, fdate : any,todate : any) {
    return http.get(`/report/statuswisereport/?size=${size}&page=${page}&search=${search}&click=${click}&fdate=${fdate}&todate=${todate}`);
  },
  getCustomerDetail(search:string) {
    return http.get('/crm/getCustomerData' ,{
      params: { search: search },
    });
  },
  getCusDetail (id: number) {
    return http.get('/crm/getEnquiryCustomerDetails', {
      params: { id: id },
    });
  },

  getstatuscount(startdate, enddate) {
    return http.get("/crm/GetSCount",  {
      params: { startdate, enddate },
    });
  },
  getmodecount(startdate, enddate) {
    return http.get("/crm/GetModeCount",  {
      params: { startdate, enddate },
    });
  },
  gettypecount(startdate, enddate) {
    return http.get("/crm/GetTypeCount",  {
      params: { startdate, enddate },
    });
  },
  getprioritycount(startdate, enddate) {
    return http.get("/crm/GetPriorityCount",  {
      params: { startdate, enddate },
    });
  },
}
