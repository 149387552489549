import React, { useState } from 'react';
import './sidebar1.css'
import { MenuItem } from '@mui/material';
import { any } from 'prop-types';
import { useNavigate } from 'react-router-dom';

interface SubItem {
  title: string;
  url?: string; // URL for sub-menu items
  icon?: string | any;
  subItems?: SubItem[];
}

interface MenuItemProps {
  title: string;
  icon?: string | any;
  subItems?: SubItem[];
  url?: string;
}

const MenuItem1: React.FC<MenuItemProps> = ({ title, icon, subItems,url }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    if (url) {
      navigate(url);
    } else {
      setIsExpanded(!isExpanded);
    }
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className="menu-item" onClick={handleClick}>
        {icon && <span className="menu-icon">{icon}</span> }
        {title}
        {subItems && <span className="expand-icon">{isExpanded ? '-' : '+'}</span>}
      </div>
      {isExpanded && subItems && (
        <div className="sub-menu">
          {subItems.map((subItem, index) => (
            <MenuItem1 key={index} {...subItem} />
          ))}
        </div>
      )}
      
    </div>
    
  );
};

export default MenuItem1;
