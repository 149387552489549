import React, { useState } from 'react';
import { Box, Button, Collapse, List, ListItem, ListSubheader, makeStyles } from '@material-ui/core';
import * as icons from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import '../sidebarstyle.css';
import '../../../layout.css';
import Sidebar from '../../sidebar1';
// import Sidebar from '..';


const useStyles = makeStyles((theme) => ({
  nav: {
    width: 250,
    backgroundColor: theme.palette.background.paper,
  },
  subMenu: {
    paddingLeft: theme.spacing(4),
  },
  listItem: {
    padding: 0,
  },
  button: {
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: 'inherit',
    width: '100%',
  },
  collapseContainer: {
    '&.MuiCollapse-container': {
      height: 'auto !important', // Ensure height is auto
    },
  },
}));


const MenuWrapper: React.FC = ({ children }) => {
  return (
    <Box component="nav" style={{ width: 250, backgroundColor: 'background.paper' }}>
      {children}
    </Box>
  );
};

const SubMenuWrapper: React.FC = ({ children }) => {
  return (
    <Box style={{ paddingLeft: 16 }}>
      {children}
    </Box>
  );
};

const SidebarMenu: React.FC = () => {
  const classes = useStyles();
  // const [openMenu, setOpenMenu] = React.useState<string | null>(null);

  // const handleToggleMenu = (menu: string) => {
  //   setOpenMenu(openMenu === menu ? null : menu);
  // };

  const [openMenu, setOpenMenu] = useState('');

  const handleToggleMenu = (menu: string) => {
    console.log('Toggling menu:', menu);
    console.log('Current openMenu:', openMenu);
    setOpenMenu((prevOpenMenu) => (prevOpenMenu === menu ? '' : menu));
  };


  const closeSidebar = () => {
    // Add logic to close sidebar if needed
  };

  return (
    <Sidebar />
    // <MenuWrapper>
    //   {/* Super Admin */}
    //   <List component="div" subheader={<ListSubheader component="div" disableSticky>SUPER ADMIN</ListSubheader>}>
    //     <SubMenuWrapper>
    //       <List component="div">
    //         <ListItem component="div">
    //           <Button
    //             disableRipple
    //             onClick={() => handleToggleMenu('dashboards')}
    //             startIcon={<icons.People />}
    //             endIcon={openMenu === 'dashboards' ? <icons.ExpandLess /> : <icons.ExpandMore />}
    //             className={classes.button}
    //           >
    //             User Settings
    //           </Button>
    //         </ListItem>


    //         <Collapse in={openMenu === 'dashboards'} timeout="auto" unmountOnExit>
    //           <List component="div" disablePadding>
    //             <ListItem component="div">
    //               <Button
    //                 disableRipple
    //                 component={RouterLink}
    //                 onClick={closeSidebar}
    //                 to="/crm/role"
    //                 startIcon={<icons.BrightnessLowTwoTone />}
    //               >
    //                 User Role
    //               </Button>
    //             </ListItem><br />
    //             <ListItem component="div">
    //               <Button
    //                 disableRipple
    //                 component={RouterLink}
    //                 onClick={closeSidebar}
    //                 to="/admin/user"
    //                 startIcon={<icons.MmsTwoTone />}
    //               >
    //                 User
    //               </Button>
    //             </ListItem><br />
    //             <ListItem component="div">
    //               <Button
    //                 disableRipple
    //                 component={RouterLink}
    //                 onClick={closeSidebar}
    //                 to="/admin/department"
    //                 startIcon={<icons.MmsTwoTone />}
    //               >
    //                 Department
    //               </Button>
    //             </ListItem><br />
    //             <ListItem component="div">
    //               <Button
    //                 disableRipple
    //                 component={RouterLink}
    //                 onClick={closeSidebar}
    //                 to="/admin/designation"
    //                 startIcon={<icons.MmsTwoTone />}
    //               >
    //                 Designation
    //               </Button>
    //             </ListItem><br />
    //             <ListItem component="div">
    //               <Button
    //                 disableRipple
    //                 component={RouterLink}
    //                 onClick={closeSidebar}
    //                 to="/admin/employee"
    //                 startIcon={<icons.MmsTwoTone />}
    //               >
    //                 Employee
    //               </Button>
    //             </ListItem><br />
    //             <ListItem component="div">
    //               <Button
    //                 disableRipple
    //                 component={RouterLink}
    //                 onClick={closeSidebar}
    //                 to="/admin/city"
    //                 startIcon={<icons.MmsTwoTone />}
    //               >
    //                 Places (City)
    //               </Button>
    //             </ListItem>
    //           </List>
    //         </Collapse>
    //       </List>
    //       <List component="div">
    //         <ListItem component="div">
    //           <Button
    //             disableRipple
    //             onClick={() => handleToggleMenu('companyRegister')}
    //             startIcon={<icons.People />}
    //             endIcon={openMenu === 'companyRegister' ? <icons.ExpandLess /> : <icons.ExpandMore />}
    //             className={classes.button}
    //           >
    //             Company Register
    //           </Button>
    //         </ListItem>

    //         <Collapse in={openMenu === 'companyRegister'} timeout="auto" unmountOnExit>
    //           <List component="div" disablePadding>
    //             <ListItem component="div">
    //               <Button
    //                 disableRipple
    //                 component={RouterLink}
    //                 onClick={closeSidebar}
    //                 to="/admin/company"
    //                 startIcon={<icons.MmsTwoTone />}
    //               >
    //                 Company
    //               </Button>
    //             </ListItem>
    //             <br />
    //             <ListItem component="div">
    //               <Button
    //                 disableRipple
    //                 component={RouterLink}
    //                 onClick={closeSidebar}
    //                 to="/admin/plant"
    //                 startIcon={<icons.MmsTwoTone />}
    //               >
    //                 Plant
    //               </Button>
    //             </ListItem>
    //             <br />
    //             <ListItem component="div">
    //               <Button
    //                 disableRipple
    //                 component={RouterLink}
    //                 onClick={closeSidebar}
    //                 to="/admin/financialyear"
    //                 startIcon={<icons.MmsTwoTone />}
    //               >
    //                 Financial Year
    //               </Button>
    //             </ListItem>
    //           </List>
    //         </Collapse>
    //       </List>
    //     </SubMenuWrapper>
    //   </List>
    //   {/* Marketing CRM */}
    //   <List
    //     component="div"
    //     subheader={<ListSubheader component="div" disableSticky>Marketing CRM</ListSubheader>}
    //   >
    //     <List component="div">
    //       <ListItem component="div">
    //         <Button
    //           disableRipple
    //           onClick={() => handleToggleMenu('gensetting')}
    //           startIcon={<icons.BrightnessLowTwoTone />}
    //           endIcon={openMenu === 'gensetting' ? <icons.ExpandLess /> : <icons.ExpandMore />}
    //           className={classes.button}
    //         >
    //           General Settings
    //         </Button>
    //       </ListItem>
    //       <Collapse in={openMenu === 'gensetting'} timeout="auto" unmountOnExit>
    //         <List component="div" disablePadding>
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/mode"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Mode Of Enquiry
    //             </Button>
    //           </ListItem>
    //           <br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/type"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Enquiry Type
    //             </Button>
    //           </ListItem>
    //           <br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/followtype"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Followup Type
    //             </Button>
    //           </ListItem>
    //           <br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/priority"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Priority
    //             </Button>
    //           </ListItem>
    //           <br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/status"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Status
    //             </Button>
    //           </ListItem>
    //           <br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/quoteterms"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Terms & Conditions
    //             </Button>
    //           </ListItem>
    //           <br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/team"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Team
    //             </Button>
    //           </ListItem>
    //           <br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/target"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Target
    //             </Button>
    //           </ListItem>
    //         </List>
    //       </Collapse>
    //     </List>

    //     <List component="div">
    //       <ListItem component="div">
    //         <Button
    //           disableRipple
    //           onClick={() => handleToggleMenu('leadenq')}
    //           startIcon={<icons.AddShoppingCartTwoTone />}
    //           endIcon={openMenu === 'leadenq' ? <icons.ExpandLess /> : <icons.ExpandMore />}
    //           className={classes.button}
    //         >
    //           Lead/Enquiry Register
    //         </Button>
    //       </ListItem>
    //       <Collapse in={openMenu === 'leadenq'} timeout="auto" unmountOnExit>
    //         <List component="div" disablePadding>
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/customer"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Customer Register
    //             </Button>
    //           </ListItem><br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/addenquiry"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Add Lead/Enquiry
    //             </Button>
    //           </ListItem><br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/enquiry"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Lead/Enquiry Followup
    //             </Button>
    //           </ListItem>
    //         </List>
    //       </Collapse>
    //     </List>

    //     <List component="div">
    //       <ListItem component="div">
    //         <Button
    //           disableRipple
    //           onClick={() => handleToggleMenu('reports')}
    //           startIcon={<icons.DashboardTwoTone />}
    //           endIcon={openMenu === 'reports' ? <icons.ExpandLess /> : <icons.ExpandMore />}
    //           className={classes.button}
    //         >
    //           Reports
    //         </Button>
    //       </ListItem>
    //       <Collapse in={openMenu === 'reports'} timeout="auto" unmountOnExit>
    //         <List component="div" disablePadding>
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/customer"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Daily Followup Report
    //             </Button>
    //           </ListItem><br />
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/crm/addenquiry"
    //               startIcon={<icons.MmsTwoTone />}
    //             >
    //               Customer Detail Report
    //             </Button>
    //           </ListItem>
    //         </List>
    //       </Collapse>
    //     </List>
    //   </List>

    //   {/* Design & Development */}
    //   <List
    //     component="div"
    //     subheader={<ListSubheader component="div" disableSticky>Design & Development</ListSubheader>}
    //   >
    //     <List component="div">
    //       <ListItem component="div">
    //         <Button
    //           disableRipple
    //           onClick={() => handleToggleMenu('formreg')}
    //           startIcon={<icons.ReceiptOutlined />}
    //           endIcon={openMenu === 'formreg' ? <icons.ExpandLess /> : <icons.ExpandMore />}
    //           className={classes.button}
    //         >
    //           Form Register
    //         </Button>
    //       </ListItem>
    //       <Collapse in={openMenu === 'formreg'} timeout="auto" unmountOnExit>
    //         <List component="div" disablePadding>
    //           <ListItem component="div">
    //             <Button
    //               disableRipple
    //               component={RouterLink}
    //               onClick={closeSidebar}
    //               to="/design/designdev"
    //               startIcon={<icons.BrushSharp />}
    //             >
    //               Concept Drawing Analysis
    //             </Button>
    //           </ListItem>
    //         </List>
    //       </Collapse>
    //     </List>
    //   </List>
    // </MenuWrapper>
  );
};

export default SidebarMenu;