import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";

interface Data {
    name: string,
    sname: string,
    mobile: string,
    phone: string,
    email: string,
    pan: string,
    gstno: string,
    tinno: string,
    hsncode: string,
    gemsellerid: string,
    add1: string,
    add2: string,
    add3: string,
    add4: string,
    add5: string,
    cadd1: string,
    cadd2: string,
    cadd3: string,
    cadd4: string,
    cadd5: string,
    cby: string,
    isactive: string; id: number;

    // Add more fields as necessary
}

const company = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'In-Active'
    }
];

function Company() {
    // For Grid Table
    const [data, setData] = useState<Data[]>([]);

    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isMobileValid, setIsMobileValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);

    //Form data 
    const [formData, setFormData] = useState<any>({
        id: '',
        name: '',
        sname: '',
        mobile: '',
        phone: '',
        email: '',
        pan: '',
        gstno: '',
        tinno: '',
        hsncode: '',
        gemsellerid: '',
        add1: '',
        add2: '',
        add3: '',
        add4: '',
        add5: '',
        cadd1: '',
        cadd2: '',
        cadd3: '',
        cadd4: '',
        cadd5: '',
        isactive: '',
        cby: '',
        con: '',
        mby: '',
        mon: ''
    });

    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {

        apiService.getCompany(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
            setData(response.data);
        }).catch((err) => {
            console.log("err", err)
        })
    }, [rowsPerPage, page, formData]);

    //For Pagination 
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const validateMobile = (mobile: string) => {
        // Mobile number validation logic (example: 10 digits only)
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);
    };
    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[a-z]+\.(com|in|org|info|tech|gov|edu|mil|co|io)$/;
        return emailRegex.test(email);
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        if (name === 'mobile') {
            setIsMobileValid(validateMobile(value));
        }

        if (name == 'email') {
            setIsEmailValid(validateEmail(value));
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    // Textbox and Select box onchage
    // const handleChange = (event: any) => {
    //     const { name, value } = event.target;
    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [name]: value
    //     }));
    // };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setFormData({
            id: '',
            name: '',
            sname: '',
            mobile: '',
            phone: '',
            email: '',
            pan: '',
            gstno: '',
            tinno: '',
            hsncode: '',
            gemsellerid: '',
            add1: '',
            add2: '',
            add3: '',
            add4: '',
            add5: '',
            cadd1: '',
            cadd2: '',
            cadd3: '',
            cadd4: '',
            cadd5: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: ''
        });
        setSelectedItem(null);
    };
    // Insert and Update 
    let submit = (event: any) => {

        event.preventDefault(); // Prevents default form submission behavior
        // Insert
        if (selectedItem == null) {

            let data = {


                "sname": formData?.sname,
                "name": formData?.name,
                "mobile": formData?.mobile,
                "email": formData?.email,
                "pan": formData?.pan,
                "gstno": formData?.gstno,
                "add1": formData?.add1,
                "add2": formData?.add2,
                "add3": formData?.add3,
                "add4": formData?.add4,
                "add5": formData?.add5,
                "isactive": formData?.isactive,
                "cby": "1",
                "mby": ""

            };
            console.log('Submit --> ', data);


            apiService.insertCompany(data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        name: '',
                        sname: '',
                        mobile: '',
                        phone: '',
                        email: '',
                        pan: '',
                        gstno: '',
                        tinno: '',
                        hsncode: '',
                        gemsellerid: '',
                        add1: '',
                        add2: '',
                        add3: '',
                        add4: '',
                        add5: '',
                        cadd1: '',
                        cadd2: '',
                        cadd3: '',
                        cadd4: '',
                        cadd5: '',
                        isactive: '',
                        cby: '',
                        con: '',
                        mby: '',
                        mon: ''
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }

        // Update
        else {
            let data = {
                "id": formData?.id,
                "name": formData?.name,
                "sname": formData?.sname,
                "mobile": formData?.mobile,
                "email": formData?.email,
                "pan": formData?.pan,
                "gstno": formData?.gstno,
                "add1": formData?.add1,
                "add2": formData?.add2,
                "add3": formData?.add3,
                "add4": formData?.add4,
                "add5": formData?.add5,
                "isactive": formData?.isactive,
                "cby": "1",
                "mby": ""
            };
            apiService.updateCompany(Number(formData.id), data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        name: '',
                        sname: '',
                        mobile: '',
                        phone: '',
                        email: '',
                        pan: '',
                        gstno: '',
                        tinno: '',
                        hsncode: '',
                        gemsellerid: '',
                        add1: '',
                        add2: '',
                        add3: '',
                        add4: '',
                        add5: '',
                        cadd1: '',
                        cadd2: '',
                        cadd3: '',
                        cadd4: '',
                        cadd5: '',
                        isactive: '',
                        cby: '',
                        con: '',
                        mby: '',
                        mon: ''
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
    };
    // Handler for Edit Button click
    const handleEditClick = (item: Data) => {

        setFormData({
            id: item?.id.toString(),
            sname: item?.sname,
            name: item?.name,
            mobile: item?.mobile,
            email: item?.email,
            pan: item?.pan,
            gstno: item?.gstno,
            add1: item?.add1,
            add2: item?.add2,
            add3: item?.add3,
            add4: item?.add4,
            add5: item?.add5,
            isactive: item?.isactive,
        });
        setSelectedItem(item);
        setOpen(true);
    };

    return (
        <>
            <Helmet>
                <title>Company - Admin</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle
                    heading="Company"
                    subHeading=""
                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleClickOpen}>
                            Add New
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Short Name</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Mobile No</TableCell>
                                            <TableCell>Email Id</TableCell>
                                            <TableCell>Pan No</TableCell>
                                            <TableCell>GST No</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice().map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.sname}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.mobile}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell>{row.pan}</TableCell>
                                                <TableCell>{row.gstno}</TableCell>
                                                <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                                                    {row.isactive == "1" ? "Active" : "In-Active"}
                                                </TableCell>
                                                <TableCell>
                                                  <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                component="div"
                                count={Math.floor(data.length == 0 ? 0 : data[0]["count"])}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={handleClose}>
                    <form>
                        <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
                        <DialogContent>
                            <TextField
                                required
                                margin="dense"
                                id="sname"
                                label="Short Name"
                                name='sname'
                                fullWidth
                                value={formData?.sname}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Name"
                                name='name'
                                fullWidth
                                value={formData?.name}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="mobile"
                                label="Mobile No"
                                name='mobile'
                                fullWidth
                                value={formData?.mobile}
                                onChange={handleChange}
                                error={!isMobileValid}
                                helperText={!isMobileValid ? 'Invalid mobile number' : ''}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="email"
                                label="Email Id"
                                name='email'
                                fullWidth
                                value={formData?.email}
                                onChange={handleChange}
                                error={!isEmailValid}
                                helperText={!isEmailValid ? 'Invalid email id' : ''}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="pan"
                                label="Pan No"
                                name='pan'
                                fullWidth
                                value={formData?.pan}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="gstno"
                                label="GST No"
                                name='gstno'
                                fullWidth
                                value={formData?.gstno}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="add1"
                                label="House No"
                                name='add1'
                                fullWidth
                                value={formData?.add1}
                                onChange={handleChange}
                            />
                            <TextField
                                id="add2"
                                label="Street Address"
                                name='add2'
                                value={formData?.add2}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                            />
                            <TextField
                                id="add3"
                                label="Landmark"
                                name='add3'
                                value={formData?.add3}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                            />
                            <TextField
                                id="add4"
                                label="City"
                                name='add4'
                                value={formData?.add4}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                            />
                            <TextField
                                id="add5"
                                label="State"
                                name='add5'
                                value={formData?.add5}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                            />
                           
                            <TextField
                                select
                                margin="dense"
                                label="Status"
                                name='isactive'
                                fullWidth
                                value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                                onChange={handleChange}
                            >
                                {company.map((option: { value: any; label: any; }) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={submit} variant='contained' color="primary" disabled={!isMobileValid || !isEmailValid}>
                                {selectedItem ? 'Update' : 'Save'}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container>
            <Footer />
        </>
    );

}
export default Company;