import {
    Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import { Helmet } from "react-helmet-async";
  import { FaPencil } from "react-icons/fa6";
  import { ToastContainer } from "react-toastify";
  import Footer from "src/components/Footer";
  import PageTitle from "src/components/PageTitle";
  import PageTitleWrapper from "src/components/PageTitleWrapper";
  import { errorToast, successToast } from "src/layouts/utile/toast";
  import apiService from "src/services/api-service";
  
  interface Data {
    id: number;
    name: string;
    sname: string;
    hod: number;
    hodname: string;
    isactive: string;
    cby: string;
    con: Date;
    mby: string;
    mon: Date;
  }
  
  interface Hod {
    id: number;
    empno: number;
    name: string;
    deptname: string;
    designname: string;
  }
  
  const status = [
    {
      value: 1,
      label: 'Active'
    },
    {
      value: 0,
      label: 'In-Active'
    }
  ];
  
  function Department() {
    const [data, setData] = useState<Data[]>([]);
    const [hod, setHod] = useState<Hod[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [hodPage, setHodPage] = useState(0);
    const [hodRowsPerPage, setHodRowsPerPage] = useState(5);
    const [formData, setFormData] = useState<any>({
      id: '',
      name: '',
      sname: '',
      hod: '',
      isactive: '',
      cby: '',
      con: '',
      mby: '',
      mon: '',
    });
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedHod, setSelectedHod] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
  
    useEffect(() => {
      fetchDepartmentData();
      fetchHodData();
    }, [rowsPerPage, page,hodRowsPerPage,hodPage]);
  
    
  
    const fetchDepartmentData = () => {
      apiService.getDepartment(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
        setData(response.data);
      }).catch((err) => {
        console.log("err", err);
      });
    };
  
    const fetchHodData = () => {
      apiService.getEmployee(hodRowsPerPage.toString(), (hodPage + 1).toString(), "").then(response => {
        setHod(response.data);
      }).catch((err) => {
        console.log("err", err);
      });
    };
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleHodChangePage = (event: unknown, newPage: number) => {
      setHodPage(newPage);
       fetchHodData();
    };
  
    const handleHodChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setHodRowsPerPage(parseInt(event.target.value, 10));
      setHodPage(0);
    };
  
    const handleChange = (event: any) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    };
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClickOpen1 = () => {
      setOpen1(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      setFormData({
        id: '',
        name: '',
        sname: '',
        hod: '',
        isactive: '',
        cby: '',
        con: '',
        mby: '',
        mon: '',
      });
      setSelectedItem(null);
      setSelectedHod(null);
    };
  
    const handleClose1 = () => {
      setOpen1(false);
    };
  
    const handleHodSelect = (row: Hod) => {
      setSelectedHod(row);
      setFormData((prevFormData) => ({
        ...prevFormData,
        hod: row.id
      }));
      handleClose1();
    };
  
    let submit = (event: any) => {
      event.preventDefault();
  
      // Insert
      if (selectedItem == null) {
        let data = {
          name: formData?.name,
          sname: formData?.sname,
          hod: formData?.hod,
          isactive: formData?.isactive,
          cby: "1",
        };
  
        apiService.insertDepartment(data).then(response => {
          if (response?.data.key == "400") {
            errorToast(response?.data?.message);
          } else {
            successToast(response.data);
            setSelectedItem(null);
            setFormData({
              id: '',
              name: '',
              sname: '',
              hod: '',
              isactive: '',
              cby: '',
              con: '',
              mby: '',
              mon: '',
            });
            handleClose();
            setSelectedHod(hod.find(h => h.id === formData.hod) || null);
            fetchDepartmentData(); // Refetch data after insert
          }
        }).catch((err) => {
          console.log("err", err);
        });
      }
      // Update
      else {
        let data = {
          id: formData?.id,
          name: formData?.name,
          sname: formData?.sname,
          hod: formData?.hod,
          isactive: formData?.isactive,
          cby: "1",
        };
  
        apiService.updateDepartment(Number(formData.id), data).then(response => {
          if (response?.data.key == "400") {
            errorToast(response?.data?.message);
          } else {
            successToast(response.data);
            setSelectedItem(null);
            setFormData({
              id: '',
              name: '',
              sname: '',
              hod: '',
              isactive: '',
              cby: '',
              con: '',
              mby: '',
              mon: '',
            });
            handleClose();
            fetchDepartmentData(); // Refetch data after update
          }
        }).catch((err) => {
          console.log("err", err);
        });
      }
    };
  
    const handleEditClick = (item: Data) => {
      setFormData({
        id: item?.id.toString(),
        name: item?.name,
        sname: item?.sname,
        hod: item?.hod,
        isactive: item?.isactive,
        cby: item?.cby,
        con: item?.con,
        mby: item?.mby,
        mon: item?.mon,
      });
      setSelectedItem(item);
      setSelectedHod(hod.find(h => h.id === item.hod) || null);
      setOpen(true);
    };
  
    return (
      <>
        <Helmet>
          <title>Department - Admin</title>
        </Helmet>
  
        <PageTitleWrapper>
          <PageTitle
            heading="Department"
            subHeading=""
          />
        </PageTitleWrapper>
  
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Add New
              </Button>
            </Grid>
  
            <Grid item xs={12}>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Short Name</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>HOD</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.slice().map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.sname}</TableCell>
                          <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                            {row.isactive == "1" ? "Active" : "In-Active"}
                          </TableCell>
                          <TableCell>{row.hodname}</TableCell>
                          <TableCell>
                          <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={Math.floor(data.length == 0 ? 0 : data[0]["count"])}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
                
              </Paper>
            </Grid>
          </Grid>
  
          <Dialog open={open} onClose={handleClose}>
            <form>
              <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
              <DialogContent>
                <TextField
                  required
                  margin="dense"
                  id="name"
                  label="Name"
                  name='name'
                  fullWidth
                  value={formData?.name}
                  onChange={handleChange}
                />
                <TextField
                  required
                  margin="dense"
                  id="sname"
                  label="Short Name"
                  name='sname'
                  fullWidth
                  value={formData?.sname}
                  onChange={handleChange}
                />
                <TextField
                  select
                  margin="dense"
                  label="Status"
                  name='isactive'
                  fullWidth
                  value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                  onChange={handleChange}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  margin="dense"
                  label="HOD"
                  name="hod"
                  autoComplete="off"
                  fullWidth
                  value={selectedHod ? selectedHod.name : ''}
                  onClick={handleClickOpen1}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={submit} variant='contained' color="primary">
                  {selectedItem ? 'Update' : 'Save'}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <Dialog open={open1} onClose={handleClose1}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Emp No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Dept Name</TableCell>
                      <TableCell>Designation Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hod.slice().map((row) => (
                      <TableRow key={row.id} onClick={() => handleHodSelect(row)}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.empno}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.deptname}</TableCell>
                        <TableCell>{row.designname}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={Math.floor(hod.length == 0 ? 0 : hod[0]["count"])}
                page={hodPage}
                onPageChange={handleHodChangePage}
                rowsPerPage={hodRowsPerPage}
                onRowsPerPageChange={handleHodChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Dialog>
  
          <ToastContainer 
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Container>
        <Footer />
      </>
    );
  }
  
  export default Department;
  